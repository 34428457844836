import {AuthenticatedMixin, PWAPage, RoleMixin} from "../../shared/pwa-page";
import {Session} from "../../shared/session";
import {html} from "lit";

import "./channel-list";
import "./channel-edit";
import "./channel-destroy";
import "./channel-admin-list";
import "./channel-admin-edit";
import "./channel-admin-destroy";

export class PageSocial extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AuthenticatedMixin(PWAPage)) {
  render() {
    return html`
      <link-card>
        <a href="/social/channels" class="card-link light-green">
          <figure>
            <svg-icon
              size="100%"
              style="--icon-size: 11px"
              icon="document"
            ></svg-icon>
          </figure>

          <span>Channels</span>
        </a>
      </link-card>
    `;
  }
}
