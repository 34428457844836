import {RoleMixin} from "../../shared/pwa-page";
import {AdminListPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import {QuestionnaireCategoryDomain} from "../../domain/questionnaire-category-domain";
import {ResultProfileDomain} from "../../domain/result-profile-domain";
import {html} from "lit";
import {ResultProfileTextDomain} from "../../domain/result-profile-text-domain";

customElements.define(
  "page-result-profile-text-list",
  class PageResultProfileTextList extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminListPage) {
    canEdit = true;
    canDestroy = true;
    canAdd = true;
    searchable = false;
    title = null;

    static get properties() {
      return {
        resultProfileId: { type: String, attribute: 'result-profile-id' },
      };
    }

    newUrl() {
      return `/questionnaires/result_profiles/${this.resultProfileId}/texts/new`;
    }
    editUrl(obj) {
      return `/questionnaires/result_profiles/${this.resultProfileId}/texts/${obj.id}`;
    }
    destroyUrl(obj) {
      return `/questionnaires/result_profiles/${this.resultProfileId}/texts/${obj.id}/destroy`;
    }

    constructor() {
      super(new ResultProfileTextDomain());
    }

    connectedCallback() {
      this.domain.resultProfileId = this.resultProfileId;
      super.connectedCallback();
    }

    get columns() {
      return [
        {name: 'Name', field: 'name', sortable: true},
        {name: 'Value', field: 'value', sortable: true, render: (obj) => html`
          <small>${obj.value.substring(0, 50)}...</small>
        `},
        {name: 'Order', field: 'order', sortable: true},
      ];
    }
  }
);
