import {RoleMixin} from "../../shared/pwa-page";
import {AdminDestroyPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import {QuestionnaireDomain} from "../../domain/questionnaire-domain";

export class PageQuestionnaireDestroy extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminDestroyPage) {
  constructor() {
    super(new QuestionnaireDomain());
    this.userId = null;
  }

  redirect() {
    if (this.userId) {
      window.location.replace(`/tenants/users/${this.userId}`);
      return;
    }
    window.location.replace(`/questionnaires`);
  }

  connectedCallback() {
    // Check for back reference.
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('user_id')) {
      this.userId = urlParams.get('user_id');
    }

    debugger;

    super.connectedCallback();
  }
}
