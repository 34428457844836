import {RoleMixin} from "../../shared/pwa-page";
import {AdminListPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import {QuestionnaireCategoryDomain} from "../../domain/questionnaire-category-domain";
import { GenericDomain } from "../../domain/generic-domain";
import { html } from "lit";

export class PageScoreTextList extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminListPage) {
  #scoreTypeDomain;
  #scoreLevelDomain;
  canEdit = true;
  canDestroy = false;
  canAdd = false;
  searchable = true;
  title = 'Questionnaire Score Texts';

  constructor() {
    super(new GenericDomain(`/score_texts`));
    this.#scoreTypeDomain = new GenericDomain(`/score_types`);
    this.#scoreLevelDomain = new GenericDomain(`/scores`);
  }

  editUrl(obj) {
    return `/questionnaires/score_texts/${obj.id}`;
  }

  get columns() {
    return [
      {name: 'Text', field: 'text', sortable: true, filterable: false, searchable: false, render: (obj) => html`<small>${obj.text}</small>`},
      {name: 'Type', field: 'score_type_id', sortable: true, render: row => html`${row.score_type?.name}`, filterable: {
          property: 'score_type_id',
          label: 'Score Type',
          choices: async () => {
            const response = await this.#scoreTypeDomain.list({per_page: 1000});
            return response.data.map((c) => {
              return {
                value: c.id,
                name: c.name
              };
            });
          },
        },
      },
      {name: 'Score', field: 'score_id', sortable: true, render: row => html`${row.questionnaire_score?.name}`, filterable: {
          property: 'score_id',
          label: 'Score Level',
          choices: async () => {
            const response = await this.#scoreLevelDomain.list({per_page: 1000});
            return response.data.map((c) => {
              return {
                value: c.id,
                name: c.name
              };
            });
          },
        },
      },
    ];
  }
}
