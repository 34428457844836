import { html } from "lit";
import { BaseHRDashboard } from "./general";

export class PageDashboardHRUsers extends BaseHRDashboard {
  render() {
    return html`
      <dashboard-container>
      </dashboard-container>
    `
  }
}
customElements.define('page-dashboard-hr-users', PageDashboardHRUsers);
