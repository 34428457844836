import {AdminDomainHandler} from "../shared/app-domain-handler";

export class OrderDomain extends AdminDomainHandler {
  constructor() {
    super();
  }

  /**
   * Sends a request to ship an order.
   *
   * @param {string} orderId - The ID of the order to be shipped.
   * @param {object} data - The data containing the shipping details.
   * @returns {Promise<any>} - A promise that resolves with the response from the shipping API.
   */
  shipOrder(orderId, data) {
    return this.api.postData(`/orders/${orderId}/shipment`, data);
  }

  /**
   * Cancels an order.
   *
   * @param {string} orderId - The ID of the order to be canceled.
   * @param {object} data - The data containing the cancellation details.
   * @returns {Promise<any>} - A promise that resolves with the response from the cancellation API.
   */
  cancelOrder(orderId, data) {
    return this.api.postData(`/orders/${orderId}/cancellation`, data);
  }

  downloadInvoicePdf(orderId) {
    return this.api.getData(`/orders/${orderId}/invoice_pdf`);
  }

  baseUrl(operation, options) {
    return `/orders`;
  }
}
