import { GenericDomain } from "../../domain/generic-domain";
import { AdminListPage } from "../../shared/admin";
import { RoleMixin } from "../../shared/pwa-page";
import { Session } from "../../shared/session";

export class OptionCategoryList extends RoleMixin(
  Session.ROLE_QOGNI_ADMIN,
  AdminListPage
) {
  canEdit = true;
  title = "Option Categories";

  constructor() {
    super(new GenericDomain('/option_categories'));
  }

  editUrl(obj) {
    return `/content/option-categories/${obj.id}`;
  }

  newUrl() {
    return `/content/option-categories/new`;
  }

  destroyUrl(obj) {
    return `/content/option-categories/${obj.id}/destroy`;
  }

  get columns() {
    return [
      {
        name: "Name",
        field: "name",
        sortable: true,
        filterable: false,
        searchable: false,
      },
    ];
  }
}
