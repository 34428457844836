import { html } from "lit";
import { ref, createRef } from "lit/directives/ref.js";
import { PWAPage } from "../shared/pwa-page";
import { AuthenticationDomain } from "../domain/authentication-domain";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { isValidUuid4, isValidEmail } from "@qogni-technologies/design-system/src/shared/common";
import {config} from "../qogni-app-config";
import { Session } from "../shared/session";

export class PageEnter extends PWAPage {
  #domain;
  #waitingForCode;
  #query = {};
  #invitation = null;

  #emailInputRef = createRef();
  #pinInputRef = createRef();
  #submitBtnRef = createRef();

  constructor() {
    super();
    this.#domain = new AuthenticationDomain();
  }

  get invitation() {
    return this.#invitation ?? localStorage.getItem("invitation");
  }

  set invitation(val) {
    this.#invitation = val;
    localStorage.setItem("invitation", this.#invitation);
  }

  async checkOAuthHandling() {
    if (!this.#domain.isOAUthRedirect) {
      return;
    }

    return Task.run(
      async () => {
        if (await this.#domain.handleOAuthRedirect(this.invitation)) {
          app.goTo('/?auth=1', {
            force: true,
          });
        }
      },
      {
        ghost: document.documentElement,
        description: "Handling OAuth callback",
      }
    );
  }

  async connectedCallback() {
    if (app.session.isAuthenticated) {
      return app.goTo('/?auth=1', {
        force: true,
      });
    }

    super.connectedCallback();
    this.#invitation = localStorage.getItem("invitation");

    // Get all params in local object.
    const url = new URL(window.location.href);
    const params = Array.from(url.searchParams);
    params.forEach((param) => {
      this.#query[param[0]] = param[1];
    });

    // Get providers.
    // this.socialProviders = await this.#domain.getSocialProviders();
    this.socialProviders = [];

    // Check oAuth and Invitation.
    this.checkOAuthHandling();
    this.checkInvitation();

  }

  firstUpdated() {
    this.checkEmail();
  }

  checkInvitation() {
    if (!this.#query.invite) return;
    if (!isValidUuid4(atob(this.#query["invite"]))) return;
    this.invitation = atob(this.#query["invite"]);

    // Clear the invite from the URL.
    this.clearUrl();
  }

  checkEmail() {
    if (!this.#query.email) return;
    if (!isValidEmail(this.#query.email)) return;

    const email = this.#query.email;
    this.#emailInputRef.value.value = email;
    this.#submitBtnRef.value.click();
  }

  /**
   * Clears all query parameters from the current URL and updates the browser's URL without refreshing the page.
   *
   * @return {void}
   */
  clearUrl() {
    const url = new URL(window.location.href);
    const params = Array.from(url.searchParams);
    params.forEach((param) => url.searchParams.delete(param[0]));
    const newUrl = url.search ? url.href : url.href.replace("?", "");
    window.history.replaceState({}, document.title, newUrl);
  }

  render() {

    return html`
      <div class="narrow">
        <section class="card">
          <h2>Welcome to Qogni!</h2>
          <p>You're one step closer to improve your lifestyle!</p>
        </section>

        <section class="card">
          <x-form debug @action=${this.action}>
            <form method="get" action="?" class="material center">
              <fieldset>
                <legend>
                  <h2>Sign in or sign up</h2>
                  <p>Get started for free</p>
                </legend>

                <button
                  name="linkedin"
                  type="button"
                  class="wide outline blue"
                  ?hidden=${this.socialProviders && this.socialProviders.indexOf('linkedin') === -1}
                  @click=${this.socialLogin.bind(this)}
                >
                  <svg-icon
                    icon="linkedin"
                    color="#2d64bc"
                    size="24px"
                  ></svg-icon>
                  Continue with LinkedIn
                </button>

                <button
                  name="facebook"
                  type="button"
                  class="wide outline blue mt-tiny"
                  ?hidden=${this.socialProviders && this.socialProviders.indexOf('facebook') === -1}
                  @click=${this.socialLogin.bind(this)}
                >
                  <svg-icon
                    icon="facebook-round"
                    color="#4a5f9b"
                    size="26px"
                  ></svg-icon>
                  Continue with Facebook
                </button>
              </fieldset>

              <hr data-content="or" />

              <fieldset>
                <input
                  name="email"
                  data-label="Email"
                  type="email"
                  ?required=${!this.codeRequested}
                  ?readonly=${this.codeRequested}
                  ${ref(this.#emailInputRef)}
                  placeholder="Email address"
                />
              </fieldset>

              <fieldset class=${!this.codeRequested ? "hidden" : "visible"}>
                <input
                  name="pin"
                  inputmode="numeric"
                  pattern="[0-9]"
                  autocomplete="one-time-code"
                  data-label="Verification code"
                  type="number"
                  ?required=${this.codeRequested}
                  ${ref(this.#pinInputRef)}
                  placeholder="Paste sign up code"
                />
                <div class="info">
                  We sent a verification email to your inbox ●
                  <a href="#resend" class="primary" @click=${this.#resend}
                    >Resend</a
                  >
                  ●
                  <a href="#reset" class="primary" @click=${this.#reset}
                    >Different email address</a
                  >
                </div>
              </fieldset>

              <button ${ref(this.#submitBtnRef)} name="email" class="green wide mb-tiny" type="submit">
                Continue with email
              </button>

              <section class="disclaimer info">
                By clicking "Continue with LinkedIn" or "Continue with email",
                you agree to our User <a href="${config.tosUrl}" target="_blank">Terms of Service</a> and
                <a href="${config.privacyPolicyUrl}" target="_blank">Privacy Policy</a>.
              </section>
            </form>
          </x-form>
        </section>

        <div class="center">
          <img
            alt="boy"
            src="/assets/img/boy.svg"
            height="300px"
            loading="lazy"
          />
        </div>
      </div>
    `;
  }

  static get properties() {
    return {
      codeRequested: { type: Boolean },
      socialProviders: { type: Array },
    };
  }

  get codeRequested() {
    return this.#waitingForCode;
  }

  async socialLogin(e) {
    const provider = e.target.name;
    const task = async () => {
      try {
        await this.#domain.startWithSocial(provider);
      } catch {
        app.addToastMessage(
          `We had an issue with requesting ${provider} login method! Please try again later.`,
          {
            type: "error",
          }
        );
      }
    };

    Task.run(task, {
      ghost: document.documentElement,
      description: "Request social authentication",
    });
  }

  /**
   * Handles x-form's action event.
   * @param {*} e
   */
  async action(e) {
    if (e.detail.name !== "--submit") return;
    e.preventDefault();

    const task = async () => {
      if (!e.detail.value.pin) {
        this.#waitingForCode = true;
        try {
          await this.#domain.startWithEmail(e.detail.value.email);
          this.#pinInputRef.value.focus();
        } catch {
          this.#waitingForCode = false;
          app.addToastMessage(
            "We had some issues requesting your sign in. Please make sure you have internet connectivity and try again later",
            {
              type: "error",
            }
          );
        }
      } else {
        this.#waitingForCode = false;
        try {
          await this.#domain.activateUserSession(
            e.detail.value.email,
            e.detail.value.pin,
            this.invitation
          );

          app.goTo('/?auth=1', {
            force: true,
          });
        } catch (err) {
          let message;
          if (err.response && err.response.status === 401) {
            message =
              "The code you filled in is either not valid or has been expired. Please try again.";
          } else {
            message =
              "We encountered an error trying to sign you in. Please try again in a bit.";
          }
          app.addToastMessage(message, { type: "error" });
          this.#pinInputRef.value.value = "";
        }
      }
      this.requestUpdate();
    };
    Task.run(task, {
      ghost: e.target,
      description: "Sign in",
    });
  }

  async #resend() {
    const email = this.#emailInputRef.value.value;
    await this.#domain.startWithEmail(email);
    this.#pinInputRef.value.value = "";
    this.#pinInputRef.value.focus();
    app.addToastMessage("New code requested");
  }

  async #reset() {
    this.querySelector("form").reset();
    this.#waitingForCode = false;
    this.requestUpdate();
  }
}
