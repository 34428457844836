import {css, html, LitElement} from "lit";

/**
 * Ranking component
 */
customElements.define(
  "data-ranking",
  class DataRanking extends LitElement {
    createRenderRoot() {
      return this;
    }

    render() {
      return html`
        <div>
          <div class="group">
            <span class="count h3">01</span>
            <span class="score"><h2>143.528</h2></span>
            <span class="name"><h5>Jan Jansen</h5></span>
          </div>
          <div class="group">
            <span class="count h3">02</span>
            <span class="score"><h2>123.528</h2></span>
            <span class="name"><h5>Jan Jansen</h5></span>
          </div>
          <div class="group">
            <span class="count h3">03</span>
            <span class="score"><h2>113.528</h2></span>
            <span class="name"><h5>Jan Jansen</h5></span>
          </div>
          <div class="group">
            <span class="count h3">04</span>
            <span class="score"><h2>89.528</h2></span>
            <span class="name"><h5>Jan Jansen</h5></span>
          </div>
          <div class="group">
            <span class="count h3">05</span>
            <span class="score"><h2>76.528</h2></span>
            <span class="name"><h5>Jan Jansen</h5></span>
          </div>
          <div class="group active">
            <span class="count h3">14</span>
            <span class="score"><h2>22.528</h2></span>
            <span class="name"><h5>Jan Jansen</h5></span>
          </div>
        </div>
      `
    }
  }
);
