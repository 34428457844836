import { GenericDomain } from "../../domain/generic-domain";
import { RoleMixin } from "../../shared/pwa-page";
import { Session } from "../../shared/session";
import { AdminEditPage } from "../../shared/admin";
import { links } from "./index";

export class PageSupplementEdit extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminEditPage) {
  topLinks = links;

  constructor() {
    super(new GenericDomain('/supplements'));
  }

  get fields() {
    return [
      {
        label: 'Name',
        property: 'name',
        required: true,
        type: String
      }, {
        label: 'Description',
        property: 'description',
        required: true,
        type: String,
        markdown: true,
      },
    ];
  }
}
