import {RoleMixin} from "../../shared/pwa-page";
import {AdminListPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import {QuestionnaireCategoryDomain} from "../../domain/questionnaire-category-domain";

export class PageQuestionnaireCategoriesList extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminListPage) {
  #domain;

  canEdit = false;
  canDestroy = false;
  canAdd = false;
  searchable = false;
  title = 'Questionnaire Categories';

  constructor() {
    super(new QuestionnaireCategoryDomain());
  }

  get columns() {
    return [
      {name: 'ID', field: 'id'},
      {name: 'Name', field: 'name', sortable: true, filterable: false, searchable: false},
    ];
  }
}
