import {ApiRequest} from "./APIRequest";


/**
 * Storage of session and verification of session in-memory.
 * @event logout User is logged out, can be triggered due to JWT-session being expired or by action of logout (will be defined in event_source).
 */
export class OAuth2 {
  #api;

  static factory() {
    return new this();
  }

  constructor() {
    this.#api = ApiRequest.factory();
  }

  /**
   * Handles the redirect after an OAuth2 authentication flow.
   * Checks if the redirect URL contains the required parameters, and verifies the state parameter.
   * Then requests the JWT token from the API server, sets it in the session, and removes the URL parameters.
   *
   * @returns {Promise<boolean>} - A Promise that resolves to true if the redirect handling is successful, or false if any error occurs.
   */
  async handleRedirect() {
    const urlParams = new URLSearchParams(window.location.search);
    if (!urlParams.has('code') || !urlParams.has('state')) {
      throw new Error('Incorrect oauth2 redirect: code || state not found');
    }
    if (urlParams.get('state') !== localStorage.getItem('state')) {
      throw new Error('Incorrect oauth2 redirect: state is incorrect.');
    }

    // Request the JWT token from our API-server.
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    const provider = urlParams.get('oauth2_provider');

    let redirectUri = window.location.href.split('?')[0];
    redirectUri += `?oauth2_provider=${provider}&action=redirect`;
    redirectUri = btoa(redirectUri);

    const response = await this.#api.postData(`/auth/oauth2/${provider}/activate?redirectUri=${redirectUri}`, {
      code,
      state
    });

    // Set token in session.
    await app.session.setJwt(response.authorisation.token);
    await app.session.setRoles(response.data.roles);
    await app.session.setUser(response.data.user);

    // Remove all url parameters from the current URL using replaceState.
    const url = new URL(window.location.href);
    const params = Array.from(url.searchParams);
    params.forEach(param => url.searchParams.delete(param[0]));
    const newUrl = url.search ? url.href : url.href.replace('?', '');
    window.history.replaceState({}, document.title, newUrl);

    return true;
  }

  /**
   * Initiates the OAuth2 authentication process for the specified provider.
   *
   * @param {string} provider - The provider name.
   * @param redirectUri - The custom redirect uri. Will be generated if not set.
   * @returns {Promise<*|string>} - A promise that resolves to the response data from the API.
   */
  async initiate(provider, redirectUri = null) {
    if (!redirectUri) {
      redirectUri = window.location.href.split('?')[0];
      redirectUri += `?oauth2_provider=${provider}&action=redirect`;
    }

    // Base encode the URL, making it safe for transfer.
    redirectUri = btoa(redirectUri);

    return await this.#api.getData(`/auth/oauth2/${provider}/initiate?redirectUri=${redirectUri}`);
  }
}
