import { ChannelAdminDomain } from "../../domain/channel-admin-domain";
import { AdminDestroyPage } from "../../shared/admin";
import { RoleMixin } from "../../shared/pwa-page";
import { Session } from "../../shared/session";

export class PageChannelAdminDestroy extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminDestroyPage) {
  title = 'Channel Admin';

  static get properties() {
    return {
      ...super.properties,
      channelId: { type: String, attribute: 'channelid', routeOrigin: 'pathname' },
    };
  }
  constructor() {
    super(new ChannelAdminDomain());
  }
  connectedCallback() {
    this.domain.channelId = this.channelId;
    super.connectedCallback();
  }
}
