import {RoleMixin} from "../../shared/pwa-page";
import {AdminListPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import { GenericDomain } from "../../domain/generic-domain";
import { links } from "./index";

export class PageProgramList extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminListPage) {
  canEdit = true;
  canDestroy = false;
  canAdd = true;
  canView = true;
  searchable = true;
  title = 'Program List';

  viewLinkIcon = 'arrow';
  viewLinkText = 'Manage';
  viewLinkWidth = 90;

  topLinks = links

  editUrl(obj) {
    return `/programs/${obj.id}`;
  }

  newUrl() {
    return `/programs/new`;
  }

  viewUrl(obj) {
    return `/programs/${obj.id}/manage`;
  }

  constructor() {
    super(new GenericDomain('/programs'));
  }

  get columns() {
    return [
      {name: 'Name', field: 'name', sortable: true, searchable: true},
    ];
  }
}
