import {LitElement, html, css} from 'lit-element';
import ApexCharts from 'apexcharts';

customElements.define(
  "apex-chart",
  class ApexChart extends LitElement {
    createRenderRoot() {
      return this;
    }

    static get properties() {
      return {
        options: {
          type: Object,
          attribute: true,
        },
      };
    }

    connectedCallback() {
      super.connectedCallback();
      this.createChart();
    }

    createChart() {
      console.log(this.options);
      const div = document.createElement('div');
      div.id = 'chart';
      this.appendChild(div);

      const chart = new ApexCharts(div, this.options);
      chart.render();
      window.dispatchEvent(new Event('resize'));
    }

    render() {
      return html``;
    }
  }
);
