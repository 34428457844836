import { html } from 'lit-element';
import ApexCharts from 'apexcharts'
import { createRef, ref } from "lit/directives/ref.js";
import { DashboardBlock } from "./base";
import { StatisticsDomain } from "../../../domain/statistics-domain";
import { Task } from "@qogni-technologies/pwa-utils-library/utils/task.js";
import { nothing } from "lit";

customElements.define(
  "stat-budget",
  class BurnoutStat extends DashboardBlock {
    #budgetInterval;
    #statDomain;

    constructor() {
      super();
      this.budgetIndex = 0;
      this.#statDomain = new StatisticsDomain();
      this.type = 'available';
    }

    static get properties() {
      return {
        ...super.properties,
        cta: {type: Boolean, attribute: true},
        type: {type: String, attribute: true},
      }
    }

    static get demoData() {
      return [{
        currency: {symbol: '€', code: 'EUR', name: 'Euro'},
        balance: 19042.000,
        allocated: 43000.000,
        allocated_ytd: 21000.000,
      }];
    }

    async fetch(refresh = false) {
      await super.fetch(refresh);
      if (! this.filter.organisationId) {
        this.data = BurnoutStat.demoData;
        this.requestUpdate();
        console.warn('Filters not set for the budget stat. Using demo data.');
        return;
      }
      if (this.data && ! refresh) return;

      return Task.run(async() => {
        this.data = await this.#statDomain.budget(this.filter.organisationId, {
          allocated: this.type === 'allocated',
        });
        this.requestUpdate();
      }, {
        global: false,
        ghost: this
      });
    }

    connectedCallback() {
      super.connectedCallback();

      this.#budgetInterval = setInterval(() => {
        if (this.data !== undefined) {
          this.budgetIndex = (this.budgetIndex + 1) % this.data.length;
          this.requestUpdate();
        }
      }, 3000);
    }

    disconnectedCallback() {
      super.disconnectedCallback();
      if (this.#budgetInterval) clearInterval(this.#budgetInterval);
    }

    get value() {
      if (! this.data || ! this.data.hasOwnProperty(this.budgetIndex)) return null;
      if (this.type === 'available') return this.data[this.budgetIndex].balance;
      if (this.type === 'allocated') return Math.abs(this.data[this.budgetIndex].allocated_ytd);
      return null;
    }
    get label() {
      if (this.type === 'available') return 'Budget available';
      if (this.type === 'allocated') return 'Allocated budget (YTD)';
    }

    render() {
      return html`
        <comparison-stat
          score="${this.value ?? 0}"
          ?loading="${this.data === undefined}"
          label="${this.label}"
          icon="metrics"
          format
          prefix="${this.data && this.data.hasOwnProperty(this.budgetIndex) ? this.data[this.budgetIndex]?.currency?.symbol ?? '' : ''}"
          color="yellow"
        ></comparison-stat>

        ${this.cta ? html`
          <a href="/tenants/organisations/${this.filter.organisationId}/view" class="button tiny outline">
            Add
          </a>
        ` : nothing}
      `;
    }
  }
);
