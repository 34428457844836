import {RoleMixin} from "../../shared/pwa-page";
import {AdminListPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import {html} from "lit";
import { GenericDomain } from "../../domain/generic-domain";

export class PageQuestionList extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminListPage) {
  canEdit = true;
  canDestroy = false;
  canAdd = false;
  searchable = false;
  title = 'Questions';

  filterable = true;

  #questionCategoryDomain;
  #questionnaireCategoryDomain;

  constructor() {
    super(new GenericDomain('/questions'));
    this.#questionCategoryDomain = new GenericDomain('/question_categories');
    this.#questionnaireCategoryDomain = new GenericDomain('/questionnaire_categories');
  }

  editUrl(obj) {
    return `/questionnaires/questions/${obj.id}`;
  }

  get columns() {
    return [
      {name: 'ID', field: 'id', classList: {small: true}},
      {name: 'Question', field: 'question', sortable: true, filterable: false, searchable: false},
      {name: 'Description', field: 'description', sortable: true, filterable: false, searchable: false, render: (obj) => html`<small>${obj.description}</small>`},
      {
        name: 'Primary Category',
        field: 'question_category_id',
        render: (obj) => {
          return obj.question_category?.name;
        },
        filterable: {
          property: 'question_category_id',
          label: 'Primary Question Category',
          choices: async () => {
            const response = await this.#questionCategoryDomain.list({per_page: 1000});
            return response.data.map((c) => {
              return {
                value: c.id,
                name: c.name
              };
            });
          },
        },
      },
      {
        name: 'Secondary Category',
        field: 'secondary_question_category_id',
        render: (obj) => {
          return obj.secondary_question_category?.name;
        },
        filterable: {
          property: 'secondary_question_category_id',
          label: 'Secondary Question Category',
          choices: async () => {
            const response = await this.#questionCategoryDomain.list({per_page: 1000});
            return response.data.map((c) => {
              return {
                value: c.id,
                name: c.name
              };
            });
          },
        },
      },
      {name: 'Type', field: 'question_type_id', render: (obj) => {
        return obj.question_type?.name;
      }},
      {
        name: 'Questionnaire Category',
        field: 'questionnaire_category_id',
        render: (obj) => {
          return obj.questionnaire_category?.name;
        },
        filterable: {
          property: 'questionnaire_category_id',
          label: 'Questionnaire Category',
          choices: async () => {
            const response = await this.#questionnaireCategoryDomain.list({per_page: 1000});
            return response.data.map((c) => {
              return {
                value: c.id,
                name: c.name
              };
            });
          },
        },
      },
    ];
  }
}
