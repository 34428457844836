import { RoleMixin } from "../../shared/pwa-page";
import { AdminDestroyPage } from "../../shared/admin";
import { Session } from "../../shared/session";
import { UserDomain } from "../../domain/user-domain";

export class PageUserDestroy extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminDestroyPage) {
  title = 'User';
  redirect = '/tenants/users';

  constructor() {
    super(new UserDomain());
  }
}
