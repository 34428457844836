import { html } from "lit";

import { RoleMixin } from "../../../shared/pwa-page";
import { AdminPage } from "../../../shared/admin";
import { Session } from "../../../shared/session";
import { BaseHRDashboard } from "./general";

export class PageDashboardHRStrategic extends BaseHRDashboard {
  render() {
    return html`
      <dashboard-container>
        <div class="col-3">
          <section class="card">
            <stat-users
              .filter=${{organisationId: this.organisationId}}
              size="small"
              type="active"
            ></stat-users>
          </section>
          <section class="card">
            <stat-programs
              .filter=${{organisationId: this.organisationId}}
              size="small"
            ></stat-programs>
          </section>
        </div>
        <section class="card col-9">
          <stat-calculator
            .filter=${{organisationId: this.organisationId}}
          ></stat-calculator>
        </section>

        <section class="card col-6">
          <h2>Mental Health Absence Prediction</h2>

          <stat-burnout
            .filter=${{organisationId: this.organisationId}}
          ></stat-burnout>
        </section>
        <section class="card col-6">

        </section>

        <section class="card col-6">
          <stat-disc
            .filter=${{organisationId: this.organisationId}}
          ></stat-disc>
        </section>
        <section class="card col-6">

        </section>

        <section class="card col-6">
          <h3>️Company Energy Diagram</h3>
          <energy-matrix
            .filter=${{organisationId: this.organisationId}}
          ></energy-matrix>
        </section>
        <section class="card col-6">

        </section>
      </dashboard-container>
    `
  }
}
customElements.define('page-dashboard-hr-strategic', PageDashboardHRStrategic);
