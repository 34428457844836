import {css} from "lit";
import {hookEscapeKey} from "@qogni-technologies/design-system/src/shared/common";
import {ButtonBar} from "./button-bar";

/**
 * Customizable side bar component
 */
customElements.define(
  "side-bar",
  class SideBar extends ButtonBar {
    static get properties() {
      return {
        buttons: {},
        global: {type: Boolean, attribute: true},
        appearance: {type: String, attribute: true},
      };
    }

    static get styles() {
      return [
        css`
          @media only screen and (max-width: 600px) {
            :host(.slide) {
              display: block;
              background-color: var(--color-primary-100);
              width: 100vw;
              height: 100vh;
            }
          }

          @media only screen and (max-width: 900px) {
            :host span[data-text] {
              display: none;
            }
          }

          nav {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: var(--gutter-tiny);
            box-sizing: border-box;
            left: 0;
          }

          nav figure {
            margin: 0;
          }

          span[data-text] {
            font-size: 20px;
            font-weight: 600;
            margin-left: var(--gutter-small);
          }

          nav a:link,
          nav a:visited {
            border-radius: var(--radius-large);
            text-decoration: none;
            color: var(--color-text-000);
            background-color: var(--color-primary-200);
            border: 0;
            outline: none;
            --icon-fill-color: var(--color-primary-100);
            margin-bottom: var(--gutter-tiny);
            padding: var(--gutter-tiny);
            display: flex;
            align-items: center;
            justify-content: flex-start;
            transition: background var(--transition-duration) ease-in-out, color var(--transition-duration) ease-in-out;
          }

          nav a:active {
            box-shadow: 3px 3px var(--color-primary-400);
          }

          nav a:link:last-child,
          nav a:visited:last-child {
            margin-bottom: 0;
          }

          nav figure {
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            --icon-fill-color: var(--color-primary-200);
            transition: background var(--transition-duration) ease-in-out;
          }

          nav figure svg-icon {
            width: 50%;
            height: 50%;
            transition: filter var(--transition-duration) ease-in-out;
          }

          nav a:nth-child(5n + 1) {
            &:hover,
            &:focus,
            &.active {
              background-color: var(--color-primary-000);
            }

            figure {
              background-color: var(--color-primary-000);
            }
          }

          nav a:nth-child(5n + 2) {
            &:hover,
            &:focus,
            &.active {
              background-color: var(--color-accent-100);
            }

            figure {
              background-color: var(--color-accent-100);
            }
          }

          nav a:nth-child(5n + 3) {
            &:hover,
            &:focus,
            &.active {
              background-color: var(--color-accent-200);
            }

            figure {
              background-color: var(--color-accent-200);
            }
          }

          nav a:nth-child(5n + 4) {
            &:hover,
            &:focus,
            &.active {
              background-color: var(--color-accent-300);
            }

            figure {
              background-color: var(--color-accent-300);
            }
          }

          nav a:nth-child(5n + 5) {
            &:hover,
            &:focus,
            &.active {
              background-color: var(--color-accent-400);
            }

            figure {
              background-color: var(--color-accent-400);
            }
          }

          nav a:hover,
          nav a:focus,
          nav a.active {
            background: var(--color-primary-000);
            color: var(--color-primary-200);

            figure {
              background: var(--color-primary-200);

              svg-icon,
              nav a:nth-child(5n + 1):focus figure svg-icon {
                filter: invert(100%);
              }
            }
          }

          @media (max-width: 900px) {
            :host(:not(.slide)) {
              nav span[data-text] {
                display: none;
              }
            }
          }

          @media only screen and (min-width: 900px) {
            nav {
              padding: var(--gutter-small) 0 var(--gutter-small) var(--gutter-small);
            }
          }

          /* on smallest viewport, make side-bar slide out to full screen */
          @media only screen and (max-width: 600px) {
            :host {
              position: fixed;
              left: 0;
              top: 0;
              right: 0;
            }

            nav.sidebar-opened {
              background-color: var(--color-primary-100, white);
            }

            :host(.slide) span[data-text] {
              display: block !important;
            }

            a[data-id].in-bottom {
              display: none;
            }
          }
        `,
      ];
    }

    connectedCallback() {
      super.connectedCallback();

      const me = this;
      me.aside = this.closest("aside");
      me.sideBar = this.aside.querySelector("side-bar");
      const closeSideMenu = () => {
        me.sideBar.classList.toggle("slide", false);
      };

      // fired indirectly, through left button on bottom-bar
      app.on("toggle-side-menu", () => {
        me.sideBar.classList.toggle("slide");
        if (me.sideBar.classList.contains("slide")) {
          hookEscapeKey(closeSideMenu);
          me.aside.addEventListener("click", closeSideMenu, {
            once: true,
          });
        }
      });
    }
  }
);
