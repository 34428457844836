import { RoleMixin } from "../../shared/pwa-page";
import { AdminEditPage } from "../../shared/admin";
import { Session } from "../../shared/session";
import { RoleDomain } from "../../domain/role-domain";
import { html } from "lit";
import { UserDomain } from "../../domain/user-domain";
import { repeat } from "lit/directives/repeat.js";
import { Task } from "@qogni-technologies/pwa-utils-library/utils/task.js";

export class PageUserEdit extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminEditPage) {
  #roleDomain;

  redirect() {
    debugger;
  }

  static get properties() {
    return {
      ...super.properties,
      roles: {type: Array},
    };
  }

  constructor() {
    super(new UserDomain());
    this.#roleDomain = new RoleDomain();
    this.roles = [];
  }

  async fetch(options = {}) {
    await super.fetch(options);
    await Task.run(async () => {
      this.roles = (await this.#roleDomain.list({per_page: 1000})).data;
    }, {
      ghost: document.documentElement,
    });
  }

  get fields() {
    return [
      {
        label: 'Roles',
        property: 'roles',
        render: (field, value) => html`
          <multi-select>
            ${repeat(this.roles, (role) => html`
                <label>
                  <input type="checkbox" name="roles[]" value="${role.id}"
                         ?checked=${this.object.roles.filter((r) => r.id === role.id).length !== 0}>
                  <span data-label>${role.name}</span>
                </label>
              `)}
          </multi-select>
        `
      }
    ];
  }
}
