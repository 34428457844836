import { AppDomainHandler } from "../shared/app-domain-handler";
import {ApiRequest} from "../shared/APIRequest";

export class StatisticsDomain extends AppDomainHandler {
  #api;

  constructor() {
    super();
    this.#api = ApiRequest.factory();
  }

  async totalUsers(options) {
    if (options?.organisationId) {
      return (await this.#api.getData(`/statistics/organisations/${options.organisationId}/users/totals?`)).data;
    }
    return (await this.#api.getData('/statistics/users/total?'+(new URLSearchParams(options)))).data;
  }
  async topTopics(organisationId) {
    return (await this.#api.getData(`/statistics/organisations/${organisationId}/interest_topics`)).data;
  }
  async leaderboard(organisationId, options = {}) {
    return (await this.#api.getData(`/statistics/organisations/${organisationId}/leaderboard?${new URLSearchParams(options)}`)).data;
  }
  async activeUsers(options) {
    if (options?.organisationId) {
      return (await this.#api.getData(`/statistics/organisations/${options.organisationId}/users/active?`)).data;
    }
    return (await this.#api.getData('/statistics/users/active?'+(new URLSearchParams(options)))).data;
  }
  async programs(organisationId) {
    return (await this.#api.getData(`/statistics/organisations/${organisationId}/programs?`)).data;
  }
  async fivePillar(organisationId) {
    return (await this.#api.getData(`/statistics/organisations/${organisationId}/five-pillars`)).data;
  }
  async sales() {
    return (await this.#api.getData('/statistics/orders/sales')).data;
  }
  async budget(organisation_id, options = {}) {
    return (await this.#api.getData(`/statistics/organisations/${organisation_id}/budget?${new URLSearchParams(options)}`)).data;
  }
  async burnout(organisation_id = null) {
    return (await this.#api.getData(`/statistics/burnout?${new URLSearchParams({organisation_id})}`)).data;
  }
  async calculateRoi(organisationId, options = {}) {
    return (await this.#api.postData(`/statistics/organisations/${organisationId}/roi-calculator`, options)).data;
  }
  async energyMatrix(organisationId) {
    return (await this.#api.getData(`/statistics/organisations/${organisationId}/energy-matrix`)).data;
  }
  async discModel(organisationId) {
    return (await this.#api.getData(`/statistics/organisations/${organisationId}/disc-model`)).data;
  }
}
