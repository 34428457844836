import { html } from 'lit-element';
import { DashboardBlock } from "./base";
import { StatisticsDomain } from "../../../domain/statistics-domain";
import { Task } from "@qogni-technologies/pwa-utils-library/utils/task.js";
import { nothing } from "lit";

customElements.define(
  "stat-users",
  class UserStat extends DashboardBlock {
    #statDomain;

    constructor() {
      super();
      this.#statDomain = new StatisticsDomain();
      this.type = 'total';
    }

    static get properties() {
      return {
        ...super.properties,
        type: {type: String, attribute: true},
        size: {type: String, attribute: true},
      }
    }

    static get demoData() {
      return {
        total_users: 244,
        active_users: 124,
        total_activated_users: 174,
        created_last_week: 40,
        created_this_week: 14,
        trashed_users: 41,
      };
    }

    async fetch(refresh = false) {
      await super.fetch(refresh);
      if (! this.filter.organisationId) {
        this.data = UserStat.demoData;
        this.requestUpdate();
        console.warn('Filters not set for the users stat. Using demo data.');
        return;
      }
      if (this.data && ! refresh) return;

      return Task.run(async() => {
        if (this.type === 'active') {
          this.data = await this.#statDomain.activeUsers(this.filter);
        } else {
          this.data = await this.#statDomain.totalUsers(this.filter);
        }
        this.requestUpdate();
      }, {
        global: false,
        ghost: this
      });
    }

    get value() {
      if (this.type === 'total') return this.data?.total_users ?? undefined;
      if (this.type === 'activated') return this.data?.total_activated_users ?? undefined;
      if (this.type === 'active') return this.data?.active_users ?? undefined;
      return null;
    }
    get label() {
      if (this.type === 'total') return 'Total users';
      if (this.type === 'activated') return 'Total activated users';
      if (this.type === 'active') return 'Total active users';
    }

    render() {
      return html`
        <comparison-stat
          score="${this.value ?? '?'}"
          ?loading="${this.value === undefined}"
          label="${this.label}"
          icon="${this.type === 'activated' ? 'account-add' : 'people'}"
          prefix=""
          size=${this.size}
          color="red"
        ></comparison-stat>

        ${this.cta ? html`
          <a href="/tenants/organisations/${this.filter.organisationId}/view" class="button tiny outline">
            Top up
          </a>
        ` : nothing}
      `;
    }
  }
);
