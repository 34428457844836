import { GenericDomain } from "./generic-domain";

export class OrganisationDomain extends GenericDomain {
  constructor() {
    super('/organisations');
  }

  async showFinancialStats(organisationId){
    this.api.getData(`${this.baseUrl('GET')}/${organisationId}/ledger_accounts`);
  }
}
