import { createRef } from "lit/directives/ref.js";
import { GenericDomain } from "../../domain/generic-domain";
import { AdminEditPage } from "../../shared/admin";
import { OptionsCategoriesAutocomplete } from "../../shared/autocompletes";
import { RoleMixin } from "../../shared/pwa-page";
import { Session } from "../../shared/session";

export class PageOptionEdit extends RoleMixin(
  Session.ROLE_QOGNI_ADMIN,
  AdminEditPage
) {
  title = "Options";

  constructor() {
    super(new GenericDomain('/options'));
  }

  get fields() {
    const userRef = createRef();
    return [
      {
        label: "Name",
        property: "name",
        required: true,
        type: String,
      },
      {
        label: "Option Categories",
        property: "option_category_id",
        required: true,
        type: String,
        ref: userRef,
        autoComplete: new OptionsCategoriesAutocomplete(userRef).autoComplete,
      },
    ];
  }
}
