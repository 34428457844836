import { EventTargetMixin, parseHTML } from "@qogni-technologies/design-system/shared/common.js";
import { html } from "lit";
import { PureSPA } from "pure-web/spa";
import { config } from "./qogni-app-config";
import { Session } from "./shared/session";
import { polyfillsLoaded } from "./polyfills/polyfillsLoader";

customElements.define(
  "qogni-app",
  class QogniApp extends EventTargetMixin(PureSPA) {
    #session;

    #toaster;
    #initialized = false;

    #footerRef;
    #asideRef;

    static get config() {
      return config;
    }

    get session() {
      return this.#session;
    }
    get initialized() {
      return this.#initialized;
    }

    async beforeInitialize() {
      await polyfillsLoaded;
      await this.session.init();
      this.updateMenuButtons();
      return true;
    }

    get isOnboarded() {
      try {
        return this.session?.isOnboarded;
      } catch {
        return false;
      }
    }

    constructor() {
      super();
      window.app = this;
    }

    async connectedCallback() {
      try {
        super.connectedCallback();

        await this.initialize();
      } catch (ex) {
        console.error("Error initializing: ", ex);
      }
    }

    async initialize() {
      this.#session = Session.factory(this);

      this.#session.addEventListener("authenticated", () => {
        this.#updateUserDetails();
        this.updateMenuButtons();

        // TODO: Text for new users (signed-up) different from returning users
        setTimeout(() => {
          app.addToastMessage(
            `Welcome ${this.session.user?.firstname || ""} to Qogni!`
          );
        }, 5000);
      });

      this.#initialized = true;
    }

    /**
     * Add a toast message
     * @param {*} msg
     * @param {*} options
     */
    addToastMessage(msg, options = { type: "info" }) {
      const ev = new CustomEvent("notification", {
        detail: {
          text: msg,
          ...(options || {}),
          type: options?.type || "info",
        },
      });
      window.dispatchEvent(ev);
    }

    #updateUserDetails(u) {
      if (!u) return;
      if (this.querySelector('account-link > a > strong')) {
        this.querySelector('account-link > a > strong').innerText = u.firstname || 'Anonymous';
        this.querySelector('account-link img').src = u.profile_img_url || '/assets/img/profiles/profile-picture.webp';
      }
    }

    render() {
      return html`
        <main>
          <header></header>
          <main-grid>${super.render()}</main-grid>
        </main>
        <slide-panel id="meta-panel"></slide-panel>
      `;
    }

    updated(props) {
      super.updated(props);
      const path = "/" + location.pathname.substring(1);
      setTimeout(() => {
        this.fire("activeRoute", {
          path: path,
        });
      }, 100);
    }

    firstUpdated() {
      this.addGlobalAppDOM();
    }

    addGlobalAppDOM() {
      this.#footerRef = parseHTML(/*html*/ `<footer>
        <bottom-bar appearance="global" class="not-anonymous"></bottom-bar>
      </footer>`)[0];

      this.insertAdjacentElement("beforeend", this.#footerRef);

      this.#asideRef = parseHTML(/*html*/ `<aside>
        <side-bar appearance="global" class="not-anonymous"></side-bar>
      </aside>`)[0];

      this.insertAdjacentElement("beforeend", this.#asideRef);

      this.#toaster = parseHTML(/*html*/ `
          <message-toaster></message-toaster>
        `)[0];

      this.insertAdjacentElement("beforeend", this.#toaster);

      document.querySelector("header").addEventListener("mousedown", () => {
        location.href = "/";
      });

      this.updateMenuButtons();
    }

    updateMenuButtons() {
      this.#asideRef.querySelector("side-bar").items = this.getButtons("side");
      this.#footerRef.querySelector("bottom-bar").items = this.getButtons("bottom");
    }

    /**
     * Get sorted list of buttons for top, side, or bottom bar
     * @param {String} position (top|side|bottom)
     * @returns {Array}
     */xw
    getButtons(position) {
      const items = this.getGlobalMenuButtons();

      // filter and sort menu items depending on type
      return items
        .filter((i) => {
          if (i.config?.role && !app.session.hasRole(i.config?.role)) {
            return false;
          }
          const menu = i.config?.menu ?? { index: 0 };

          return menu[position]?.index > 0;
        })
        .sort((a, b) => {
          const aSettings = a.config?.menu[position],
            aIndex = aSettings?.index;
          const bSettings = b.config?.menu[position],
            bIndex = bSettings?.index;
          return aIndex > bIndex ? 1 : -1;
        });
    }

    getGlobalMenuButtons() {
      const items = [];
      items.push({
        id: "menu",
        path: "#",
        config: {
          icon: "hamburger",
          menu: {
            bottom: {
              index: 1,
            },
            side: {
              index: 0,
            },
          },
        },
      });

      for (const pageConfig of app.config.pages) {
        items.push({
          id: pageConfig.id,
          path: pageConfig.path,
          ...pageConfig,
        });
      }

      if (app.session.isAdmin) {
        const backofficeUrl = "https://backoffice.dev.qogni.io/";
        // todo: determinate url when in production later on.
        items.push({
          id: "backoffice",
          name: "Backoffice",
          config: {
            icon: "tune",
            path: backofficeUrl,
            target: "_blank",
            menu: {
              side: {
                index: 9999,
              },
            },
          },
        });
      }
      return items;
    }

    get notFoundPage() {
      return html`<http-404></http-404>`
    }
  }
);
