import { GenericDomain } from "./generic-domain";

export class ImportCsvDomain extends GenericDomain {
  constructor() {
    super("/users/import_tasks");
  }

  async getTask(taskId) {
    const url = `${this.baseUrl("show")}/${taskId}`;
    return await this.api.getData(url);
  }

  async upload(taskId, formdata) {
    const url = `${this.baseUrl("show")}/${taskId}/upload`;
    return await this.api.uploadFile("POST", url, formdata);
  }

  async mapping(taskId, mapping) {
    const url = `${this.baseUrl("show")}/${taskId}/mapping`;
    return await this.api.postData(url, { mapping });
  }

  async execute(taskId, dryRun = false) {
    const url = `${this.baseUrl("show")}/${taskId}/execute`;
    return await this.api.postData(url, { dry_run: dryRun });
  }
}
