import {AdminDomainHandler} from "../shared/app-domain-handler";

export class GenericDomain extends AdminDomainHandler {
  #baseUrl;

  constructor(baseUrl) {
    super();
    this.#baseUrl = baseUrl;
  }

  setBaseUrl(baseUrl) {
    this.#baseUrl = baseUrl;
  }

  baseUrl(operation, options) {
    if (typeof this.#baseUrl === 'function') return this.#baseUrl(operation, options);
    return this.#baseUrl;
  }
}
