import { GenericDomain } from "../domain/generic-domain";
import { UserDomain } from "../domain/user-domain";

export class UserAutocomplete extends EventTarget {
  constructor(ref) {
    super();
    this.userList = [];
    this.ref = ref;
    this.domain = new UserDomain();
  }

  get autoComplete() {
    return {
      categories: {
        Users: {
          trigger: (options) => {
            return true;
          },

          action: (options) => {
            this.ref.value.value = options.user.id;
          },

          getItems: async (options) => {
            const request = await this.domain.list({
              filter: options.search,
              per_page: 25,
              abortPrevious: true,
            });

            const data = request.data;

            return data.map((i) => {
              let description = `
                  ${i.has_connection ? `<strong>Connected</strong>, ` : ``}
                  ${i.followers_count ? `${i.followers_count} followers, ` : ``}
                `.trim().replace('\n', '');
              if (description.substring(description.length - 1) === ',') description = description.substring(0, description.length - 1);
              return {
                text: `${i.firstname} ${i.lastname}`,
                description,
                image: i.profile_img_url ?? "/assets/img/profile-picture.webp",
                user: i,
              };
            });
          },
        },
      }
    }
  }
}

/**
 * TagAutocomplete class provides an interface for autocompleting tag entries.
 * It extends EventTarget to handle events such as selecting a tag.
 */
export class TagAutocomplete extends EventTarget {
  constructor() {
    super();
    this.tagList = [];
    this.domain = new GenericDomain('/tags');
  }

  get autoComplete() {
    return {
      categories: {
        Tags: {
          trigger: (options) => {
            return true;
          },

          action: (options) => {
            this.dispatchEvent(new CustomEvent('selected', {
              detail: options
            }));
          },

          getItems: async (options) => {
            const request = await this.domain.list({
              filter: options.search,
              per_page: 25,
              abortPrevious: true,
            });
            return request.data.map((i) => {
              return {
                text: i.name,
                description: `Type: ${i.type ?? '-'}, Slug: ${i.slug ?? '-'}`,
                tag: i
              };
            });
          },
        },
      }
    }
  }
}

export class OptionsCategoriesAutocomplete extends EventTarget {
  constructor(ref) {
    super();
    this.optionsList = [];
    this.ref = ref;
    this.domain = new GenericDomain('/option_categories');
  }

  get autoComplete() {
    return {
      categories: {
        Tags: {
          trigger: (options) => {
            return true;
          },

          action: (options) => {            
            this.ref.value.value = options.item.id;
          },

          getItems: async (options) => {
            const request = await this.domain.list({
              filter: options.search,
              per_page: 25,
              abortPrevious: true,
            });
            
            return request.data.map((i) => {
              return {
                text: i.name,
                item: i
              };
            });
          },
        },
      }
    }
  }
}