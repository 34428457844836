import { AuthenticatedMixin, RoleMixin } from "../shared/pwa-page";
import { Session } from "../shared/session";
import { AdminPage } from "../shared/admin";
import { html } from "lit";

export const links = () => {
  return [{
    name: 'Admin',
    link: '/dashboards/admin',
    role: Session.ROLE_QOGNI_ADMIN,
    active: (e) => {
      return e && e.pattern.pathname.startsWith('/dashboards/admin');
    }
  }, {
    name: 'HR',
    link: '/dashboards/hr',
    icon: 'account',
    active: (e) => {
      return e && e.pattern.pathname.startsWith('/dashboards/hr');
    }
  }];
}

export class PageHome extends AuthenticatedMixin(AdminPage) {
  title = 'Dashboard';
  #notAuthenticated = null;

  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
    if (app.session.hasRole(Session.ROLE_QOGNI_ADMIN)) {
      location.replace('/dashboards/hr');
      // location.replace('/dashboards/admin');
    } else if (app.session.hasRole(Session.ROLE_HR) && app.session.user?.organisation_id) {
      location.replace('/dashboards/hr');
    } else {
      this.#notAuthenticated = true;

    }
  }

  render() {
    if (this.#notAuthenticated === true) {
      document.querySelector('aside')?.remove();
    }
    if (app.session.hasRole(Session.ROLE_HR) && ! app.session.user?.organisation_id) {
      return html`
        <section class="card">
          <strong>
            Your HR account is no longer actively connected to your organisation. Please contact your administrator.
          </strong>
          <a href="/logout" class="button tiny">
            Click here to logout
          </a>
        </section>
      `;
    }
    return html`
      <section class="card">
        <strong>
          Your account has no access to this page. Please contact your administrator.
          <a href="/logout" class="button tiny">
            Click here to logout
          </a>
        </strong>
      </section>
    `;
  }
}

