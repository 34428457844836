import { GenericDomain } from "../../domain/generic-domain";
import { AdminDestroyPage } from "../../shared/admin";
import { RoleMixin } from "../../shared/pwa-page";
import { Session } from "../../shared/session";

export class OptionCategoryDestroy extends RoleMixin(
  Session.ROLE_QOGNI_ADMIN,
  AdminDestroyPage
) {
  title = "Option delete";

  constructor() {
    super(new GenericDomain('/option_categories'));
  }
}
