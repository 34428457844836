import "./program-list";
import "./program-edit";

export const links = [
  {
    name: 'Programs',
    link: '/programs',
    icon: 'calendar',
    active: (activeRoute) => {
      return activeRoute.pattern.pathname.startsWith('/programs');
    }
  }, {
    name: 'Supplements',
    link: '/programs/supplements',
    icon: 'supplement',
  }
];
