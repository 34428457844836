import { html } from "lit";
import { DashboardBlock } from "./base";
import { StatisticsDomain } from "../../../domain/statistics-domain";
import { Task } from "@qogni-technologies/pwa-utils-library/utils/task.js";
import { ref } from "lit/directives/ref.js";
import ApexCharts from "apexcharts";

customElements.define(
  "energy-matrix",
  class EnergyMatrixStat extends DashboardBlock {
    #statDomain;

    constructor() {
      super();
      this.#statDomain = new StatisticsDomain();
      this.graph = null;
      this.data = [];
    }

    static get properties() {
      return {
        ...super.properties,
        size: { type: String, attribute: true },
      };
    }

    async fetch(refresh = false) {
      await super.fetch(refresh);
      if (!this.filter.organisationId) {
        this.data = [
          {
            name: "Corrosive Energy",
            value: 314,
          },
          {
            name: "Productive Energy",
            value: 183,
          },
          {
            name: "Resigned Energy",
            value: 187,
          },
          {
            name: "Comfortable Energy",
            value: 180,
          },
        ];
        this.requestUpdate();
        return;
      }
      if (this.data.length > 0 && !refresh) return;

      return Task.run(
        async () => {
          this.data = await this.#statDomain.energyMatrix(
            this.filter.organisationId
          );
          this.requestUpdate();
        },
        {
          global: false,
          ghost: this,
        }
      );
    }

    async getOptions() {
      const { series, labels } = this.data
        .sort((a, b) => {
          const order = {
            "Corrosive Energy": 4,
            "Productive Energy": 1,
            "Resigned Energy": 2,
            "Comfortable Energy": 1,
          };
          return order[a.name] - order[b.name];
        })
        .map((e) => {
          if (e.name === "Resigned Energy") {
            e.name = "Resigned Inertia";
          }
          return e;
        })
        .reduce(
          (acc, cur) => {
            acc.series.push(cur.value);
            acc.labels.push(cur.name);
            return acc;
          },
          { series: [], labels: [] }
        );

      return {
        series: series,
        labels: labels,
        chart: {
          type: "polarArea",
        },
        stroke: {
          colors: ["#fff"],
        },
        fill: {
          opacity: 0.8,
        },
        colors: ["#0094ca", "#ffb811", "#e6392a", "#96d700"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 175,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    }

    get value() {
      return null;
    }

    async initGraph(el) {
      if (!this.graph && this.data.length > 0) {
        this.graph = new ApexCharts(el, await this.getOptions());
        this.graph.render();
      }
    }

    render() {
      return html`
        ${this.loading
          ? html`<app-shimmer class="image"></app-shimmer>`
          : this.data.length > 0
          ? html`
              <h3>Energy Matrix</h3>
              <section class="energy-matrix-container">
                <span class="label quality-label quality-positive"
                  >Positive</span
                >
                <span class="label quality-label quality-title"
                  >Quality of Organisational Energy</span
                >
                <span class="label quality-label quality-negative"
                  >Negative</span
                >
                <span class="label intensity-label intensity-high"
                  >High</span
                >
                <span class="label intensity-label intensity-title"
                  >Intensity of Organisational Energy</span
                >
                <span class="label intensity-label intensity-low"
                  >Low</span
                >
                <div ${ref(this.initGraph.bind(this))}></div>
              </section>
            `
          : html`<i>No data available.</i>`}
      `;
    }
  }
);
