{
  "name": "backoffice-app",
  "version": "1.0.0",
  "scripts": {
    "dev": "node esbuild-dev.js",
    "dev-api": "npm run dev -- --api-root=https://api.dev.qogni.io/v1.0",
    "build": "node esbuild-build.js&tsc",
    "docker-local": "docker run --rm -p 8282:80 -p 8001:8001 -v ./public:/usr/share/nginx/html qogni/pwa-boilerplate-web"
  },
  "dependencies": {
    "@qogni-technologies/design-system": "^3.0.0",
    "@qogni-technologies/pwa-utils-library": "^1.1.0",
    "@tanstack/lit-table": "^8.18.0",
    "apexcharts": "^3.49.1",
    "esbuild": "^0.20.0",
    "esbuild-sass-plugin": "^3.2.0",
    "highcharts": "^11.4.8",
    "lit": "^3.0.0",
    "minimist": "^1.2.8",
    "pure-web": "^1.0.35",
    "sass": "^1.77.1",
    "showdown": "^2.1.0",
    "typescript": "^5.4.5",
    "urlpattern-polyfill": "^6.0.2"
  },
  "devDependencies": {
    "@eslint/js": "^9.2.0",
    "eslint": "^9.2.0",
    "eslint-plugin-lit": "^1.12.0",
    "eslint-plugin-wc": "^2.1.0",
    "glob": "^10.3.15",
    "globals": "^15.2.0"
  }
}
