import { RoleMixin } from "../../shared/pwa-page";
import { AdminEditPage } from "../../shared/admin";
import { Session } from "../../shared/session";
import { GenericDomain } from "../../domain/generic-domain";
import { html } from "lit";
import { createRef, ref } from "lit/directives/ref.js";
import { repeat } from "lit/directives/repeat.js";
import { range } from "lit/directives/range.js";
import { enhanceInputs } from "@qogni-technologies/pwa-utils-library/enhancements/form-input.js";
import { Task } from "@qogni-technologies/pwa-utils-library/utils/task.js";

export class PageOrganisationUserInvite extends RoleMixin([Session.ROLE_QOGNI_ADMIN, Session.ROLE_HR], AdminEditPage) {
  title = () => {
    return html`<h1>Invite User(s)</h1>`;
  }

  #organisationDomain = new GenericDomain(`/organisations`);
  #locationsDomain = null;
  #departmentDomain = null;

  static get properties() {
    return {
      ...super.properties,
      organisationId: { type: String, attribute: 'organisationid', routeOrigin: "pathname" },
      locationId: { type: String, attribute: 'location_id' },
      departmentId: { type: String, attribute: 'department_id' },

      organisation: { type: Object },
      location: { type: Object },
      department: { type: Object },

      rows: { type: Number },
    };
  }

  async fetch(options = {}) {
    const retrieveStructure = Task.run(async () => {
      this.organisation = (await this.#organisationDomain.show(this.organisationId))?.data;
      if (this.#locationsDomain && this.locationId) {
        this.location = (await this.#locationsDomain.show(this.locationId))?.data;
        if (this.#departmentDomain && this.departmentId) {
          this.department = (await this.#departmentDomain.show(this.departmentId))?.data;
        }
      }
    });
    return Promise.all([
      super.fetch(options),
      retrieveStructure
    ]);
  }

  constructor() {
    super(new GenericDomain());
    this.rows = 1;
  }

  async connectedCallback() {
    // Query param parsing.
    const params = new URLSearchParams(window.location.search);
    if (! this.locationId) this.locationId = params.get('location_id');
    if (! this.departmentId) this.departmentId = params.get('department_id');

    this.id = 'new';
    let baseUrl = `/organisations/${this.organisationId}`;
    if (this.locationId) baseUrl += `/locations/${this.locationId}`;
    if (this.departmentId) baseUrl += `/departments/${this.departmentId}`;
    baseUrl += '/users';
    this.domain.setBaseUrl(baseUrl);

    if (this.locationId) {
      this.#locationsDomain = new GenericDomain(`/organisations/${this.organisationId}/locations`);
      if (this.departmentId) {
        this.#departmentDomain = new GenericDomain(`/organisations/${this.organisationId}/locations/${this.locationId}/departments`);
      }
    }

    super.connectedCallback();
  }

  removeRow(e) {
    this.rows--;
    this.requestUpdate();
    setTimeout(() => {
      enhanceInputs(this);
    });
  }

  addRow(e) {
    if (this.rows > 50) {
      app.addToastMessage('Maximum of rows reached: 50');
      return;
    }
    this.rows++;
    this.requestUpdate();
    setTimeout(() => {
      enhanceInputs(this);
    });
  }

  get before() {
    return html`
      <section class="card">
        <h3>
          Invitation
        </h3>
        <p>
          Sending invitations to the users you provide works like sending an invitation on your behalf. You agree that
          you are in a legal position to send invites in the Qogni system in consideration of the GDPR-laws that might
          apply. This means, we will send emails to the users below with your consent.
        </p>

        <p>
          <strong>
            Users are invited in the following structure:
          </strong>
        </p>
        <table class="w-100">
          <tr>
            <td class="bold"><strong>
              Organisation
            </strong></td>
            <td>
              ${this.organisation?.name}
            </td>
          </tr>
          <tr>
            <td class="bold"><strong>
              Location
            </strong></td>
            <td>
              ${this.location?.name ?? '-'}
            </td>
          </tr>
          <tr>
            <td class="bold"><strong>
              Department
            </strong></td>
            <td>
              ${this.department?.name ?? '-'}
            </td>
          </tr>
        </table>
      </section>
    `;
  }

  get fields() {
    return [
      {
        label: 'Firstname',
        property: 'firstname[]',
        required: true,
        type: String,
      }, {
        label: 'Lastname',
        property: 'lastname[]',
        required: true,
        type: String,
      }, {
        label: 'Work Email',
        property: 'work_email[]',
        required: true,
        type: String,
      }, {
        label: 'Language',
        property: 'language[]',
        required: false,
        type: 'Select',
        defaultChoiceName: '-- default --',
        choices: [{
          name: 'English',
          value: 'en-US'
        }, {
          name: 'Dutch',
          value: 'nl-NL'
        }]
      }
    ];
  }

  async preprocess(data) {
    data = await super.preprocess(data);
    const newData = [];

    for (let i = 0; i < data['firstname'].length; i++) {
      newData.push({
        firstname: data['firstname'][i],
        lastname: data['lastname'][i],
        work_email: data['work_email'][i],
        language: data['language'][i],
      });
    }

    return newData;
  }

  renderFields() {
    return html`
      <section class="card">
        <x-form unsanitized @action=${this.action}>
          <form>
            <div class="container">
              ${repeat(range(this.rows), (idx) => html`
                <div class="entry inline">
                  ${repeat(this.fields, (field) => {
                    return html`
                      <div class="inline">
                        ${this.renderSingleField(field, undefined)}
                      </div>
                    `;
                  })}
                </div>
              `)}
            </div>
            <flex-container breakpoint="tiny">
              <flex-item class="col-6">
                <button type="button" class="tiny wide" @click=${this.addRow.bind(this)}>
                  <svg-icon icon="plus"></svg-icon> Add row
                </button>
              </flex-item>
              <flex-item class="col-6">
                <button type="button" class="tiny outline wide" @click=${this.removeRow.bind(this)} ?disabled=${this.rows <= 1}>
                  <svg-icon icon="minus"></svg-icon> Remove last row
                </button>
              </flex-item>
            </flex-container>

            <button type="submit">Invite users</button>
          </form>
        </x-form>
      </section>
    `;
  }
}
