import {AdminDomainHandler} from "../shared/app-domain-handler";

export class ChannelAdminDomain extends AdminDomainHandler {
  constructor() {
    super();
    this.channelId = '';
  }

  baseUrl(operation, options) {
    return `/channels/${this.channelId}/users`;
  }
}
