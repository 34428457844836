import { html } from "lit";
import { RoleMixin } from "../../shared/pwa-page";
import { AdminListPage, dateTimeRenderer } from "../../shared/admin";
import { Session } from "../../shared/session";
import { GenericDomain } from "../../domain/generic-domain";
import { repeat } from "lit/directives/repeat.js";

export class PageHabitList extends RoleMixin(
  Session.ROLE_QOGNI_ADMIN,
  AdminListPage
) {
  canEdit = true;
  canDestroy = false;
  title = "Habits";

  constructor() {
    super(new GenericDomain("/habits"));
  }

  editUrl(obj) {
    return `/content/habits/${obj.id}`;
  }

  newUrl() {
    return `/content/habits/new`;
  }

  get columns() {
    return [
      { name: "Name", field: "name" },
      { name: "Description", field: "description" },
      { name: "Days of activity", field: "days_of_activity" },
      {
        name: "Tags",
        field: "tags",
        render: (row) => {
          const tags = row?.tags ?? [];
          return html`<small
            >${tags
              ? html` ${repeat(tags, (t) => html`${t.name},`)} `
              : html`-`}</small
          >`;
        },
      },
      {
        name: "Category",
        field: "habit_category",
        render: (row) => {
          return html`${row?.habit_category?.name ?? "-"}`;
        },
      },
      { name: "Created", field: "created_at", render: dateTimeRenderer },
    ];
  }
}
