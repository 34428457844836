import { html } from 'lit-element';
import ApexCharts from 'apexcharts'
import { ref } from "lit/directives/ref.js";
import { DashboardBlock } from "./base";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { StatisticsDomain } from "../../../domain/statistics-domain";
import { repeat } from "lit/directives/repeat.js";

customElements.define(
  "stat-leaderboard",
  class LeaderboardStat extends DashboardBlock {
    #statDomain;

    static get properties() {
      return {
        ...super.properties,
        period: {type: String, attribute: true},
      };
    }

    constructor() {
      super();
      this.#statDomain = new StatisticsDomain();
      this.period = 'mtd';
    }

    static get demoData() {
      return [{
          build_up_points: "2050",
          firstname: "Eleanor",
          lastname: "Bright",
          profile_img_url: null
        },
        {
          build_up_points: "1780",
          firstname: "Julian",
          lastname: "Frost",
          profile_img_url: null
        },
        {
          build_up_points: "1350",
          firstname: "Lila",
          lastname: "Fern",
          profile_img_url: null
        },
        {
          build_up_points: "940",
          firstname: "Kai",
          lastname: "Holloway",
          profile_img_url: null
        },
        {
          build_up_points: "620",
          firstname: "Dahlia",
          lastname: "Reed",
          profile_img_url: null
        }];
    }

    async fetch(refresh = false) {
      await super.fetch(refresh);
      if (! this.filter.organisationId) {
        this.data = LeaderboardStat.demoData;
        this.requestUpdate();
        console.warn('Filters not set for the leaderboard stat. Using demo data.');
        return;
      }
      if (this.data && ! refresh) return;

      return Task.run(async() => {
        this.data = await this.#statDomain.leaderboard(this.filter.organisationId, {
          period: this.period
        });
        this.requestUpdate();
      }, {
        global: false,
        ghost: this
      });
    }

    connectedCallback() {
      super.connectedCallback();
    }

    renderLoading() {
      return html`
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
      `;
    }
    renderBlock() {
      if (! this.data) return html`<i>Leaderboard can't be shown at this moment...</i>`;

      return html`
        <div class="data-table mb-tiny">
          <table>
            <thead>
            <tr>
              <th>Place</th>
              <th>Name</th>
              <th>Points build-up</th>
            </tr>
            </thead>
            <tbody>
            ${repeat(this.data, (row, idx) => {
              return html`
                <tr>
                  <td>
                    ${idx+1}
                  </td>
                  <td>
                    ${row.firstname} ${row.lastname}
                  </td>
                  <td>
                    ${row.build_up_points}
                  </td>
                </tr>
              `
            })}
            </tbody>
          </table>
        </div>
      `;
    }
  }
);
