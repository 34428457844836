import {RoleMixin} from "../../shared/pwa-page";
import {AdminListPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import {RecipeDomain} from "../../domain/recipe-domain";
import {html, nothing} from "lit";

export class PageRecipeList extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminListPage) {
  #domain;

  canEdit = true;
  title = 'Recipes';

  constructor() {
    super(new RecipeDomain());
  }

  editUrl(obj) {
    return `/content/recipes/${obj.id}`;
  }

  newUrl() {
    return `/content/recipes/new`;
  }

  destroyUrl(obj) {
    return `/content/recipes/${obj.id}/destroy`;
  }

  get columns() {
    return [
      {name: 'Name', field: 'name', sortable: true, filterable: false, searchable: false},
      {name: 'Duration', field: 'minutes', sortable: true, filterable: false, searchable: false, render: (r) => html`${r.minutes ? r.minutes + ' minutes' : nothing}`},
      {name: 'Totals', field: 'total_kcal', sortable: true, filterable: false, searchable: false, render: (r) =>  html`
        ${r.total_kcal > 0 ? html`<svg-icon icon="check" size="14px" color="#25b03"></svg-icon>` : html`<svg-icon icon="close" size="14px" color="#d02a2a"></svg-icon>`}
      `},
      {name: 'Moment', field: 'breakfast', sortable: false, filterable: false, searchable: false, render: (r) =>  html`
        ${r.breakfast ? html`<span title="Breakfast">B</span>` : nothing}
        ${r.lunch ? html`<span title="Lunch">L</span>` : nothing}
        ${r.dinner ? html`<span title="Dinner">D</span>` : nothing}
      `},
    ];
  }
}
