import { RoleMixin } from "../../shared/pwa-page";
import { AdminEditPage } from "../../shared/admin";
import { Session } from "../../shared/session";
import { GenericDomain } from "../../domain/generic-domain";

export class PageScoreDescriptionEdit extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminEditPage) {
  title = 'Score Description';

  constructor() {
    super(new GenericDomain('/score_descriptions'));
  }

  get fields() {
    return [
      {
        label: 'Description',
        property: 'description',
        readonly: false,
        expanded: true,
        required: true,
        type: String
      }
    ];
  }
}
