import {RoleMixin} from "../../shared/pwa-page";
import {AdminListPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import { GenericDomain } from "../../domain/generic-domain";
import { links } from "./index";

export class PageSupplementList extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminListPage) {
  canEdit = true;
  canDestroy = false;
  canAdd = true;
  searchable = true;
  title = 'Supplement List';

  topLinks = links

  editUrl(obj) {
    return `/programs/supplements/${obj.id}`;
  }
  newUrl(obj) {
    return `/programs/supplements/new`;
  }

  constructor() {
    super(new GenericDomain('/supplements'));
  }

  get columns() {
    return [
      {name: 'Name', field: 'name', sortable: true, searchable: true},
      {name: 'Description', field: 'description', sortable: true, searchable: true},
    ];
  }
}
