import { RoleMixin } from "../../shared/pwa-page";
import { AdminEditPage } from "../../shared/admin";
import { Session } from "../../shared/session";
import { GenericDomain } from "../../domain/generic-domain";

export class PageOrganisationCategoryEdit extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminEditPage) {

  constructor() {
    super(new GenericDomain('/organisation_categories'));
  }

  get fields() {
    return [
      {
        label: 'Name',
        property: 'name',
        required: true,
        type: String,
      }
    ];
  }
}
