import {AdminDomainHandler} from "../shared/app-domain-handler";

export class ResultProfileTextDomain extends AdminDomainHandler {
  constructor() {
    super();
    this.resultProfileId = '';
  }

  baseUrl(operation, options) {
    return `/result_profiles/${this.resultProfileId}/texts`;
  }
}
