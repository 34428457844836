import { GenericDomain } from '../../domain/generic-domain';
import { AdminListPage } from '../../shared/admin';
import { RoleMixin } from '../../shared/pwa-page';
import { Session } from '../../shared/session';
import { html } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

export class PageOrganisationLedgerAccountsList extends RoleMixin([Session.ROLE_QOGNI_ADMIN], AdminListPage) {
  searchable = true;
  canEdit = false;
  canDestroy = false;
  canAdd = false;
  canView = true;
  title = 'Organisation Ledger Accounts';

  static get properties() {
    return {
      ...super.properties,
      organisationId: { type: String, attribute: 'organisationid', routeOrigin: "pathname" },
    };
  }

  editUrl() {
    return ''
  }

  newUrl() {
    return '';
  }

  viewUrl(obj) {
    return `/tenants/organisations/${this.organisationId}/ledger/${obj.id}`;
  }

  constructor() {
    super(new GenericDomain());
  }

  connectedCallback() {
    this.domain.setBaseUrl(`/organisations/${this.organisationId}/ledger_accounts`);
    super.connectedCallback();
  }

  get columns() {
    return [
      {name: 'Currency', field: 'currency_id', render: (obj) => html`${obj.currency.name} (${unsafeHTML(obj.currency.symbol)})`},
      {name: 'Balance', render: (obj) => html`${unsafeHTML(obj.currency.symbol)} ${Number(obj.balance).toFixed(2)}`}
    ];
  }
}
