import { RoleMixin } from "../../shared/pwa-page";
import { AdminEditPage } from "../../shared/admin";
import { Session } from "../../shared/session";
import { GenericDomain } from "../../domain/generic-domain";
import { html, nothing } from "lit";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";

export class PageOrganisationDepartmentEdit extends RoleMixin([Session.ROLE_QOGNI_ADMIN, Session.ROLE_HR], AdminEditPage) {
  title = 'Department';
  #organisationDomain;
  #locationDomain;

  static get properties() {
    return {
      ...super.properties,
      organisationId: { type: String, attribute: 'organisationid', routeOrigin: "pathname" },
      departmentId: { type: String, attribute: 'departmentid', routeOrigin: "pathname" },
      locationId: { type: String, attribute: 'locationid', routeOrigin: "pathname" },
      organisation: { type: Object },
      location: { type: Object },
    };
  }

  constructor() {
    super(new GenericDomain());
    this.#organisationDomain = new GenericDomain(`/organisations`);
    this.#locationDomain = new GenericDomain();
  }

  async connectedCallback() {
    this.id = this.departmentId;
    super.connectedCallback();
    this.#locationDomain.setBaseUrl(`/organisations/${this.organisationId}/locations`);
    this.domain.setBaseUrl(`/organisations/${this.organisationId}/locations/${this.locationId}/departments`);
  }

  async fetch(options = {}) {
    const fetchOrganisation = Task.run(async () => {
      this.organisation = (await this.#organisationDomain.show(this.organisationId))?.data;
    });
    const fetchLocation = Task.run(async () => {
      if (this.locationId) this.location = (await this.#locationDomain.show(this.locationId))?.data;
    });

    return Promise.all([
      super.fetch(options),
      fetchOrganisation,
      fetchLocation,
    ]);
  }

  get before() {
    return html`
      <section class="card">
        <p>
          <strong>
            ${this.createNew ? html`
              Department will be created in the following structure:
            ` : html`
              Department is in the following structure:
            `}
          </strong>
        </p>
        <table class="w-100">
          <tr>
            <td class="bold"><strong>
              Organisation
            </strong></td>
            <td>
              ${this.organisation?.name}
            </td>
          </tr>
          ${this.location ? html`
            <tr>
              <td class="bold"><strong>
                Location
              </strong></td>
              <td>
                ${this.location?.name}
              </td>
            </tr>
          ` : nothing}
        </table>
      </section>
    `;
  }

  get fields() {
    return [
      {
        label: 'Name',
        property: 'name',
        required: true,
        type: String,
      }
    ];
  }
}
