import {AuthenticatedMixin, PWAPage, RoleMixin} from "../../shared/pwa-page";
import {Session} from "../../shared/session";
import {html} from "lit";

import "./recipe-list";
import "./recipe-edit";
import "./ingredient-list";
import "./ingredient-edit";
import "./article-category-list";
import "./article-category-edit";
import "./article-list";
import "./article-edit";
import "./option-list";
import "./option-edit";
import "./option-destroy";
import "./option-category-list";
import "./option-category-edit";
import "./option-category-destroy";

export class PageContent extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AuthenticatedMixin(PWAPage)) {
  render() {
    return html`
      <link-card>
        <a href="/content/recipes" class="card-link light-green">
          <figure>
            <svg-icon
              size="100%"
              style="--icon-size: 11px"
              icon="document"
            ></svg-icon>
          </figure>

          <span>Recipes</span>
        </a>
      </link-card>

      <link-card>
        <a href="/content/ingredients" class="card-link red">
          <figure>
            <svg-icon
              size="100%"
              style="--icon-size: 11px"
              icon="document"
            ></svg-icon>
          </figure>

          <span>Ingredients</span>
        </a>
      </link-card>

      <link-card>
        <a href="/content/article_categories" class="card-link light-blue">
          <figure>
            <svg-icon
              size="100%"
              style="--icon-size: 11px"
              icon="document"
            ></svg-icon>
          </figure>

          <span>Article Categories</span>
        </a>
      </link-card>

      <link-card>
        <a href="/content/articles" class="card-link yellow">
          <figure>
            <svg-icon
              size="100%"
              style="--icon-size: 11px"
              icon="document"
            ></svg-icon>
          </figure>

          <span>Articles</span>
        </a>
      </link-card>

      <link-card>
        <a href="/content/options" class="card-link light-blue">
          <figure>
            <svg-icon
              size="100%"
              style="--icon-size: 11px"
              icon="document"
            ></svg-icon>
          </figure>

          <span>Tip of the Days</span>
        </a>
      </link-card>

      <link-card>
        <a href="/content/option-categories" class="card-link yellow">
          <figure>
            <svg-icon
              size="100%"
              style="--icon-size: 11px"
              icon="document"
            ></svg-icon>
          </figure>

          <span>Tip of the days categories</span>
        </a>
      </link-card>
    `;
  }
}
