import { html, LitElement } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { config } from "../../qogni-app-config";
import { Task } from "@qogni-technologies/pwa-utils-library/utils/task.js";
import { GenericDomain } from "../../domain/generic-domain";
import { classMap } from "lit/directives/class-map.js";

customElements.define(
  "translations-container",
  class TranslationsContainer extends LitElement {
    createRenderRoot() {
      return this;
    }

    constructor() {
      super();
      this.domain = new GenericDomain('/translations');
      this.existingTranslations = {};
      this.aiTranslations = {};
    }

    get properties() {
      return {
        ...super.properties,
        languages: {
          type: Array,
          attribute: true,
        },
        property: {
          type: String,
          attribute: true,
        },
        type: {
          type: String,
          attribute: true
        },
        id: {
          type: String,
          attribute: true
        },
        value: {
          type: String,
          attribute: true,
        },
        context: {
          type: String,
          attribute: true,
        },
        existingTranslations: {
          type: Object,
          attribute: true,
        },
        aiTranslations: {
          type: Object,
          attribute: true,
        }
      };
    }

    #aiTranslate() {
      Task.run(async () => {
        const response = await this.domain.api.postData('/translations/ai-assist', {
          input: this.value,
          locales: this.languages,
          extra_info: this.context,
        });
        if (! response || ! response.data || ! response.data.translations?.length) return;
        this.aiTranslations = response.data.translations.reduce((acc, translation) => {
          acc[translation.locale] = translation.value;
          return acc;
        }, {});
        this.requestUpdate();
      }, {
        ghost: this.renderRoot,
        description: 'AI Translate'
      });
    }

    #markEditted(e) {
      e.target.classList.add('edited');
    }

    #save() {
      const tasks = [];
      for (const languageCode of this.languages) {
        // Try to get the field and translated value.
        const translatedText = this.querySelector('language-container[data-language="'+languageCode+'"] input')?.value;
        const existingId = this.querySelector('language-container[data-language="'+languageCode+'"] input')?.dataset?.id;
        if (! translatedText && ! existingId) continue;

        if (existingId && translatedText) {
          tasks.push(this.domain.update(existingId, {
            value: translatedText
          }));
        } else if (existingId && ! translatedText) {
          tasks.push(this.domain.destroy(existingId));
        } else if (! existingId && translatedText) {
          tasks.push(this.domain.create( {
            translatable_type: this.type,
            translatable_id: this.id,
            key: this.property,
            locale: languageCode,
            value: translatedText,
          }));
        }
      }

      Task.run(async () => {
        await Promise.all(tasks);
        app.addToastMessage('Translations are saved!');
      });
    }

    fetch() {
      Task.run(async () => {
        const results = await this.domain.list({
          filter_key: this.property,
          filter_translatable_type: this.type,
          filter_translatable_id: this.id,
        });

        if (results.data) {
          // For loop over the data and insert into existing translations (key = locale, value = translation).
          for (const data of results.data) {
            this.existingTranslations[data.locale] = data;
          }
        }
        this.requestUpdate();
      }, {
        ghost: this.renderRoot,
        description: 'Fetch existing translations...'
      });
    }

    connectedCallback() {
      super.connectedCallback();
      this.fetch();
    }

    render() {
      return html`
        <section class="card">
          ${repeat(this.languages, (languageCode) => {
            const languageName = config.languages[languageCode];
            let value = null;
            let id = null;
            const classList = {};

            if (this.existingTranslations && this.existingTranslations.hasOwnProperty(languageCode)) {
              value = this.existingTranslations[languageCode].value;
              id = this.existingTranslations[languageCode].id;
            }
            if (this.aiTranslations && this.aiTranslations.hasOwnProperty(languageCode)) {
              value = this.aiTranslations[languageCode];
              classList['ai-translated'] = true;
            }

            return html`
              <language-container title="${languageName ? languageName + ' ('+languageCode+')' : languageCode}" data-language="${languageCode}">
                <svg class="icon" xmlns="http://www.w3.org/2000/svg">
                  <use href="/assets/img/intl-flags.svg#${languageCode}"></use>
                </svg>
                <input type="text" value="${value}" placeholder="Translation in ${languageName ?? ''} (${languageCode})" data-id="${id}" class="${classMap(classList)}" @change="${this.#markEditted.bind(this)}" />
              </language-container>
            `;
          })}
          <language-controls>
            <button type="button" class="tiny" @click="${this.#save.bind(this)}">Save</button>
            <button type="button" class="tiny outline" disabled>Reset</button>
            <button type="button" class="tiny outline" @click="${this.#aiTranslate.bind(this)}">
              AI Translate <svg-icon icon="magic"></svg-icon>
            </button>
          </language-controls>
        </section>
      `;
    }
  }
);
