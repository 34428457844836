import {css, html, LitElement} from "lit";
import {parseHTML} from "@qogni-technologies/design-system/shared/common.js";

const MESSAGE_TIMEOUT = 3000;
const ICONS = [
  {id: "info", color: "#2D9CC8"},
  {id: "warning", color: "#F2A74D"},
  {id: "error", color: "#EC4F1D"},
];

customElements.define(
  "message-toaster",

  /** Renders Message Toaster to display quick notifications */
  class MessageToaster extends LitElement {
    #container;

    static get styles() {
      return [
        css`
          #toastContainer {
            pointer-events: none;
            position: fixed;
            z-index: 100;
            bottom: 90px;
            left: 0;
            right: 0;
            padding: 1em;
            display: grid;
            gap: 1em;
          }

          .toast {
            font-size: 1rem;
            font-weight: 600;
            padding: 0.7rem 1.5rem;
            margin: auto;
            border-radius: 1rem;
            background: var(--color-surface-100, white);
            color: var(--color-text-000, currentColor);
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
            filter: brightness(var(--extra-brightness, 130%));
            transform: scale(100%);
            overflow: hidden;
            transition: all 0.25s ease-in;
            display: grid;
            grid-template-columns: 36px 1fr;
            max-width: 600px;
            min-width: 200px;
            align-items: center;
            opacity: 1;
          }
          .toast > span {
            display: inline-block;
            margin-top: -6px;
          }

          .toast.out {
            opacity: 0;
            top: -500px;
            transform: scale(60%);
            transition: all 0.25s ease-in;
            will-change: transform top;
          }

          .toast.in {
            opacity: 0;
            margin-top: -110px;
            transform: scale(90%);
            transition: all 0.2s ease-in;
          }
        `,
      ];
    }

    render() {
      return html`<section id="toastContainer"></section>`;
    }

    firstUpdated() {
      this.#container = this.shadowRoot.getElementById("toastContainer");

      window.addEventListener("notification", (e) => {
        this.add(e.detail.text, e.detail.type || "info");
      });
    }

    getIcon(type) {
      return (
        ICONS.filter((i) => {
          return i.id === type;
        })[0] || ICONS[0]
      );
    }

    add(message, type) {
      const icon = this.getIcon(type);

      let toast = parseHTML(/*html*/ `
        <div class="toast in" role="status">
          <svg-icon color="${icon.color}" icon="${icon.id}"></svg-icon>
          <span>${message}</span>
        </div>`)[0];

      this.#container.appendChild(toast);

      let readTimeout = MESSAGE_TIMEOUT * 0.8;
      if (type === "error") readTimeout *= 2;

      setTimeout(() => {
        toast.classList.remove("in");
      }, 200);

      setTimeout(() => {
        toast.classList.add("out");

        setTimeout(() => {
          this.#container.removeChild(toast);
        }, 1000);
      }, readTimeout);
    }
  }
);
