import { html } from 'lit-element';
import ApexCharts from 'apexcharts'
import { ref } from "lit/directives/ref.js";
import { DashboardBlock } from "./base";
import { Task } from "@qogni-technologies/pwa-utils-library/utils/task.js";
import { StatisticsDomain } from "../../../domain/statistics-domain";

customElements.define(
  "stat-topics-pie",
  class TopicsPieStat extends DashboardBlock {
    #statDomain;

    static get properties() {
      return {
        ...super.properties,
      };
    }

    constructor() {
      super();
      this.#statDomain = new StatisticsDomain();
      this.graph = null;
    }

    static get demoData() {
      return [{
        name: 'Better work-life balance',
        interest_count: 55,
      }, {
        name: 'Calmer gut',
        interest_count: 25,
      }, {
        name: 'Stop smoking',
        interest_count: 14,
      }, {
        name: 'Moderation in alcohol consumption',
        interest_count: 8,
      }, {
        name: 'Enhancing mental resilience',
        interest_count: 6,
      }, {
        name: 'Reaching a healthy weight',
        interest_count: 5,
      }, {
        name: 'Improving sleep quality',
        interest_count: 4,
      }, {
        name: 'Balancing hormones',
        interest_count: 4,
      }];
    }

    async fetch(refresh = false) {
      await super.fetch(refresh);
      if (! this.filter.organisationId) {
        this.data = TopicsPieStat.demoData;
        this.requestUpdate();
        console.warn('Filters not set for the topics-pie stat. Using demo data.');
        return;
      }
      if (this.data && ! refresh) return;

      return Task.run(async() => {
        this.data = await this.#statDomain.topTopics(this.filter.organisationId);
        this.requestUpdate();
      }, {
        global: false,
        ghost: this
      });
    }

    async getOptions() {
      const series = [];
      const labels = [];
      let totalInterest = 0;

      this.data.forEach((d) => {
        totalInterest += d.interest_count;
        series.push(d.interest_count);
        labels.push(d.name);
      });
      return {
        series,
        labels,
        chart: {
          type: 'donut',
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'start',
        },
        colors: [
          '#d44f73',
          '#e8ab5d',
          '#49946a',
          '#2d9dc9',
          '#2b3a33',
          '#c0569b',
          '#f0c37e',
          '#66b181',
          '#4fb2d5',
          '#3c4a42',
        ],
      };
    }

    async initGraph(el) {
      if (! this.graph) {
        this.graph = new ApexCharts(
          el,
          await this.getOptions()
        );
        this.graph.render();
      }
    }

    renderLoading() {
      return html`
        <app-shimmer></app-shimmer>
        <app-shimmer class="image"></app-shimmer>
      `;
    }
    renderBlock() {
      return html`
        <div ${ref(this.initGraph.bind(this))}></div>
      `;
    }
  }
);
