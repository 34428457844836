import {RoleMixin} from "../../shared/pwa-page";
import {AdminDestroyPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import {RoleDomain} from "../../domain/role-domain";
import {html} from "lit";

export class PageRoleDestroy extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminDestroyPage) {
  title = 'Role';
  redirect = '/tenants/roles';

  constructor() {
    super(new RoleDomain());
  }

  renderDeleteForm() {
    if (this.object?.internal) return html`
      <section class="card red">
        <h3>This role is internal and can't be changed</h3>
      </section>
    `;
    return super.renderDeleteForm();
  }
}
