import { ResultProfileTextDomain } from "../../domain/result-profile-text-domain";
import { AdminDestroyPage } from "../../shared/admin";
import { RoleMixin } from "../../shared/pwa-page";
import { Session } from "../../shared/session";

export class PageResultProfileTextDestroy extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminDestroyPage) {
  constructor() {
    super(new ResultProfileTextDomain());
  }

  redirect() {
    window.location.replace(`/questionnaires/result_profiles/${this.resultProfileId}`);
  }

  static get properties() {
    return {
      ...super.properties,
      resultProfileId: { type: String, attribute: 'resultprofileid', routeOrigin: 'pathname' },
    };
  }

  connectedCallback() {
    this.domain.resultProfileId = this.resultProfileId;
    super.connectedCallback();
  }
}
