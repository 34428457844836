import { RoleMixin } from "../../shared/pwa-page";
import { AdminEditPage, TagField } from "../../shared/admin";
import { Session } from "../../shared/session";
import { GenericDomain } from "../../domain/generic-domain";

export class PageHabitCategoryEdit extends RoleMixin(
  Session.ROLE_QOGNI_ADMIN,
  AdminEditPage
) {
  title = "Habit Category";

  get fields() {
    return [
      {
        label: "Name",
        property: "name",
        required: true,
        type: String,
      },
      {
        label: "Description",
        property: "description",
        required: true,
        type: String,
        expanded: true,
        html: true,
      },
      new TagField({
        object: this.object,
        onChange: (e) => {
          if (!this.object) this.object = { tags: [] };
          this.object.tags = e.detail.tags;
          this.requestUpdate();
        },
      }),
    ];
  }

  constructor() {
    super(new GenericDomain("/habit_categories"));
  }
}
