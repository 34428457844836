import {RoleMixin} from "../../shared/pwa-page";
import { AdminListPage, dateTimeRenderer } from "../../shared/admin";
import {Session} from "../../shared/session";
import {GenericDomain} from "../../domain/generic-domain";
import {html} from "lit";

export class PageArticleList extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminListPage) {
  canEdit = true;
  canDestroy = false;
  title = 'Articles';
  filterable = true;

  #categoryDomain;

  constructor() {
    super(new GenericDomain('/articles'));
    this.#categoryDomain = new GenericDomain('/article_categories');
  }

  editUrl(obj) {
    return `/content/articles/${obj.id}`;
  }

  newUrl() {
    return `/content/articles/new`;
  }

  get columns() {
    return [
      {name: 'Name', field: 'name', sortable: true, filterable: false, searchable: false},
      {name: 'Slug', field: 'slug', sortable: true, filterable: false, searchable: false},
      {
        name: 'Category',
        field: 'category_id',
        sortable: true,
        searchable: false,
        filterable: {
          property: 'category_id',
          label: 'Category',
          choices: async () => {
            const response = await this.#categoryDomain.list({per_page: 1000});
            return response.data.map((c) => {
              return {
                value: c.id,
                name: c.name
              };
            });
          },
        },
        render: obj => {
          return html`
            <a href="/content/article_categories/${obj.category_id}">
              ${obj.category.name}
            </a>
          `;
        },
      },
      {name: 'Created', field: 'created_at', sortable: true, filterable: false, searchable: false, render: dateTimeRenderer},
    ];
  }
}
