import {RoleMixin} from "../../shared/pwa-page";
import { AdminEditPage, TagField, TranslatableField } from "../../shared/admin";
import {Session} from "../../shared/session";
import {GenericDomain} from "../../domain/generic-domain";

export class PageGlossaryDefinitionEdit extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminEditPage) {
  title = 'Glossary Definition';

  constructor() {
    super(new GenericDomain('/glossary_definitions'));
  }

  async preprocess(data) {
    const processed = await super.preprocess(data);
    if (! processed['url']) delete processed['url'];
    return processed;
  }

  get fields() {
    return [
      new TranslatableField({
        label: 'Name',
        property: 'name',
        required: true,
        type: String,

        parent: this,
        translatableType: "App\\Models\\GlossaryDefinition",
        translatableId: this.id,
        translatableContext: 'Glossary term definition name that needs translation'
      }),
      {
        label: 'URL',
        property: 'url',
        required: false,
        type: String
      },
      new TranslatableField({
        label: 'Description',
        property: 'description',
        required: false,
        type: String,
        parent: this,
        expanded: true,
        translatableType: "App\\Models\\GlossaryDefinition",
        translatableId: this.id,
        translatableContext: 'Glossary term definition description that needs translation'
      }),
      new TagField({
        object: this.object,
        onChange: (e) => {
          if (! this.object) this.object = {tags: []};
          this.object.tags = e.detail.tags;
          this.requestUpdate();
        },
      }),
    ];
  }
}
