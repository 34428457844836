import {RoleMixin} from "../../shared/pwa-page";
import { AdminListPage, dateTimeRenderer } from "../../shared/admin";
import {Session} from "../../shared/session";
import {IngredientDomain} from "../../domain/ingredient-domain";

export class PageIngredientList extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminListPage) {
  #domain;

  canEdit = true;
  canDestroy = false;
  title = 'Ingredients';

  constructor() {
    super(new IngredientDomain());
  }

  editUrl(obj) {
    return `/content/ingredients/${obj.id}`;
  }

  newUrl() {
    return `/content/ingredients/new`;
  }

  get columns() {
    return [
      {name: 'Name', field: 'name', sortable: true, filterable: false, searchable: false},
      {name: 'Unit', field: 'unit', sortable: true, filterable: false, searchable: false},
      {name: 'Created', field: 'created_at', sortable: true, filterable: false, searchable: false, render: dateTimeRenderer},
    ];
  }
}
