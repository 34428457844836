import { html } from 'lit-element';
import ApexCharts from 'apexcharts'
import { createRef, ref } from "lit/directives/ref.js";
import { DashboardBlock } from "./base";

customElements.define(
  "stat-burnout",
  class BurnoutStat extends DashboardBlock {
    static get properties() {
      return {
        ...super.properties,
        width: {
          type: Number,
          attribute: true,
        },
        height: {
          type: Number,
          attribute: true,
        }
      };
    }

    constructor() {
      super();
      this.graphRef = createRef();
      this.graph = null;
    }

    async getOptions() {
      const series = [{
        name: 'High Risk',
        data: [],
      }, {
        name: 'Medium Risk',
        data: [],
      }, {
        name: 'Low Risk',
        data: [],
      }];

      // Fill data.
      const data = await this.data;
      data.forEach((dataPoint) => {
        const score = parseInt(dataPoint.score);
        const entry = [new Date(dataPoint.created_at), score];
        if (score >= 45)
          series[0].data.push(entry);
        else if (score > 25 && score < 45)
          series[1].data.push(entry);
        else
          series[2].data.push(entry);
      });

      return {
        series,
        chart: {
          height: this.height ?? '500px',
          type: 'scatter',
          zoom: {
            type: 'xy',
          }
        },
        colors: [
          '#d44f73',
          '#e8ab5d',
          '#49946a',
        ],
        dataLabels: {
          enabled: false
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          type: 'datetime',
        },
        yaxis: {},
        legend: {
          markers: {
            strokeWidth: 1,
          },
        },
        markers: {
          shape: [
            'diamond',
            'triangle',
            'circle',
          ],
          size: 6,
          fillOpacity: 0.9,
          strokeColors: '#333',
          strokeWidth: 1,
        },
      }
    }

    connectedCallback() {
      super.connectedCallback();
    }

    async initGraph(el) {
      if (! this.graph) {
        this.graph = new ApexCharts(
          el,
          await this.getOptions()
        );
      }

      this.graph.render();
    }

    renderLoading() {
      return html`
        <app-shimmer></app-shimmer>
        <app-shimmer class="image"></app-shimmer>
      `;
    }
    renderBlock() {
      return html`
        <div ${ref(this.initGraph.bind(this))}></div>
      `;
    }
  }
);
