import { RoleMixin } from "../../shared/pwa-page";
import { AdminListPage, dateTimeRenderer } from "../../shared/admin";
import { Session } from "../../shared/session";
import { links } from "./index";
import { GenericDomain } from "../../domain/generic-domain";
import { html } from "lit";

customElements.define(
  "page-organisation-location-list",
  class PageOrganisationLocationList extends RoleMixin([Session.ROLE_QOGNI_ADMIN, Session.ROLE_HR], AdminListPage) {
    canEdit = true;
    canDestroy = false;
    canAdd = false;
    canView = true;
    searchable = true;
    title = null;

    viewLinkIcon = 'arrow';
    viewLinkText = 'Manage';
    viewLinkWidth = 90;

    static get properties() {
      return {
        ...super.properties,
        organisationId: { type: String, attribute: 'organisationid', routeOrigin: "pathname" },
      };
    }

    editUrl(obj) {
      return `/tenants/organisations/${this.organisationId}/locations/${obj.id}`;
    }

    newUrl() {
      return `/tenants/organisations/${this.organisationId}/locations/new`;
    }

    destroyUrl(obj) {
      return `/tenants/organisations/${this.organisationId}/locations/${obj.id}/destroy`;
    }

    viewUrl(obj) {
      return `/tenants/organisations/${this.organisationId}/locations/${obj.id}/view`;
    }

    constructor() {
      super(new GenericDomain());
    }

    connectedCallback() {
      this.domain.setBaseUrl(`/organisations/${this.organisationId}/locations`);
      super.connectedCallback();
    }

    get columns() {
      return [
        {name: 'Name', field: 'name', sortable: true, filterable: false, searchable: true},
        {name: 'GEO', field: 'latitude', sortable: true, render: (obj, value) => html`
          ${value ? html`<svg-icon icon="check" display="inline" color="green" size="11px" display="inline"></svg-icon>` : html`<svg-icon icon="close" display="inline" color="red" size="11px"></svg-icon>`}
        `},
        {name: 'Created', field: 'created_at', sortable: true, render: dateTimeRenderer},
      ];
    }
  }
);
