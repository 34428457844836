import {html} from "lit";
import {PWAPage} from "../shared/pwa-page";

customElements.define(
  "http-404",
  class HTTP404 extends PWAPage {
    render() {
      return html`
        <section class="card">
          <h2>Not found!</h2>
          <p>This page could not be found because it does not exist (anymore). Please use the button below to return
            to the homepage.</p>
          <a href="/" class="button">To home</a>
        </section>
      `
    }
  }
)
