import { Task } from "@qogni-technologies/pwa-utils-library/utils/task.js";
import { ImportCsvDomain } from "../../domain/import-csv-domain";
import { AdminWizardEditPage } from "../../shared/admin";
import { RoleMixin } from "../../shared/pwa-page";
import { Session } from "../../shared/session";
import { ref, createRef } from "lit/directives/ref.js";
import { html, nothing } from "lit";

export class PageOrganisationUserImportEdit extends RoleMixin(
  [Session.ROLE_QOGNI_ADMIN, Session.ROLE_HR],
  AdminWizardEditPage
) {
  #firstnameRef = createRef();
  #lastnameRef = createRef();
  #emailRef = createRef();
  #langRef = createRef();
  #sendInviteEmailCheckboxRef = createRef();
  #delimiterInputRef = createRef();
  #enclosureInputRef = createRef();
  #escapeInputRef = createRef();
  #headerOffsetInputRef = createRef();

  #dryRunInitiated = false;

  static get properties() {
    return {
      ...super.properties,
      organisationId: {
        type: String,
        attribute: "organisationid",
        routeOrigin: "pathname",
      },
      selectedFields: { type: Object },
    };
  }

  constructor() {
    super(new ImportCsvDomain());
    this.step = 0;
    this.selectedFields = {};
  }

  async connectedCallback() {
    await super.connectedCallback();
    const step = this.#determineStep();

    if (!step) await this.pollingTaskDetails();
  }

  disconnectedCallback() {
    this.#clearTimeout = true;
    super.disconnectedCallback();
    clearTimeout(this.#timeoutId);
  }

  title() {
    return html`
      <h1>Import Users</h1>
      <a class="button tiny outline" href="/tenants/organisations/${this.organisationId}/users/import/list">View old imports</a>
      <!-- <a class="button tiny outline" href="/import_template.csv" download="import_template.csv">Download Sample</a> -->
    `
  }

  #determineStep() {
    if (!this.object) return;

    const { status } = this.object;
    if (status === "pending_upload") {
      this.step = 0;
      this.saveButtonLabel = "";
      return true;
    }
    if (status === "pending_mapping") {
      this.step = 1;
      this.saveButtonLabel = "";
      return true;
    }
    if (status === "pending_confirmation" && !this.object.output) {
      this.step = 2;
      this.saveButtonLabel = "Start import";
      return true;
    }
    if (status === "pending_confirmation" && this.object.output) {
      this.step = 3;
      this.saveButtonLabel = "Execute";
      return true;
    }

    if (status === "completed") {
      app.addToastMessage('Task completed successfully.');
      app.goTo(`/tenants/organisations/${this.organisationId}/view`);
    }

    return false;
  }

  async save(e) {
    if (e) e.preventDefault();

    try {
      const task = async () => {
        const form = e.target.querySelector("form");
        const formdata = new FormData(form);

        if (this.step === 0) {
          await this.preprocess();
          const res = await this.domain.upload(this.id, formdata);
          if (!res.status) return;
          this.object = res.data;
          return await this.pollingTaskDetails();
        }

        if (this.step === 1) {
          let mapping = [];
          for (let i of formdata) {
            if (i[1]) {
              const obj = { property: i[0], column: i[1] };
              mapping.push(obj);
            }
          }

          const res = await this.domain.mapping(this.id, mapping);
          this.object = res.data;
          return await this.pollingTaskDetails();
        }

        if (this.step === 2) {
          const res = await this.domain.execute(this.id, true);
          this.object = res.data;
          this.#dryRunInitiated = true;
          return await this.pollingTaskDetails();
        }

        if (this.step === 3) {
          const res = await this.domain.execute(this.id);
          this.object = res.data;
          app.goTo(`/tenants/organisations/${this.organisationId}/view`);
          return;
        }
      };

      await Task.run(task, {
        ghost: e?.target,
        description: "Update entity",
      });
    } catch (err) {
      if (
        err.response &&
        err.response.status === 400 &&
        err.errorData &&
        err.errorData.errors
      ) {
        this.error = "";
        Object.entries(err.errorData.errors).forEach(([key, value]) => {
          this.error += ` ${key}: ${value.join(", ")}`;
        });
      }
      console.error(err);
      app.addToastMessage(
        "Error: " + (err?.errorData?.message ?? "saving form failed.")
      );
      return;
    }
  }

  async preprocess() {
    if (this.createNew) {
      const sendInviteEmail = this.#sendInviteEmailCheckboxRef.value.checked;
      const delimiter = this.#delimiterInputRef.value.value;
      const enclosure = this.#enclosureInputRef.value.value;
      const escape = this.#escapeInputRef.value.value;
      // const headerOffset = this.#headerOffsetInputRef.value.value;

      const options = {
        options: {
          send_invite_email: sendInviteEmail,
          delimiter,
          enclosure,
          escape,
        },
        input: {
          organisation_id: this.organisationId,
        },
      };
      const res = await this.domain.create(options);
      this.id = res.data.id;
      // history.replaceState({}, document.title, `/tenants/organisations/${this.organisationId}/users/import/${this.id}`);
      // this.createNew = false;
    }
  }

  #timeoutId;
  #clearTimeout = false;

  async pollingTaskDetails() {
    if (this.id === "new") return;

    const pollTaskDetails = () => {
      return new Promise((reolve) => {
        const poll = async () => {
          const res = await this.domain.getTask(this.id);
          this.object = res.data;
          const isStepDefined = this.#determineStep();
          this.#timeoutId = setTimeout(() => {
            if (this.#clearTimeout) {
              reolve();
              return;
            }
            if (!isStepDefined) {
              poll();
              clearTimeout(this.#timeoutId);
              return;
            }
            if (isStepDefined && this.step === 2 && this.#dryRunInitiated) {
              poll();
              clearTimeout(this.#timeoutId);
              return;
            }
            if (isStepDefined) {
              clearTimeout(this.#timeoutId);
              reolve();
              return;
            }
          }, 500);
        };
        poll();
      });
    };

    const task = async () => {
      await pollTaskDetails();
    };

    await Task.run(task, { ghost: this });
  }

  get steps() {
    return [
      {
        name: "upload_csv",
        label: "Upload CSV",
      },
      {
        name: "map_fields",
        label: "Map Fields",
      },
      {
        name: "dry_run",
        label: "Validation",
      },
      {
        name: "process",
        label: "Process",
      },
    ];
  }

  get fields() {
    const columns = this.object?.metadata?.columns ?? [];

    switch (this.step) {
      case 0:
        return [
          {
            label: "File",
            property: "file",
            required: true,
            type: "File",
          },
          {
            label:
              "I want to send out invite emails to the imported users in the name of the organisation.",
            property: "send_invite_email",
            type: "Checkbox",
            default: true,
            ref: this.#sendInviteEmailCheckboxRef,
          },
          {
            render: () => html`
              <details>
                <summary>Advanced settings</summary>
                <label>
                  <span data-label="">Delimiter</span>
                  <input name="delimiter" type="search" placeholder="Enter CSV delimiter" value="," ${ref(this.#delimiterInputRef)}>
                </label>
                <label>
                  <span data-label="">Enclosure</span>
                  <input name="enclosure" type="search" placeholder="Enter CSV enclosure" .value=${`"`} ${ref(this.#enclosureInputRef)}>
                </label>
                <label>
                  <span data-label="">Escape</span>
                  <input name="escape" type="search" placeholder="Enter CSV escape" .value=${"\\"} ${ref(this.#escapeInputRef)}>
                </label>
                <label>
                  <span data-label="">Header offset</span>
                  <input name="header_offset" type="number" placeholder="Enter CSV Header offset" value="0" ${ref(this.#headerOffsetInputRef)}>
                </label>
              </details>
            `
          }
        ];
      case 1:
        return [
          {
            label: "header",
            render: () =>
              html`<mapping-field
                header
                .headerTitles=${{
                  column1: "Mapping fields",
                  column2: "CSV column field",
                  column3: "Example",
                }}
              ></mapping-field>`,
          },
          {
            label: "firstname",
            property: "firstname",
            placeholder: "Please select firstname field from your csv file",
            defaultChoiceName: "",
            choices: this.getColumns(columns, "firstname"),
            required: true,
            type: "Select",
            value: this.selectedFields["firstname"],
            ref: this.#firstnameRef,
            change: this.onSelectChange,
            render: (obj) => html`<mapping-field
              name="${obj.property}"
              label="${obj.label}"
              value=${obj.value}
              example=${this.getExample(obj.value)}
              .options=${obj.choices}
              @change=${obj.change}
            ></mapping-field>`,
          },
          {
            label: "lastname",
            property: "lastname",
            placeholder: "Please select lastname field from your csv file",
            choices: this.getColumns(columns, "lastname"),
            required: true,
            type: "Select",
            value: this.selectedFields["lastname"],
            ref: this.#lastnameRef,
            change: this.onSelectChange,
            render: (obj) => html`<mapping-field
              name="${obj.property}"
              label="${obj.label}"
              value=${obj.value}
              example=${this.getExample(obj.value)}
              .options=${obj.choices}
              @change=${obj.change}
            ></mapping-field>`,
          },
          {
            label: "work_email",
            property: "work_email",
            placeholder: "Please select email field from your csv file",
            choices: this.getColumns(columns, "work_email"),
            required: true,
            value: this.selectedFields["work_email"],
            type: "Select",
            ref: this.#emailRef,
            change: this.onSelectChange,
            render: (obj) => html`<mapping-field
              name="${obj.property}"
              label="${obj.label}"
              value=${obj.value}
              example=${this.getExample(obj.value)}
              .options=${obj.choices}
              @change=${obj.change}
            ></mapping-field>`,
          },
          {
            label: "language",
            property: "language",
            placeholder: "Please select language field from your csv file",
            choices: this.getColumns(columns),
            value: this.selectedFields["language"],
            type: "Select",
            ref: this.#langRef,
            change: this.onSelectChange,
            render: (obj) => html`<mapping-field
              name="${obj.property}"
              label="${obj.label}"
              value=${obj.value}
              example=${this.getExample(obj.value)}
              .options=${obj.choices}
              @change=${obj.change}
            ></mapping-field>`,
          },
        ];
      case 2:
        return [
          {
            render: this.renderDryRunTemplate,
          },
        ];
      case 3:
        return [
          {
            render: this.renderExecuteTemplate,
          },
        ];
      default:
        return [];
    }
  }

  renderDryRunTemplate() {
    const rows = this.object?.metadata?.rows;
    return html`
      <section class="card">
        <comparison-stat
          score="${rows}"
          label="Rows found"
          icon="people"
          color="yellow"
        ></comparison-stat>
      </section>
    `;
  }

  renderExecuteTemplate() {
    const output = this.object?.output;
    const { rows, inserted, skipped, errors } = output;

    return html`
      <section class="card">
        <comparison-stat
          score="${rows}"
          label="Total users"
          icon="people"
        ></comparison-stat>
      </section>
      <section class="card">
        <comparison-stat
          score="${inserted}"
          label="Inserted users"
          icon="people"
        ></comparison-stat>
      </section>
      <section class="card">
        <comparison-stat
          score="${skipped}"
          label="Skipped users"
          icon="people"
        ></comparison-stat>
      </section>
      ${Array.isArray(errors) && errors.length
        ? html`<section class="card">
            <comparison-stat
              score="${errors.length}"
              label="Rows with errors"
              icon="error"
            ></comparison-stat>
          </section>`
        : nothing}
      <section class="card">
        <strong>These are the results of the validation. To start the process, confirm below and start the execution</strong>
      </section>
    `;
  }

  onSelectChange(e) {
    this.selectedFields[e.target.name] = e.target.value;
    this.requestUpdate();
  }

  getExample(field) {
    if (!field) return "";
    const sample = this.object?.metadata?.sample ?? [];
    return sample[0]?.[field];
  }

  getColumns(columns, field = []) {
    if (field === "firstname") {
      return columns.filter(
        (i) =>
          i !== this.selectedFields["lastname"] &&
          i !== this.selectedFields["work_email"]
      );
    }
    if (field === "lastname") {
      return columns.filter(
        (i) =>
          i !== this.selectedFields["firstname"] &&
          i !== this.selectedFields["work_email"]
      );
    }
    if (field === "work_email") {
      return columns.filter(
        (i) =>
          i !== this.selectedFields["firstname"] &&
          i !== this.selectedFields["lastname"]
      );
    }
    return columns.filter(
      (i) =>
        i !== this.selectedFields["firstname"] &&
        i !== this.selectedFields["lastname"] &&
        i !== this.selectedFields["work_email"]
    );
  }

  willUpdate(changeProps) {
    super.willUpdate(changeProps);

    if (changeProps.has("object")) {
      const columns = this.object?.metadata?.columns ?? [];

      if (columns) {
        this.selectedFields["firstname"] = columns.find((i) =>
          i.includes("first")
        );
        this.selectedFields["lastname"] = columns.find((i) =>
          i.includes("last")
        );
        this.selectedFields["work_email"] = columns.find((i) =>
          i.includes("email")
        );
        this.selectedFields["language"] =
          columns.find((i) => i.includes("language")) ?? "";
      }
    }
  }
}
