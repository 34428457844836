import { PWAPage } from "../shared/pwa-page";

export class PageLogout extends PWAPage {
  async connectedCallback() {
    super.connectedCallback();

    try {
      await app.session.logout();
    } catch (e) {
      console.warn(e);
    }

    app.goTo('/enter?logout=1', {force: true});
  }
}
