import {RoleMixin} from "../../shared/pwa-page";
import {AdminListPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import {html} from "lit";
import {RoleDomain} from "../../domain/role-domain";
import { links } from "./index";

export class PageRoleList extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminListPage) {
  canEdit = true;
  canDestroy = true;
  canAdd = true;
  searchable = true;
  title = 'Role List';
  topLinks = links

  editUrl(obj) {
    return `/tenants/roles/${obj.id}`;
  }
  newUrl() {
    return `/tenants/roles/new`;
  }
  destroyUrl(obj) {
    return `/tenants/roles/${obj.id}/destroy`;
  }

  constructor() {
    super(new RoleDomain());
  }

  get columns() {
    return [
      {name: 'Name', field: 'name', sortable: true, filterable: false, searchable: true},
      {name: 'Slug', field: 'slug', sortable: true, filterable: false, searchable: false},
      {name: 'Description', field: 'description', sortable: true, filterable: false, searchable: false},
      {name: 'Internal', field: 'internal', render: (row) => html`
          ${row.internal ? html`<badge-tag class="simple red">Yes</badge-tag>` : html`No`}
        `, classList: {small: true}},
      {name: 'Badge', field: 'badge', render: (row) => html`
          ${row.badge ? html`<badge-tag class="simple green">Yes</badge-tag>` : html`No`}
      `, classList: {small: true}},
    ];
  }
}