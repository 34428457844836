import { html } from "lit";
import { DashboardBlock } from "./base";
import { StatisticsDomain } from "../../../domain/statistics-domain";
import { Task } from "@qogni-technologies/pwa-utils-library/utils/task.js";
import { ref } from "lit/directives/ref.js";
import ApexCharts from "apexcharts";

customElements.define(
  "stat-disc",
  class DiscStat extends DashboardBlock {
    #statDomain;

    /**
     * @type {boolean}
     */
    loading;

    /**
     * @type {{id: string, color: string, type_a_minus_b: number, type_a: number, name: string}[] | undefined}
     */
    data;

    /**
     * @type {{organisationId: string}}
     */
    filter;

    /**
     * @type {{[key: string]: ApexCharts}}
     */
    graphs = {};

    constructor() {
      super();
      this.#statDomain = new StatisticsDomain();
    }

    getDemoData() {
      return [
        {
          id: "0b100a5f-c801-467c-b7a3-07025ed3e8f5",
          name: "Drive & Reward",
          color: "red",
          type_a_minus_b: 70.54,
          type_a: 80.00,
          has_data: true,
        },
        {
          id: "006ab7aa-e17b-4eee-bd3a-b38afdd2c71e",
          name: "Connection & Memory",
          color: "yellow",
          type_a_minus_b: 63.45,
          type_a: 75.00,
          has_data: true,
        },
        {
          id: "a69426df-fdf8-49bf-8848-f426e6e8a1c6",
          name: "Balance & Empathy",
          color: "green",
          type_a_minus_b: 72.45,
          type_a: 75.00,
          has_data: true,
        },
        {
          id: "d81de65d-1a9c-4033-b470-167942c745f9",
          name: "Structure & Mood",
          color: "blue",
          type_a_minus_b: 43.12,
          type_a: 80.00,
          has_data: true,
        },
      ];
    }

    async fetch(refresh = false) {
      await super.fetch(refresh);
      if (!this.filter.organisationId) {
        this.data = this.getDemoData();
        this.requestUpdate();
        return;
      }
      if (this.data !== undefined && this.data.length > 0 && !refresh) return;
      return Task.run(
        async () => {
          this.data = await this.#statDomain.discModel(
            this.filter.organisationId
          );
          this.requestUpdate();
        },
        {
          global: false,
          ghost: this,
        }
      );
    }

    #colorMap = {
      red: "#E64272",
      yellow: "#f2a84c",
      green: "#249767",
      blue: "#299ec9",
    };

    #orderMap = {
      red: false,
      yellow: false,
      green: true,
      blue: true,
    };

    /**
     *
     * @param {string} color
     * @param {{id: string, color: string, type_a_minus_b: number, type_a: number, name: string}} data
     * @returns
     */
    async getOptions(color, data) {
      return {
        colors: [this.#colorMap[color]],
        series: [
          {
            data: [data.type_a, data.type_a_minus_b],
            name: data.name,
          },
        ],
        chart: {
          type: "bar",
          height: 150,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            borderRadiusApplication: "end",
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: ["Potential", "Current"],
          max: 100
        },
        yaxis: {
          reversed: this.#orderMap[color],
          axisTicks: {
            show: true,
          },
        },
      };
    }

    /**
     * @param {HTMLElement} el
     * @param {string} color
     * @returns {HTMLElement}
     */
    async initGraph(el, color) {
      if (
        !this.graphs[color] &&
        this.data !== undefined &&
        this.data.length > 0
      ) {
        const data = this.data?.find((d) => d.color === color);
        this.graphs[color] = new ApexCharts(
          el,
          await this.getOptions(color, data)
        );
        this.graphs[color].render();
      }
    }

    /**
     * @param {string} color
     * @returns {HTMLElement}
     */
    chart(color) {
      const registerGraph = (el) => {
        this.initGraph(el, color);
      };

      if (this.loading) {
        return html`<app-shimmer class="image"></app-shimmer>`;
      }

      const data = this.data?.find((d) => d.color === color);

      if (data === undefined || data.has_data === false) {
        return html`
          <h4>${data?.name}</h4>
          <i>No data available.</i>
        `;
      }

      return html`
        <h4>${data?.name}</h4>
        <div ${ref(registerGraph)}></div>
      `;
    }

    render() {
      return html`
        ${this.data === undefined || this.data.length > 0
          ? html`
              <dashboard-container>
                <section class="col-6">${this.chart("blue")}</section>
                <section class="col-6">${this.chart("red")}</section>
                <section class="col-6">${this.chart("green")}</section>
                <section class="col-6">${this.chart("yellow")}</section>
              </dashboard-container>
            `
          : html`<i>No data available.</i>`}
      `;
    }
  }
);
