import { RoleMixin } from "../../shared/pwa-page";
import { AdminViewPage, renderBreadcrumbs } from "../../shared/admin";
import { Session } from "../../shared/session";
import { links } from "./index";
import { GenericDomain } from "../../domain/generic-domain";
import { html, nothing } from "lit";
import { Task } from "@qogni-technologies/pwa-utils-library/utils/task.js";

import "./organisation-location-list";
import "./organisation-location-edit";
import "./organisation-user-list";
import "./organisation-user-invite";

export class PageOrganisationLocationView extends RoleMixin([Session.ROLE_QOGNI_ADMIN, Session.ROLE_HR], AdminViewPage) {
  title = 'Organisation Location Details';
  topLinks = links

  #departmentsDomain;
  #organisationDomain;

  static get properties() {
    return {
      ...super.properties,
      locationId: { type: String, attribute: 'locationid', routeOrigin: "pathname" },
      organisationId: { type: String, attribute: 'organisationid', routeOrigin: "pathname" },
      organisation: { type: Object },

      users: { type: Array },
      usersPagination: { type: Object },
      usersShow: { type: Boolean },

      departments: { type: Array },
      departmentsPagination: { type: Object },
      departmentsShow: { type: Boolean },
    };
  }

  constructor() {
    super(new GenericDomain('/organisations'));
    this.#organisationDomain = new GenericDomain('/organisations');
    this.departmentsShow = true;
  }

  get breadcrumbs() {
    return [{
      name: 'Home',
      link: '/',
    }, {
      name: 'Organisations',
      link: `/tenants/organisations`,
    }, {
      name: this.organisation?.name ?? 'Organisation Details',
      link: `/tenants/organisations/${this.organisationId}/view`,
    }, {
      name: this.object?.name ?? 'Department Details',
      link: `/tenants/organisations/${this.organisationId}/locations/${this.id}/view`,
      active: true,
    }];
  }

  async connectedCallback() {
    this.id = this.locationId;
    this.#departmentsDomain = new GenericDomain(`/organisations/${this.organisationId}/locations/${this.id}/departments`);
    this.domain.setBaseUrl(`/organisations/${this.organisationId}/locations`);
    super.connectedCallback();
  }

  async fetch(options = {}) {
    const retrieveOrganisationDetails = Task.run(async () => {
      try {
        const response = await this.#organisationDomain.show(this.organisationId);
        this.organisation = response.data;
      } catch (e) {
        console.error(e);
        app.addToastMessage('Can not retrieve organisation details.');
      }
    }, {
      ghost: this
    });
    const retrieveDepartmentList = Task.run(async () => {
      try {
        const response = await this.#departmentsDomain.list();
        this.departments = response.data;
        this.departmentsPagination = response.pagination;
      } catch (e) {
        console.error(e);
        app.addToastMessage('Can not retrieve department list.');
      }
    }, {
      ghost: this
    });

    return Promise.all([
      super.fetch(options),
      retrieveOrganisationDetails,
      retrieveDepartmentList,
    ]);
  }

  toggleDetails(e) {
    if (e.target.dataset?.source === 'departments') {
      this.departmentsShow = e.target.open;
    } else if (e.target.dataset?.source === 'users') {
      this.usersShow = e.target.open;
    }
  }

  renderLocationDetails() {
    return html`
      <section class="card">
        <details class="simple nested" @toggle=${this.toggleDetails.bind(this)} data-source="details">
          <summary>
            <h3>Location details</h3>
            <svg-icon icon="caret"></svg-icon>
          </summary>

          <div class="data-table">
          <table>
            <tbody>
            <tr>
              <td class="bold">Name</td>
              <td>${this.object.name}</td>
            </tr>
            <tr>
              <td class="bold">Address</td>
              <td>
                ${this.object.address_line_1}<br>
                ${this.object.address_line_2 ? html`${this.object.address_line_2}<br>` : nothing}
                ${this.object.postal_code}, ${this.object.city}<br>
                ${this.object.state ? html`${this.object.state}<br>` : nothing}
                ${this.object.country}
              </td>
            </tr>
            </tbody>
          </table>
          <a href="/tenants/organisations/${this.organisationId}/locations/${this.id}" class="button tiny wide">
            Edit details
          </a>
        </div>
        </details>
      </section>
    `;
  }

  renderDepartments() {
    return html`
      <section class="card">
        <details class="simple nested" open @toggle=${this.toggleDetails.bind(this)} data-source="locations">
          <summary>
            <h3>Departments</h3>
            <svg-icon icon="caret"></svg-icon>
          </summary>

          <div class="data-table">
            ${this.departmentsShow ? html`
              <page-organisation-location-department-list organisationid="${this.organisationId}" locationid="${this.id}" page-size="10"></page-organisation-location-department-list>
            ` : nothing}
            <a href="/tenants/organisations/${this.organisationId}/locations/${this.object.id}/departments/new" class="button tiny wide">
              Create department
            </a>
          </div>
        </details>
      </section>
    `;
  }

  renderUsers() {
    return html`
      <section class="card">
        <details class="simple nested" @toggle=${this.toggleDetails.bind(this)} data-source="users">
          <summary>
            <h3>All Users</h3>
            <svg-icon icon="caret"></svg-icon>
          </summary>

          <div class="data-table">
            ${this.usersShow ? html`
              <page-organisation-user-list organisationid="${this.organisationId}" locationid="${this.object.id}" page-size="10"></page-organisation-user-list>
            ` : nothing}
            <flex-container breakpoint="tiny" class="mb-none">
              <flex-item class="col-6">
                <a href="/tenants/organisations/${this.organisationId}/users/import?location_id=${this.object.id}" class="button tiny wide">
                  Import users
                </a>
              </flex-item>
              <flex-item class="col-6">
                <a href="/tenants/organisations/${this.organisationId}/users/invite?location_id=${this.object.id}" class="button tiny wide">
                  Invite user(s)
                </a>
              </flex-item>
            </flex-container>
          </div>
        </details>
      </section>
    `;
  }

  renderDetail(obj) {
    return html`
      ${renderBreadcrumbs(this.breadcrumbs)}

      ${this.renderLocationDetails()}

      ${this.renderDepartments()}

      ${this.renderUsers()}
    `;
  }
}
