import { html } from 'lit';
import { RoleMixin } from '../../shared/pwa-page';
import { Session } from '../../shared/session';
import { AdminListPage, dateTimeRenderer } from '../../shared/admin';
import { GenericDomain } from '../../domain/generic-domain';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

export class PageOrganisationLedgerTransactionsList extends RoleMixin([Session.ROLE_QOGNI_ADMIN], AdminListPage) {
  searchable = false;
  canEdit = false;
  canDestroy = false;
  canAdd = false;
  canView = false;
  title = 'Organisation Ledger Accounts Transactions';

  static get properties() {
    return {
      ...super.properties,
      organisationId: { type: String, attribute: 'organisationid', routeOrigin: "pathname" },
      ledgerAccountId: { type: String, attribute: 'ledgeraccountid', routeOrigin: "pathname"},
      ledgerAccount: { type: Object }
    };
  }

  constructor() {
    super(new GenericDomain());
  }

  async connectedCallback() {
    this.domain.setBaseUrl(`/organisations/${this.organisationId}/ledger_accounts/${this.ledgerAccountId}/transactions`);
    await super.connectedCallback();
    await this.getLedgerAccountDetails();
  }

  async getLedgerAccountDetails() {
    const res = await this.domain.api.getData(`/organisations/${this.organisationId}/ledger_accounts/${this.ledgerAccountId}`);
    this.ledgerAccount = res?.data;
  }

  get columns() {
    return [
      {name: 'Description', field: 'description'},
      {name: 'Amount', render: (obj) => html`${unsafeHTML(this.ledgerAccount?.currency?.symbol)} ${Number(obj.amount).toFixed(2)}`},
      {name: 'Date', field: 'created_at', sortable: true, render: dateTimeRenderer},
    ];
  }

}
