import { html, nothing } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { classMap } from 'lit/directives/class-map.js';
import { AuthenticatedMixin, PWAPage } from "./pwa-page";
import { until } from "lit/directives/until.js";
import { AutoComplete } from "@qogni-technologies/design-system/components/base/form/auto-complete.js";
import { createRef, ref } from "lit/directives/ref.js";
import { TagAutocomplete } from "./autocompletes";
import { EventTargetMixin } from "@qogni-technologies/design-system/shared/common.js";
import { Task } from "@qogni-technologies/pwa-utils-library/utils/task.js";
import { resizeImage } from "@qogni-technologies/pwa-utils-library/utils/resize-image.js";
import { config } from "../qogni-app-config";

export class AdminPage extends AuthenticatedMixin(PWAPage) {
  #domain = null;
  topLinks = undefined;

  /**
   * Domain Class Instance
   * @returns {AdminDomainHandler|any|null}
   */
  get domain() {
    return this.#domain;
  }

  constructor(domain) {
    super();
    this.#domain = domain;
  }
}

/**
 * Represents a page for managing admin lists.
 * @extends {AdminPage}
 * @extends {AuthenticatedMixin}
 * @extends {PWAPage}
 */
export class AdminListPage extends AdminPage {
  title = 'Entities';
  canView = false;
  canAdd = true;
  canEdit = true;
  canDestroy = true;

  viewLinkText = 'View';
  viewLinkIcon = 'info2';
  viewLinkWidth = 78;

  searchable = true;
  filterable = false;

  #promise;

  static get properties() {
    return {
      cache: {type: [], attribute: false},
      pagination: {type: {}, attribute: false},
      currentPage: {type: Number, attribute: false},
      pageSize: {type: Number, attribute: 'page-size'},
      search: {type: String, attribute: false},
      sortColumn: {type: {}, attribute: false, hasChanged: () => {this.cache = null}},
      sortDirection: {type: String, attribute: false, hasChanged: () => {this.cache = null}},
    };
  }

  constructor(domain) {
    super(domain);
    this.cache = null;
    this.currentPage = 1;
    this.sortColumn = null;
    this.sortDirection = 'asc';
    this.search = null;
    this.currentFilters = [];
  }

  get fetchOptions() {
    return {};
  }

  get columns() {
    throw new Error('Not implemented');
  }

  get filters() {
    return this.columns.filter((c) => c.filterable && typeof c.filterable?.choices === 'function').map((c) => {return {column: c, ...c.filterable}});
  }

  get lastColumn() {
    if (! this.canEdit && ! this.canDestroy && ! this.canView) return null;
    let width = 0;
    if (this.canView) width += this.viewLinkWidth;
    if (this.canEdit) width += 79;
    if (this.canDestroy) width += 80;
    width = `${width}px`;
    return {name: '', sortable: false, filterable: false, searchable: false, width, render: (r) => {
      return html`
        ${this.canView ? html`<a href="${this.viewUrl(r)}" class="button simple small green"><svg-icon icon="${this.viewLinkIcon}"></svg-icon> ${this.viewLinkText}</a>&nbsp;` : nothing}
        ${this.canEdit && this.editUrl(r) ? html`<a href="${this.editUrl(r)}" class="button simple small"><svg-icon icon="pencil"></svg-icon> Edit</a>&nbsp;` : nothing}
        ${this.canDestroy && ! r.deleted_at ? html`<a href="${this.destroyUrl(r)}" class="button simple small red"><svg-icon icon="trash"></svg-icon> Delete</a>` : nothing}
      `;
    }};
  }

  sort(e, col) {
    if (this.sortColumn && this.sortColumn.name === col.name) {
      if (this.sortDirection === 'asc')
        this.sortDirection = 'desc';
      else
        this.sortDirection = 'asc';
      this.fetch();
      return;
    }
    this.sortDirection = 'asc';
    this.sortColumn = col;
    this.fetch();
  }

  viewUrl(obj) {
    throw new Error('Not implemented');
  }
  editUrl(obj) {
    throw new Error('Not implemented');
  }
  newUrl() {
    throw new Error('Not implemented');
  }
  destroyUrl(obj) {
    throw new Error('Not implemented');
  }

  update(values) {
    super.update(values);
    if (! this.cache
      || (this.pagination && this.pagination.current_page !== this.currentPage)) {
      this.#promise = this.fetch();
    }
  }

  async searchFilterChange(e) {
    if (this.searchable && ! e.detail.target.hasOwnProperty('search')) return;
    this.search = e.detail.target.search;
    this.fetch();
  }

  async fetch() {
    const options = {
      page: this.currentPage,
      ...this.fetchOptions,
    };
    if (this.pageSize) options['per_page'] = this.pageSize;
    if (this.sortColumn) {
      options['direction'] = this.sortDirection;
      options['sort'] = this.sortColumn.field || this.sortColumn.name;
    }
    if (this.search) options['filter'] = this.search;

    if (this.currentFilters.length > 0) {
      for (const filter of this.currentFilters) {
        options[filter.property] = filter.value;
      }
    }

    // Loop over options and remove undefined values.
    for (const key of Object.keys(options)) {
      if (options[key] === undefined) delete options[key];
    }

    return Task.run(async () => {
      const result = await this.domain.list(options);
      this.cache = result.data;
      this.pagination = result.pagination;
      this.currentPage = this.pagination?.current_page ?? 1;
    }, {
      ghost: document.documentElement,
      description: 'Fetching list of items...'
    });
  }

  render() {
    return html`
      ${this.topLinks ? html`
        ${AdminIndexPage.renderBreadcrumbs(this.topLinks)}
      ` : nothing}

      ${this.title !== null ? html`
        <section class="card">
          <flex-container>
            <flex-item class="col-8">
              <h1>Manage ${this.title}</h1>
            </flex-item>
            <flex-item class="col-4" style="text-align: end">
              ${this.canAdd ? html`
              <a href="${this.newUrl()}" class="button">Add</a>
            ` : nothing}
            </flex-item>
          </flex-container>
        </section>
      ` : nothing}

      ${this.searchable || this.filterable ? html`
      <section class="card search-filter ${this.searchable ? `search` : ``} ${this.filterable ? `filter` : ``}">
        <x-form live debug @statechange=${this.searchFilterChange}>
          <form>
            ${this.renderSearch()}
            ${this.renderFilters()}
          </form>
        </x-form>
      </section>
      ` : nothing}

      ${this.canAdd && this.title === null ? html`
        <section class="card">
          <a href="${this.newUrl()}" class="button tiny">Add</a>
        </section>
      ` : nothing}

      <section class="card">
        ${this.renderTable()}
      </section>

      <section class="card">
        ${this.renderPagination()}
      </section>
    `;
  }

  renderSearch() {
    return html`
      <input type="search" data-label="Search" name="search" placeholder="Type to search in the list" />
    `;
  }

  renderFilters() {
    return html`
      ${repeat(this.filters, (filter) => {
        const promise = async () => {
          if (typeof filter.choices === 'function') return await filter.choices();
          return filter.choices;
        };
        const change = (e) => {
          if (typeof filter.change === 'function') return filter.change(e);
          if (e.target.value && this.currentFilters.filter((f) => f.property === filter.property).length === 0) {
            this.currentFilters.push({
              ...filter,
              value: e.target.value,
            });
          } else if (this.currentFilters?.filter((f) => f.property === filter.property)?.length > 0) {
            this.currentFilters = this.currentFilters.filter((f) => f.property !== filter.property);
          }

          this.fetch();
        }
        return html`
          <label>
            <span data-label="">${filter.label}</span>
            ${until(promise().then((choices) => html`
              <select name="${filter.property}"
                      @change=${change}>
                <option value="">-- none --</option>
                ${repeat(choices, (c) => {
                  const currentFilter = this.currentFilters.find((f) => f.property === filter.property);
                  return html`
                    <option value="${c.value ? c.value : c}"
                            ?selected=${currentFilter && (currentFilter?.value === c || currentFilter?.value === c.value)}>
                      ${c.name ? c.name : c}
                    </option>
                  `;
                })}
              </select>
            `), html`
              <select disabled>
                <option>Loading options...</option>
              </select>
            `)}
          </label>
        `;
      })}
    `;
  }

  renderPagination() {
    if (! this.cache && ! this.pagination) return nothing;
    if (! this.pagination) return nothing;

    return html`
      <div class="pagination-controls">
        <button class="small" ?disabled=${this.currentPage === 1} @click=${() => this.currentPage--}>Previous</button>
        <span>Page ${this.currentPage} of ${this.pagination.last_page}</span>
        <button class="small ${this.currentPage === this.pagination.last_page ? 'hide' : ''}" ?disabled=${this.currentPage >= this.pagination.last_page}
                @click=${() => this.currentPage++}>Next</button>
      </div>
    `;
  }

  renderTable() {
    if (! this.cache) return html`No data found`;

    return html`
      <div class="data-table">
        <table>
          <thead>
          <tr>
          ${repeat(this.columns.concat([this.lastColumn]), (col) => {
            if (! col) return nothing;

            let classList = "";
            let styles = "";
            let colPostfix = nothing;
            if (col.sortable) {
              classList += "sortable ";
              colPostfix = html`<svg-icon icon="caret" size="12px"></svg-icon>`;
            }
            if (col.filterable) classList += "filterable ";
            if (col.searchable) classList += "searchable ";
            if (col.name === this.sortColumn) classList += 'sorted';
            if (col.width) styles += ";width: " + col.width;

            return html`
            <th scope="col" data-field="${col.field || col.name}"
                class="${classList}"
                style="${styles}"
                @click=${(e) => this.sort(e, col)}
            >
              <div class="wrap">
                <span>${col.name}</span>
                ${colPostfix}
              </div>
            </th>
          `
          })}
          </tr>
          </thead>
          <tbody>
          ${repeat(this.cache, (row) => {
            return html`
              <tr>
                ${repeat(this.columns.concat([this.lastColumn]), (col) => {
                  if (! col) return nothing;
                  let link = null;
                  try {
                    if (col.link === true && this.canView) link = this.viewUrl(row);
                    if (col.link === true && ! link && this.canEdit) link = this.editUrl(row);
                  } catch {
                    // ignore
                  }
                  // Always override if col.link is a function.
                  if (typeof col.link === 'function') link = col.link(row);

                  console.log(col.width);
                  return html`
                  <td style="${col.width ? `width: ${col.width}` : ''}" class=${col.classList ? classMap(col.classList) : classMap([])}>
                    ${col.render && typeof col.render === 'function' ? col.render(row, row[col.field||col.name]) : html`
                      ${link ? html`<a href="${link}">${row[col.field||col.name]}</a>` : html`${row[col.field||col.name]}`}
                    `}
                  </td>
                `})
                }
              </tr>
            `;
          })}
          </tbody>
        </table>
      </div>
    `;
  }
}

/**
 * Renders a string field input based on the provided configuration options.
 * The type of rendered input varies depending on the field properties such as `html`, `markdown`, `autoComplete`, and `expanded`.
 *
 * @param {Object} field - Configuration options for the field. May include the following properties:
 * - `html`: Whether the input should use a rich-text editor.
 * - `markdown`: Whether the input supports markdown.
 * - `autoComplete`: Whether the input should support autocomplete suggestions.
 * - `expanded`: Whether the input should render as a textarea with expanded rows.
 * - `label`: The label text to be displayed for the input.
 * - `property`: The name of the input field.
 * - `required`: A boolean indicating if the input is required.
 * - `readonly`: A boolean indicating if the input is read-only.
 * - `disabled`: A boolean indicating if the input is disabled.
 * - `placeholder`: Placeholder text for the input.
 * - `change`: Event listener for when the input value changes.
 * - `help`: Optional help text to display with the input.
 * - `ref`: Reference object used for certain input types (e.g., autocomplete).
 * @param {string} value - The current value of the input field to be rendered.
 */
function renderStringField(field, value) {
  if (field.autoComplete) {
    if (!field.ref) field.ref = createRef();
    return html`
      <label>
        <span data-label>${field.label}</span>
        <input
          type="search"
          ?required=${field.required}
          ?readonly=${field.readonly}
          placeholder="${field.placeholder}"
          name="${field.property}"
          value="${value}"
          ${ref(field.ref)}
          @focus=${(e) => AutoComplete.connect(e, field.autoComplete)}
          @input=${(e) => e.stopPropagation()}
          @result-selected=${(e) => this.dispatchEvent(new CustomEvent('autocomplete-selected', {detail: e.detail}))}/>
      </label>
    `;
  }
  if (field.expanded)
    return html`
      <label>
        <span data-label>${field.label}</span>
        <textarea name="${field.property}"
                  ?required=${field.required}
                  ?disabled=${field.disabled}
                  ?readonly=${field.readonly}
                  @change=${field.change}
                  rows="10">${value}</textarea>
      </label>
    `;
  return html`
    <input data-label="${field.label}"
           type="text"
           name="${field.property}"
           placeholder="${field.placeholder ?? field.label}"
           ?required=${field.required}
           ?disabled=${field.disabled}
           ?readonly=${field.readonly}
           @change=${field.change}
           value=${value}/>
    ${field.help ? html`
      <p class="small help-text">${field.help}</p>
    ` : nothing}
  `;
}

/**
 * Represents a page for managing admin edit page.
 * @extends {AdminPage}
 * @extends {AuthenticatedMixin}
 * @extends {PWAPage}
 */
export class AdminEditPage extends EventTargetMixin(AdminPage) {
  saveButtonLabel;

  redirect = true;

  #promise;
  #formData = {};

  static get properties() {
    return {
      id: {type: String, attribute: true, routeOrigin: "pathname"},
      object: {type: Object, attribute: false},
      createNew: {type: Boolean, attribute: false},
      error: {type: String, attribute: false},
    };
  }

  constructor(domain) {
    super(domain);
    this.object = undefined;
    this.createNew = false;
    this.error = null;
    this.stateData = {};
  }

  get fields() {
    throw new Error('Not implemented');
  }

  get name() {
    if (this.object && this.object.hasOwnProperty('name')) {
      return this.object.name;
    }
    return null;
  }

  async connectedCallback() {
    await super.connectedCallback();
    this.createNew = this.id === 'new';
    await this.fetch();
  }

  async fetch(options = {}) {
    if (this.createNew) return;
    try {
      if (!this.id) return;
      const res = await this.domain.show(this.id, options);
      this.object = res.data;
    } catch (err) {
      if (err.response && err.response.status === 404) {
        this.error = 'Entity not found';
      }
    }
  }

  async action(e) {
    if (e.detail.name === '--submit') return this.save(e);

    // Can be implemented in the child class if wanted.
  }

  async preprocess(data) {
    // Preprocess checkboxes and radio inputs when looping over this.fields.
    for (const field of this.fields) {
      if (field instanceof CustomField) {
        data[field.property] = field.value;
      }
      if (field.type === 'Checkbox') {
        data[field.property] = data.hasOwnProperty(field.property) && data[field.property] === 'on';
      }
      if (field.type === 'Image' && data.hasOwnProperty(field.property)) {
        const imageFile = data[field.property].unproxy;

        data[field.property] = (await resizeImage(imageFile, {
          maxHeight: 5000,
          maxWidth: 5000,
          quality: 0.8,
          contentType: 'image/jpeg',
          ...field.resizeOptions ?? {},
        })).url;
      }
    }

    return {...data};
  }

  async save(e) {
    if (e) e.preventDefault();
    try {
      const task = async () => {
        let data = {...e.detail.value, ...this.#formData};
        data = await this.preprocess(data);
        if (this.createNew) {
          await this.domain.create(data, {query: {delete: false}});
        } else {
          await this.domain.update(this.id, data, {query: {delete: false}});
        }
      }

      await Task.run(task, {
        ghost: e?.target,
        description: "Update entity"
      });
    } catch (err) {
      if (err.response && err.response.status === 400 && err.errorData && err.errorData.errors) {
        this.error = '';
        Object.entries(err.errorData.errors).forEach(([key, value]) => {
          this.error += ` ${key}: ${value.join(', ')}`;
        });
      }
      console.error(err);
      app.addToastMessage('Error: ' + (err?.errorData?.message ?? 'saving form failed.'));
      return;
    }

    if (this.redirect) {
      if (typeof this.redirect === 'function')
        return this.redirect();
      if (typeof this.redirect === 'string')
        window.location.replace(this.redirect);
      if (this.redirect === true)
        window.history.back();
    }
    app.addToastMessage('Entity has been updated!', {type: 'success'});
  }

  render() {
    if (this.object === undefined && ! this.createNew) return html`
      <app-shimmer class="title"></app-shimmer>
      <app-shimmer></app-shimmer>
      <app-shimmer></app-shimmer>

      <app-shimmer class="title"></app-shimmer>
      <app-shimmer></app-shimmer>
      <app-shimmer></app-shimmer>
    `;
    if (! this.object && ! this.createNew) return nothing;

    return html`
      <section class="card">
        <flex-container breakpoint="tiny">
          <flex-item class="flex">
            <button class="small round" @click=${() => history.back()}>
              <svg-icon icon="arrow" rotation="180"></svg-icon>
            </button>
          </flex-item>
          <flex-item class="title-wrapper flex grow-1">
            ${typeof this.title === 'function' ? this.title() : html`
              ${this.createNew ? html`
                <h1>Create new ${this.title ?? "Entity"}</h1>
              ` : html`
                <h1>Manage '${this.name || this.title || "Entity"}'</h1>
              `}
            `}
          </flex-item>
        </flex-container>
      </section>

      ${this.before ? (typeof this.before === 'function' ? this.before() : this.before) : nothing}

      ${this.error ? html`
        <section class="card red">
          ${this.error}
        </section>
      ` : nothing}

      ${this.renderFields()}

      ${this.after ? (typeof this.after === 'function' ? this.after() : this.after) : nothing}
    `;
  }

  formChange(e) {
    this.stateData = e.detail?.target || {};
  }

  renderFields() {
    return html`
      <section class="card">
        <x-form unsanitized @action=${this.action} live @statechange="${this.formChange}">
          <form>
            ${repeat(this.fields, (field) => {
              let value;
              // Custom getter.
              if (field.getter && typeof field.getter === 'function') {
                value = field.getter(field, this.object);
              } else {
                value = this.object && this.object.hasOwnProperty(field.property) ? this.object[field.property] : undefined;
              }
              if (! value && this.stateData && this.stateData.hasOwnProperty(field.property)) {
                value = this.stateData[field.property];
              }

              let before = nothing;
              let after = nothing;
              if (field.before && typeof field.before === 'function') {
                before = field.before.apply(this, [field, value]);
              }
              if (field.after && typeof field.after === 'function') {
                after = field.after.apply(this, [field, value]);
              }

              return html`
                ${before}
                ${this.renderSingleField(field, value)}
                ${after}
              `
            })}
            <button type="submit">${this.saveButtonLabel || 'Save changes'}</button>
          </form>
        </x-form>
      </section>
    `;
  }

  renderSingleField(field, value) {
    if (! field) return nothing;
    if (value === undefined && field.default) value = field.default;
    if (! field.ref) field.ref = createRef();

    // Custom render function.
    if (field.render && typeof field.render === 'function') {
      return field.render.apply(this, [field, value]);
    }

    // Build-in rendering.
    switch (field.type) {
      case 'Select':
        const promise = async () => {
          if (typeof field.choices === 'function') return await field.choices();
          return field.choices;
        };

        return html`
          <label>
            <span data-label="">${field.label}</span>
            ${field.multiple ? html`
              ${typeof field.choices === 'function' ? html`
                ${until(promise().then((choices) => html`
                  <div class="flex expanded">
                    ${repeat(choices, (c) => {
                      let isChosen = false;
                      if (value && Array.isArray(value)) {
                        for (const v of value) {
                          if (v.id === c.value) isChosen = true;
                        }
                      }
                      return html`
                        <label>
                          <input type="checkbox" class="variant1"
                                 ?checked=${isChosen}
                                 name="${field.property}[]" value="${c.value ? c.value : c}" />
                          <span></span> ${c.name ? c.name : c}
                        </label>
                      `
                    })}
                  </div>
                `), html`
                  <i>Loading options...</i>
                `)}
              ` : html`
                <div class="flex expanded">
                  ${repeat(field.choices, (c) => {
                    let isChosen = false;
                    if (value && Array.isArray(value)) {
                      for (const v of value) {
                        if (v.id === c.value) isChosen = true;
                      }
                    }
                    return html`
                      <label>
                        <input type="checkbox" class="variant1"
                               ?checked=${isChosen}
                               name="${field.property}[]" value="${c.value ? c.value : c}" />
                        <span></span> ${c.name ? c.name : c}
                      </label>
                    `;
                  })}
                </div>
              `}
            ` : html`
              ${typeof field.choices === 'function' ? html`
                ${until(promise().then((choices) => html`
                  <select name="${field.property}"
                          ?required=${field.required}
                          ${ref(field.ref)}
                          @change=${field.change}>
                    ${! field.required ? html`
                      <option value="">${field.defaultChoiceName ?? '-- none --'}</option>
                    ` : nothing}
                    ${repeat(choices, (c) => html`
                    <option value="${c.value ? c.value : c}" ?selected=${value === c || value === c.value}>
                      ${c.name ? c.name : c}
                    </option>
                  `)}
                  </select>
                `), html`
                  <select disabled>
                    <option>Loading options...</option>
                  </select>
                `)}
              ` : html`
                <select name="${field.property}"
                        ?required=${field.required}
                        @change=${field.change}
                        ${ref(field.ref)}>
                  ${! field.required ? html`
                    <option value="">${field.defaultChoiceName ?? '-- none --'}</option>
                  ` : nothing}
                  ${repeat(field.choices, (c) => html`
                  <option value="${c.value ? c.value : c}" ?selected=${value === c || value === c.value || c === field.value}>
                    ${c.name ? c.name : c}
                  </option>
                `)}
                </select>
              `}
            `}
          </label>
          `;
      case String:
        if (field.html || field.markdown)
          return html`
            <label>
              <span data-label>${field.label}</span>
              <rich-editor name="${field.property}"
                           ?required=${field.required}
                           ?readonly=${field.readonly}
                           @change=${this.#richEditorChange(field.change).bind(this)}
                           value=${value}
                           fixed-toolbar></rich-editor>
            </label>
          `;
        return renderStringField(field, value);
      case Number:
        return html`
          <input data-label="${field.label}"
                 type="number"
                 name="${field.property}"
                 placeholder="${field.placeholder ?? field.label}"
                 ?required=${field.required}
                 ?disabled=${field.disabled}
                 ?readonly=${field.readonly}
                 step="${field.step ?? '0.01'}"
                 min="${field.min}"
                 max="${field.max}"
                 @change=${field.change}
                 value="${value}" />`
      case 'File':
      case 'Image':
        return html`
          <files-dropzone
            name="${field.property}"
            ?required=${field.required}
            ?disabled=${field.disabled}
            @change=${field.change}
            ?readonly=${field.readonly}
            ?multiple=${field.multiple}
            accept=${field.accept ?? (field.type === 'Image' ? 'image/*' : '')}
            deleteEvent=${field.delete}
            .value=${field.files ?? (typeof value === 'string' ? [value] : value)}
          ></files-dropzone>
        `;
      case 'Checkbox':
        return html`
          <label>
            <input class="variant1" type="checkbox" value="on"
                   name="${field.property}"
                   ?required=${field.required}
                   ?disabled=${field.disabled}
                   ?checked=${!!value}
                   ${ref(field.ref)}
                   @change=${field.change} />
            <span></span>${field.label}
          </label>
        `;
    }
  }

  #richEditorChange(parent) {
    return (e) => {
      this.#formData[e.target.name] = e.detail.text;
      if (typeof parent === 'function') parent(e);
    }
  }
}

export class AdminWizardEditPage extends AdminEditPage {
  static get properties() {
    return {
      ...super.properties,
      step: { type: Number },
    };
  }

  get steps() {
    throw new Error('Not implemented');
  }

  get before() {
    const value = this.steps[this.step].name;
    return html`
      <section class="card">
        <nav-breadcrumbs value="${value}" .items=${this.steps}></nav-breadcrumbs>
        ${super.before()}
      </section>
    `;
  }
}

/**
 * Represents a page destroying an object.
 * @extends {AdminPage}
 * @extends {AuthenticatedMixin}
 * @extends {PWAPage}
 */
export class AdminDestroyPage extends AdminPage {
  title = 'Entity';

  redirect = true;

  static get properties() {
    return {
      id: {type: String, attribute: true, routeOrigin: "pathname"},
      object: {type: {}, attribute: false},
      isPermanent: {type: Boolean, attribute: true},
    };
  }

  constructor(domain) {
    super(domain);
    this.object = null;
    this.isPermanent = false;
  }

  get name() {
    if (this.object && this.object.hasOwnProperty('name')) {
      return this.object.name;
    }
    return null;
  }

  async connectedCallback() {
    await super.connectedCallback();
    await this.fetch();
  }

  async fetch(options = {}) {
    try {
      const res = await this.domain.show(this.id, options);
      this.object = res.data;
    } catch (err) {
      if (err.response && err.response.status === 404) {
        this.error = 'Entity not found';
      }
    }
  }

  async action(e) {
    if (e.detail.name === '--submit')
      return this.destroy(e);

    // Can be implemented in the child class if wanted.
  }

  async destroy(e) {
    if (e) e.preventDefault();
    try {
      const task = async () => {
        await this.domain.destroy(this.id);
      }

      await Task.run(task, {
        ghost: e?.target,
        description: "Destroy entity"
      });
    } catch (err) {
      this.error = err?.errorData?.message ?? `${err}`;
    }

    if (this.redirect) {
      if (typeof this.redirect === 'function')
        return this.redirect();
      if (typeof this.redirect === 'string')
        window.location.replace(this.redirect);
      if (this.redirect === true)
        window.history.back();
    }
    app.addToastMessage('Entity has been destroyed!', {type: 'success'});
  }

  render() {
    if (! this.object) return html`
      <app-shimmer class="title"></app-shimmer>
      <app-shimmer class=""></app-shimmer>
      <app-shimmer class=""></app-shimmer>
      <app-shimmer class=""></app-shimmer>
    `;
    if (this.error) {
      return html`
        <section class="card red">
          <flex-container breakpoint="tiny">
            <flex-item>
              <button class="small round" @click=${() => history.back()}>
                <svg-icon icon="arrow" rotation="180"></svg-icon>
              </button>
            </flex-item>
            <flex-item>
              ${this.error}
            </flex-item>
          </flex-container>
        </section>
      `;
    }

    return html`
      <section class="card">
        <flex-container breakpoint="tiny">
          <flex-item>
            <button class="small round" @click=${() => history.back()}>
              <svg-icon icon="arrow" rotation="180"></svg-icon>
            </button>
          </flex-item>
          <flex-item>
            <h1>Delete '${this.name || this.title}'</h1>
          </flex-item>
        </flex-container>
      </section>

      ${this.renderDeleteForm()}
    `;
  }

  #resetError(e) {
    e.preventDefault();
    e.stopPropagation();
    this.error = null;
  }

  renderDeleteForm() {
    return html`
      <section class="card">
        <x-form @action=${this.action}>
          <form>
            <h2>Are your sure you would like to destroy this object?</h2>
            ${this.renderObjectData(this.object)}

            <button type="submit">Delete</button>
          </form>
        </x-form>
      </section>
    `;
  }

  renderObjectData(obj) {
    const entries = Object.entries(obj);
    const ignoreList = [
      'id', 'user_id', 'deleted_at'
    ]
    return html`
      <div class="data-table mb-tiny">
        <table>
          <tbody>
            ${repeat(entries, ([key]) => key, ([key, value]) => {
              if (ignoreList.includes(key)) return nothing;
              if (key === 'user' && value) value = `${value.firstname} ${value.lastname}`;
              if (typeof value === 'object') value = JSON.stringify(value);
              return html`
                <tr>
                  <td>${key}</td>
                  <td>${value}</td>
                </tr>
          `
            })}
          </tbody>
        </table>
      </div>
    `;
  }
}

/**
 * Represents a page viewing an object. Must be extending the renderDetail function.
 * @extends {AdminPage}
 * @extends {AuthenticatedMixin}
 * @extends {PWAPage}
 */
export class AdminViewPage extends AdminPage {
  topLinks = undefined;

  static get properties() {
    return {
      id: {type: String, attribute: true, routeOrigin: "pathname"},
      object: {type: {}, attribute: false},
      loading: {type: Boolean, attribute: false}
    };
  }

  constructor(domain) {
    super(domain);
    this.object = null;
    this.loading = true;
  }

  async connectedCallback() {
    await super.connectedCallback();
    await this.fetch();
  }

  async fetch(options = {}) {
    try {
      const res = await this.domain.show(this.id, options);
      this.object = res.data;
      this.loading = false;
    } catch (err) {
      if (err.response && err.response.status === 404) {
        this.error = 'Entity not found';
      }
    }
  }

  render() {
    if (! this.object) return html`
      ${this.topLinks ? html`
        ${AdminIndexPage.renderBreadcrumbs(this.topLinks, this.title)}
      ` : nothing}

      <app-shimmer class="title"></app-shimmer>
      <app-shimmer class=""></app-shimmer>
      <app-shimmer class=""></app-shimmer>
      <app-shimmer class=""></app-shimmer>
    `;

    return html`
      ${this.topLinks ? html`
        ${AdminIndexPage.renderBreadcrumbs(this.topLinks, this.title)}
      ` : nothing}

      ${this.renderDetail(this.object)}
    `;
  }

  /**
   * Renders detailed information of the given object. This method should be
   * overridden in derived classes to provide specific rendering logic.
   *
   * @param {Object} obj - The object containing details to be rendered.
   * @return {TemplateResult<*>}
   * @throws {Error} When the method is not overridden in a subclass.
   */
  renderDetail(obj) {
    throw new Error('Must be overridden: renderDetail(obj);');
  }
}

export class AdminIndexPage extends AdminPage {
  links = [];
  colors = [
    'light-green', 'red', 'yellow', 'light-blue', 'dark-green'
  ]
  subtitle = null;

  constructor(links) {
    super(null);
    this.links = links;
  }

  /**
   *
   * @param links
   * @param options
   * @param {String} options.title
   * @param {String} options.subtitle
   * @param {Function} options.extra
   */
  static renderBreadcrumbs(links, options = {}) {
    let currentTitle = null;
    if (options?.title) currentTitle = options.title;
    let currentSubtitle = null;
    if (options?.subtitle) currentSubtitle = options.subtitle;

    const currentRoute = app.activeRoute;
    let activeLink = links.filter(r => {
      if (r.active && typeof r.active === 'function') return r.active(currentRoute);
      return r.link === currentRoute?.options?.path
    })[0] ?? null;
    if (! currentTitle && activeLink && activeLink.name) currentTitle = activeLink.name;
    if (! currentSubtitle && activeLink && activeLink.subtitle) currentSubtitle = activeLink.subtitle;
    else if (! currentTitle) currentTitle = currentRoute?.options?.path.substring(currentRoute?.options?.path.indexOf('/') + 1);
    const computedLinks = links.filter(l => {
      if (l?.role && !app.session.hasRole(l?.role)) {
        return false;
      }
      return true
    })


    return html`
      <page-header>
        <div class="flex-wrapper">
          <page-logo>
            <a href="/">
              <figure>
                <img
                  src="/assets/img/qogni-logo.svg"
                  alt="Qogni Logo"
                />
              </figure>
            </a>
          </page-logo>

          <div class="info">
            <h1 style="text-transform: capitalize">${currentTitle}</h1>
            <p>${currentSubtitle}</p>
          </div>

          ${options?.extra && typeof options?.extra === 'function' ? html`${options.extra()}` : nothing}

          <nav>
            <ul>
              ${repeat(computedLinks, (l) => {
                const isActive = l.active && typeof l.active === 'function' ? l.active(currentRoute) : l.link === currentRoute?.options?.pathname;
                return html`
                  <li>
                    <a href="${l.link}" class="${isActive ? 'active': ''}"> <!-- active -->
                      ${l.name}
                    </a>
                  </li>
               `;
              })}
            </ul>
          </nav>

          <account-link>
            <a class="not-anonymous" href="#">
              <figure>
                <img
                  src="${app.session?.user?.profile_img_url || "/assets/img/profile-picture.webp"}"
                  alt="User profile picture"
                />

                <svg-icon
                  color="white"
                  style="--icon-size: 11px"
                  icon="bell"
                ></svg-icon>
              </figure>

              <strong>${app.session?.user?.firstname || "Anonymous"}</strong>
            </a>
          </account-link>
        </div>
      </page-header>
    `;
  }

  renderBreadcrumbs(links) {
    return AdminIndexPage.renderBreadcrumbs(links, null, this.subtitle)
  }

  render() {
    const computedLinks = this.links.filter(l => {
      if (l?.role && !app.session.hasRole(l?.role)) {
        return false;
      }
      return true
    })
    return html`
      ${this.renderBreadcrumbs(this.links)}

      ${repeat(computedLinks, (l) => html`
          <link-card class="auto-color">
            <a href="${l.link}" class="card-link">
              <figure>
                <svg-icon
                  size="100%"
                  style="--icon-size: 11px"
                  icon="${l.icon ?? 'document'}"
                ></svg-icon>
              </figure>

              <span>
                ${l.name}
              </span>
            </a>
          </link-card>
      `
      )}
    `;
  }
}

/**
 * Converts a specified date/time field from an object to a localized string format.
 *
 * @returns {function(Object): string} - A function that accepts an object and returns the localized date/time
 * formatted string based on the provided field. If the field does not exist or has a falsy value, it returns '-'.
 */
export const dateTimeRenderer = (obj, value) => {
  if (! value) return '-';
  return Intl.DateTimeFormat(navigator.languages, {
    dateStyle: 'short',
    timeStyle: 'short',
  }).format(new Date(value));
};

/**
 * Converts a specified date field from an object to a localized string format.
 *
 * @returns {function(Object): string} - A function that accepts an object and returns the localized date
 * formatted string based on the provided field. If the field does not exist or has a falsy value, it returns '-'.
 */
export const dateRenderer = (obj, value) => {
  if (! value) return '-';
  return Intl.DateTimeFormat(navigator.languages, {
    dateStyle: 'short',
    timeStyle: undefined,
  }).format(new Date(value));
};

/**
 * Represents a custom field that extends EventTarget.
 * This class serves as a base class and contains methods that must be implemented by subclasses.
 */
export class CustomField extends EventTarget {
  get property() {
    throw new Error('Must be implemented: getter for property');
  }

  get value() {
    throw new Error('Must be overridden: getValue()');
  }

  render() {
    throw new Error('Must be overridden: render()');
  }
}

/**
 * Represents a field for handling tags input in a form. The `TagField` class
 * provides methods to add, remove, and manage tags within the field.
 *
 * The component is useful for cases where the user needs to input multiple items,
 * such as keywords, categories, or other tags.
 *
 * Methods:
 * - `addTag(tag: string)`: Adds a new tag to the field.
 * - `removeTag(tag: string)`: Removes an existing tag from the field.
 * - `getTags(): string[]`: Retrieves the list of current tags.
 * - `clearTags()`: Removes all tags from the field.
 *
 * Events:
 * - `onTagAdded(tag: string)`: Event emitted when a new tag is added.
 * - `onTagRemoved(tag: string)`: Event emitted when an existing tag is removed.
 */
export class TagField extends CustomField {
  #containerRef = createRef();
  #searchRef = createRef();

  #property;

  constructor(options) {
    super();
    this.parent = options.object;
    this.#property = options.property ?? 'tags';
    this.type = 'custom';
    this.tags = this.parent?.tags ?? [];
    this.tagComplete = new TagAutocomplete();
    this.tagComplete.addEventListener('selected', (e) => {
      document.getElementById('tagSearchBox').value = e.detail.tag.name;
    });

    if (options.onChange) this.addEventListener('tags-change', options.onChange);
  }

  get property() {
    return this.#property;
  }
  get value() {
    return this.tags;
  }
  get render() {
    return (field, value) => this.renderView();
  }

  attachTag(e) {
    const tagName = this.#searchRef.value.value.trim();
    if (! tagName) {
      app.addToastMessage('You have to fill in tag name first!', {type: 'error'});
      return;
    }

    this.tags.push({name: tagName});
    this.dispatchEvent(new CustomEvent('tags-change', {
      target: this.#containerRef,
      detail: {
        tags: this.tags
      },
      bubbles: true,
      composed: true,
    }));
    this.#searchRef.value.value = '';
  }

  detachTag(e) {
    e.preventDefault(); e.stopPropagation();
    const tagName = e.target?.closest('tag-item')?.dataset['name'];
    if (! tagName) return;

    this.tags.splice(this.tags.indexOf(tagName), 1);
    this.dispatchEvent(new CustomEvent('tags-change', {
      target: this.#containerRef,
      detail: {
        tags: this.tags
      },
      bubbles: true,
      composed: true,
    }));
  }

  renderTagSection() {
    const tags = this.parent?.tags ?? [];

    return html`
      <tag-list>
        ${tags && tags.length > 0 ? html`
          ${repeat(tags, (t) => {
            return html`
              <tag-item data-id=${t.id ?? ''} data-name=${t.name}>
                <h4>
                  ${t.name}
                  ${t.slug ? html`<small>${t.slug}</small>` : nothing}
                </h4>
                <div class="controls">
                  <a href="#" @click=${this.detachTag.bind(this)}>
                    <svg-icon icon="minus"></svg-icon>
                  </a>
                </div>
              </tag-item>

            `;
          })}
        ` : html`
          <i>There are no tags attached to this entity yet.</i>
        `}
      </tag-list>

      ${this.renderTagAdditionFields()}
    `;
  }

  renderTagAdditionFields() {
    return html`
      <section class="card flex spread vertical-top" ${ref(this.#containerRef)}>
        <div style="width: 100%;">
          <label style="width: 100%; margin-bottom: 0;">
            <span data-label>Tag name</span>
            <input
              type="search"
              placeholder="Search for tags, you can add any tag that is not listed by clicking 'attach'"
              id="tagSearchBox"
              ${ref(this.#searchRef)}
              @focus=${(e) => AutoComplete.connect(e, this.tagComplete.autoComplete)}
              @input=${(e) => e.stopPropagation()} />
          </label>
        </div>
        <button type="button" class="button" @click=${this.attachTag.bind(this)}>Attach Tag</button>
      </section>
    `;
  }

  renderView() {
    return html`
      <details class="card">
        <summary>
          Tags ${this.tags && this.tags.length > 0 ? html`(${this.tags?.length})` : nothing}
          <svg-icon icon="tag"></svg-icon>
        </summary>
        ${this.renderTagSection()}
      </details>
      </section>
    `;
  }
}

/**
 * Represents a field that supports multiple languages, intended for managing translatable text fields.
 * It extends the `CustomField` class, inheriting its properties and methods while adding functionality
 * to handle multiple language inputs and rendering.
 *
 * The `TranslatableField` ensures that the type is a string to support text-based translations
 * and removes the default language from the selectable language options.
 *
 * @class TranslatableField
 * @extends CustomField
 *
 * @param {Object} options - The configuration options for the translatable field.
 * @param {string} options.property - The property name associated with the translatable field.
 * @param {string} options.type - Data type for the field. Must be a string to support translation.
 * @param {Array<string>} [options.languages] - Optional list of languages supported by this field. Defaults to the languages specified in the `config` object.
 *
 * @throws {Error} If the `type` of the field is not a string.
 *
 * @property {string} property - Retrieves the property name associated with this translatable field.
 * @property {any} value - Retrieves the current value for the field, associated with its tags.
 * @property {Function} render - Provides a function to render the translatable field in the view.
 * @property {Function} renderView - Internal method to render the translatable field structure, including a tag section.
 */
export class TranslatableField extends CustomField {
  constructor(options) {
    super();
    this.options = options;
    if (! options.parent) throw new Error('Parent object must be specified. Must be an lit element.');
    this.parent = options.parent;
    this.languages = options.languages ?? Object.keys(config.languages);
    if (this.languages && this.languages.indexOf(config.defaultLanguage) !== -1) {
      // Remove default input language from the languages field.
      this.languages.splice(this.languages.indexOf(config.defaultLanguage), 1);
    }
    if (options.type !== String) throw new Error('Type must be a string to support TranslatableField');
    if (options.ref) throw new Error('Ref is not supported for TranslatableField');
    this.options.ref = createRef();
  }

  #toggleTranslationsView() {
    this.parent.querySelector(`translations-container[name="${this.property}"]`)?.toggleAttribute('hidden');
    this.parent.querySelector(`button.translate-button[name="${this.property}"]`)?.toggleAttribute('data-outline');
  }

  get property() {
    return this.options.property;
  }

  get value() {
    if (this.options.expanded) return this.parent.querySelector('textarea[name="' + this.property + '"]')?.value;
    return this.parent.querySelector(`input[name="${this.property}"]`)?.value;
  }

  get render() {
    return (field, value) => this.renderView(value);
  }

  renderView(value) {
    if (! this.options.ref) this.options.ref = createRef();
    const stringField = renderStringField(this.options, value);

    if (this.parent.id === 'new') return html`
      <translatable-field>
        ${stringField}
        <button type="button" class="translate-button tiny" disabled title="Please first save the entity and edit it in order to add translations.">
          <svg-icon icon="translate"></svg-icon>
        </button>
      </translatable-field>
    `;

    return html`
      <translatable-field>
        ${stringField}
        <button type="button" name="${this.property}" class="translate-button tiny" @click="${this.#toggleTranslationsView.bind(this)}">
          <svg-icon icon="translate"></svg-icon>
        </button>
      </translatable-field>
      <translations-container .property="${this.property}"
                              .languages="${this.languages}"
                              .value="${value}"
                              .type="${this.options.translatableType}"
                              .id="${this.options.translatableId}"
                              .context="${this.options.translatableContext ?? null}"
                              name="${this.property}"
                              hidden>
      </translations-container>
    `;
  }
}

export function renderBreadcrumbs(crumbs) {
  return html`
    <nav class="breadcrumbs" aria-label="Breadcrumb">
      <ol>
        ${repeat(crumbs, (c) => html`
          <li ?aria-current="${c.active}">
            <a href="${c.link}">
              ${c.name}
            </a>
          </li>
        `)}
      </ol>
    </nav>
  `;
}
