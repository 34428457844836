import { RoleMixin } from "../../shared/pwa-page";
import { AdminEditPage, TagField } from "../../shared/admin";
import { Session } from "../../shared/session";
import { GenericDomain } from "../../domain/generic-domain";

export class PageHabitEdit extends RoleMixin(
  Session.ROLE_QOGNI_ADMIN,
  AdminEditPage
) {
  title = "Habit";

  get fields() {
    return [
      {
        label: "Name",
        property: "name",
        required: true,
        type: String,
      },
      {
        label: "Description",
        property: "description",
        required: true,
        type: String,
        expanded: true,
        html: true,
      },
      {
        label: "Days of Activity",
        property: "days_of_activity",
        type: Number,
        placeholder: "Days",
        required: true,
      },
      {
        label: "Habit Category",
        property: "habit_category_id",
        required: true,
        type: "Select",
        choices: async () => {
          const domain = new GenericDomain("/habit_categories");
          const categories = await domain.list({ per_page: 10000 });
          return categories.data.map((c) => {
            return {
              value: c.id,
              name: c.name,
            };
          });
        },
      },
      new TagField({
        object: this.object,
        onChange: (e) => {
          if (!this.object) this.object = { tags: [] };
          this.object.tags = e.detail.tags;
          this.requestUpdate();
        },
      }),
    ];
  }

  constructor() {
    super(new GenericDomain("/habits"));
  }
}
