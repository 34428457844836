import {RoleMixin} from "../../shared/pwa-page";
import {AdminEditPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import {ResultProfileTextDomain} from "../../domain/result-profile-text-domain";

export class PageResultProfileTextEdit extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminEditPage) {
  constructor() {
    super(new ResultProfileTextDomain());
  }

  static get properties() {
    return {
      ...super.properties,
      resultProfileId: { type: String, attribute: 'resultprofileid', routeOrigin: 'pathname' },
    };
  }

  connectedCallback() {
    this.domain.resultProfileId = this.resultProfileId;
    super.connectedCallback();
  }

  get fields() {
    return [
      {
        label: 'Name',
        property: 'name',
        required: true,
        type: String
      }, {
        label: 'Value',
        property: 'value',
        required: true,
        type: String,
        markdown: true,
      }, {
        label: 'Order',
        property: 'order',
        required: false,
        type: Number,
      }
    ];
  }
}
