import {RoleMixin} from "../../shared/pwa-page";
import {AdminDestroyPage, AdminListPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import {FeedbackDomain} from "../../domain/feedback-domain";
import { GenericDomain } from "../../domain/generic-domain";
import { RoleDomain } from "../../domain/role-domain";

export class PageUserRoleDestroy extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminDestroyPage) {
  title = 'User Role';
  redirect() {
    window.location.href = `/tenants/users/${this.userId}/view`;
  }

  static get properties() {
    return {
      ...super.properties,
      userId: {type: String, attribute: 'userId', routeOrigin: "pathname"},
    };
  }

  constructor() {
    super(new GenericDomain());
  }

  async connectedCallback() {
    this.userId = this.routeData.pathname.groups.userId;
    this.id = this.routeData.pathname.groups.roleId;
    super.connectedCallback();
    this.domain.setBaseUrl(`/users/${this.userId}/roles`);
  }
}
