import { html } from 'lit-element';
import ApexCharts from 'apexcharts'
import { ref } from "lit/directives/ref.js";
import { DashboardBlock } from "./base";
import { Task } from "@qogni-technologies/pwa-utils-library/utils/task.js";
import { StatisticsDomain } from "../../../domain/statistics-domain";
import { repeat } from "lit/directives/repeat.js";

customElements.define(
  "stat-sparks",
  class SparksStat extends DashboardBlock {
    static get properties() {
      return {
        ...super.properties,
        period: {type: String, attribute: true},
      };
    }

    constructor() {
      super();
    }

    static get demoData() {
      return [];
    }

    render() {
      return html`Coming soon.`;
    }
  }
);
