import { RoleMixin } from "../../shared/pwa-page";
import { AdminEditPage } from "../../shared/admin";
import { Session } from "../../shared/session";
import { RoleDomain } from "../../domain/role-domain";
import { Task } from "@qogni-technologies/pwa-utils-library/utils/task.js";
import { GenericDomain } from "../../domain/generic-domain";

export class PageUserRoleEdit extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminEditPage) {
  #roleDomain;
  #organisationDomain;
  #selectedOrganisationId = null;
  #selectedLocationId = null;
  #selectedDepartmentId = null;

  static get properties() {
    return {
      ...super.properties,
      userId: {type: String, attribute: 'userId', routeOrigin: "pathname"},
      roles: {type: Array},
    };
  }

  constructor() {
    super(new GenericDomain());
    this.#roleDomain = new RoleDomain();
    this.#organisationDomain = new GenericDomain(`/organisations`);
    this.roles = [];
  }

  async connectedCallback() {
    this.userId = this.routeData.pathname.groups.userId;
    this.id = this.routeData.pathname.groups.roleId;
    this.domain.setBaseUrl(`/users/${this.userId}/roles`);
    await super.connectedCallback();
    this.requestUpdate();
  }

  async fetch(options = {}) {
    await Promise.all([
      super.fetch(options),
      Task.run(async () => {
        this.roles = (await this.#roleDomain.list({per_page: 1000})).data;
      }, {
        ghost: document.documentElement,
      })
    ]);

    // Process the combined org->tenant structure.
    if (this.object?.applies_to_type && this.object?.applies_to) {
      if (this.object.applies_to_type.indexOf('Department') !== -1) {
        this.#selectedOrganisationId = this.object.applies_to.organisation_id;
        this.#selectedLocationId = this.object.applies_to.location_id;
        this.#selectedDepartmentId = this.object.applies_to.id;
      } else if (this.object.applies_to_type.indexOf('Location') !== -1) {
        this.#selectedOrganisationId = this.object.applies_to.organisation_id;
        this.#selectedLocationId = this.object.applies_to.id;
      } else {
        this.#selectedOrganisationId = this.object.applies_to_id;
      }
    }
  }

  get fields() {
    const fields = [
      {
        label: 'Role',
        property: 'role_id',
        required: true,
        type: 'Select',
        choices: async () => {
          const response = await this.#roleDomain.list({per_page: 1000});
          return response.data.map((c) => {
            return {
              value: c.id,
              name: c.name
            };
          });
        }
      }, {
        label: 'Applies To Organisation',
        property: 'organisation_id',
        required: false,
        type: 'Select',
        defaultChoiceName: '-- Any organisation --',
        getter: () => {
          return this.#selectedOrganisationId;
        },
        change: (e) => {
          this.#selectedOrganisationId = e.target.value;
          this.requestUpdate();
        },
        choices: async () => {
          return (await this.#organisationDomain.list({per_page: 1000})).data.map((c) => {
            return {
              value: c.id,
              name: c.name
            };
          });
        }
      },
    ];

    if (this.#selectedOrganisationId) {
      fields.push({
        label: 'Applies To Location',
        property: 'location_id',
        required: false,
        type: 'Select',
        defaultChoiceName: '-- Any location --',
        getter: () => {
          return this.#selectedLocationId;
        },
        change: (e) => {
          this.#selectedLocationId = e.target.value;
          this.requestUpdate();
        },
        choices: async () => {
          return (await (new GenericDomain(`/organisations/${this.#selectedOrganisationId}/locations`)).list({per_page: 1000})).data.map((c) => {
            return {
              value: c.id,
              name: c.name
            };
          });
        }
      });
      fields.push({
        label: 'Applies To Department',
        property: 'department_id',
        required: false,
        type: 'Select',
        defaultChoiceName: '-- Any department --',
        getter: () => {
          return this.#selectedDepartmentId;
        },
        change: (e) => {
          this.#selectedDepartmentId = e.target.value;
          this.requestUpdate();
        },
        choices: async () => {
          let domain = new GenericDomain(`/organisations/${this.#selectedOrganisationId}/departments`);
          if (this.#selectedLocationId)
            domain = new GenericDomain(`/organisations/${this.#selectedOrganisationId}/locations/${this.#selectedLocationId}/departments`);

          return (await domain.list({per_page: 1000})).data.map((c) => {
            return {
              value: c.id,
              name: c.name
            };
          });
        }
      });
    }

    return fields;
  }

  async preprocess(data) {
    data = super.preprocess(data);

    // Make sure we only submit one of the things given.
    if (! data?.organisation_id) return data;
    if (! data.location_id && ! data.department_id) return data;
    delete data['organisation_id'];
    if (data.location_id && data.department_id) delete data['location_id'];

    return data;
  }
}
