import {css, html, LitElement} from "lit";
import { htmlToMarkdown, markdownToHtml } from "@qogni-technologies/design-system/shared/common.js";

/**
 * Rich Editor component (based on contenteditable div)
 */
customElements.define(
  "rich-editor",
  class RichEditor extends LitElement {
    #editor;
    #toolbar;
    #toolbarTmr;
    #range;
    #internals = undefined;

    constructor() {
      super();
      this.#internals = this.attachInternals();
      this.#internals.ariaRole = "textarea";
      this.text = "";
      this.rows = 4;
    }

    getFormValue() {
      return this.text ?? this.value;
    }

    get form() {
      return this.#internals.form;
    }

    static get styles() {
      return [
        css`
          :host {
            display: block;
            position: relative;
          }

          #editor {
            position: relative;
            text-align: left;
            padding: var(--gutter-tiny, 0.6rem);
            outline: 0;
            min-height: 25px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: var(--radius-large);
            -webkit-touch-callout: none;
          }

          #editor:not(.dirty, .has-value):after {
            color: #6a6a6a;
            display: inline-block;
            content: attr(data-placeholder);
            position: absolute;
          }

          #toolbar {
            background-color: #f4f0ea;

            padding: 0.4rem 0.6rem;
            border-radius: 1rem;
            margin-bottom: 1rem;
            width: auto;
          }

          #toolbar:not(.fixed-toolbar) {
            box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
            position: absolute;
            display: none;
          }

          #toolbar button {
            display: inline-block;
            cursor: pointer;
            outline: 0;
            border: 0;
            border-radius: 50%;
            aspect-ratio: 1/1;
            background-color: transparent;
            color: var(--color-primary-000);
            --icon-size: 24px;
            --icon-fill-color: var(--color-primary-000);
            -webkit-user-select: none; /* Safari */
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* IE 10+ */
            user-select: none; /* Standard syntax */
          }
        `
      ];
    }

    static get properties() {
      return {
        value: { type: String },
        name: { type: String },
        placeholder: {},
        toolbar: {},
        rows: { type: Number },
        fixedToolbar: { type: Boolean, attribute: 'fixed-toolbar' },
        readonly: { type: Boolean, attribute: true }
      };
    }

    render() {
      return html`
        <div
          id="toolbar"
          class="${this.fixedToolbar ? "fixed-toolbar" : ""}"
          @click=${this.toolbarClick}
        >
          <button id="bold" unselectable="on">
            <svg-icon icon="bold"></svg-icon>
          </button>
          <button id="italic" unselectable="on">
            <svg-icon icon="italic"></svg-icon>
          </button>
          <button id="ul" unselectable="on">
            <svg-icon icon="ul"></svg-icon>
          </button>
          <button id="ol" unselectable="on">
            <svg-icon icon="ol"></svg-icon>
          </button>
          <button id="undo" unselectable="on">
            <svg-icon icon="undo"></svg-icon>
          </button>
          <button id="redo" unselectable="on">
            <svg-icon icon="redo"></svg-icon>
          </button>
        </div>
        <div
          @input=${this.input}
          @paste=${this.paste}
          class="${this.value ? "has-value" : ""}"
          contenteditable="${this.readonly ? "false" : "true"}"
          id="editor"
          data-placeholder="${this.placeholder || ""}"
          style="min-height: ${this.rows * 25}px"
        ></div>
      `;
    }

    toolbarClick({ target }) {
      const btn = target.closest("button, .button");
      switch (btn?.id) {
        case "bold":
          this.runCommand(this, "bold", null);
          break;
        case "italic":
          this.runCommand(this, "italic", null);
          break;
        case "ul":
          this.runCommand(this, "insertUnorderedList", null);
          break;
        case "ol":
          this.runCommand(this, "insertOrderedList", null);
          break;
        case "undo":
          this.runCommand(this, "undo", null);
          break;
        case "redo":
          this.runCommand(this, "redo", null);
          break;
      }
    }

    runCommand(el, commandName, arg) {
      this.#editor.focus();
      document.execCommand(commandName, false, arg); // deprecated, but no alternative?
      return false;
    }

    firstUpdated() {
      this.#editor = this.renderRoot.getElementById("editor");
      this.#editor.innerHTML = this.value;

      this.#toolbar = this.renderRoot.getElementById("toolbar");

      this.placeCaretAtEnd();
      this.setupToolbar();
    }

    input() {
      this.value = this.#editor.innerHTML;
      this.change();
    }

    change() {
      const value = this.#editor.innerHTML.trim();
      const text = htmlToMarkdown(value);
      this.value = value;
      this.text = text;
      this.dispatchEvent(
        new CustomEvent("change", {
          bubbles: true,
          composed: true,
          detail: {
            text: text,
            length: this.value.length
          }
        })
      );
    }

    async paste(event) {
      event.preventDefault(); // Prevent the default paste behavior

      try {
        const [clipboardItem] = await navigator.clipboard.read();
        const outputBlob = await clipboardItem.getType("text/html");
        const pastedContent = await outputBlob.text();
        const text = htmlToMarkdown(pastedContent);
        this.#editor.innerHTML = markdownToHtml(text);
        this.change();
      } catch (error) {
        console.error("Error reading HTML from clipboard:", error);
      }
    }

    setupToolbar() {
      const me = this;
      this.#editor.addEventListener("mousedown", this.hideToolbar.bind(this));

      if (!this.fixedToolbar) {
        this.#editor.addEventListener("mouseup", (e) => {
          if (e.target.id !== "editor") return;

          const selection = window.getSelection();

          if (!this.fixedToolbar) {
            if (selection.rangeCount > 0) {
              me.#range = selection.getRangeAt(0);
              if (selection.toString()?.length > 0) {
                console.log("SELECTION: ", selection.toString());
                me.showToolbar(me.#range);
              } else me.hideToolbar();
            }
          }
        });
      }

      // Prevent the default context menu from showing
      this.#editor.addEventListener("contextmenu", (event) => {
        event.preventDefault();
      });
    }

    showToolbar(range) {
      const me = this;
      clearTimeout(this.#toolbarTmr);

      this.#toolbarTmr = setTimeout(() => {
        const rect = range.getBoundingClientRect();
        me.#toolbar.style.left = `${rect.left}px`;
        me.#toolbar.style.top = `${rect.top - me.#toolbar.offsetHeight - 50}px`;
        me.#toolbar.style.display = "block";

        me.handleOutsideClick();
      }, 200);
    }

    handleOutsideClick() {
      document.addEventListener(
        "mousedown",
        (e) => {
          if (!this.contains(e.target)) this.hideToolbar();
          else this.handleOutsideClick();
        },
        {
          once: true
        }
      );
    }

    hideToolbar() {
      if (this.fixedToolbar) return;
      this.#toolbar.style.display = "none";
    }

    // Move caret back to end of editor
    placeCaretAtEnd(el) {
      this.#editor.focus();
      if (
        typeof window.getSelection != "undefined" &&
        typeof document.createRange != "undefined"
      ) {
        const range = document.createRange();
        range.selectNodeContents(this.#editor);
        range.collapse(false);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      } else if (typeof document.body.createTextRange != "undefined") {
        const textRange = document.body.createTextRange();
        textRange.moveToElementText(this.#editor);
        textRange.collapse(false);
        textRange.select();
      }
    }
  }
);
