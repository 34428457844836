import { RoleMixin } from "../../shared/pwa-page";
import { Session } from "../../shared/session";
import { AdminIndexPage, AdminPage } from "../../shared/admin";
import { StatisticsDomain } from "../../domain/statistics-domain";
import { UserDomain } from "../../domain/user-domain";
import { QuestionnaireDomain } from "../../domain/questionnaire-domain";
import { links } from "../home";
import { Task } from "@qogni-technologies/pwa-utils-library/utils/task.js";
import { html, nothing } from "lit";

export class PageDashboardAdmin extends RoleMixin([Session.ROLE_QOGNI_ADMIN], AdminPage) {
  title = 'Qogni Dashboard';

  #statDomain;
  #questionnaireDomain;
  #userDomain;

  constructor() {
    super();

    this.#statDomain = new StatisticsDomain();
    this.#userDomain = new UserDomain();
    this.#questionnaireDomain = new QuestionnaireDomain();
    this.topLinks = links();
  }

  static get properties() {
    return {
      totalUsers: { type: Number },
      newUsersThisWeek: { type: Number },
      newUsersLastWeek: { type: Number },
      activeUsers: { type: Number },
      salesThisMonth: { type: Number },
      salesLastMonth: { type: Number },
      activeUserList: { type: Array },
      recentQuestionnaires: { type: Array },
      burnout: { type: Array },
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.fetch();
  }

  fetch() {
    this.#fetchTotalUsers();
    this.#fetchActiveUsers();
    this.#fetchSales();

    Task.run(async() => {
      const options = {per_page: 20, sort: 'last_online', direction: 'desc'};
      const response = await this.#userDomain.list(options);
      this.activeUserList = response.data.map((u) => {return {
        name: u.firstname + ' ' + u.lastname,
        email: u.email,
        online: u.last_online
      }});
    }, {global: false});
    Task.run(async() => {
      const response = await this.#questionnaireDomain.list({per_page: 20, sort: 'updated_at', direction: 'desc'});
      this.recentQuestionnaires = response.data.map((q) => {return {
        category: html`
          <a href="/questionnaires/${q.id}/view">
            ${q.questionnaire_category.name}
          </a>
        `,
        step: q.current_step,
        total: q.total,
        updated: q.updated_at,
      }});
    }, {global: false});
  }

  #fetchTotalUsers() {
    Task.run(async() => {
      const response = await this.#statDomain.totalUsers();
      this.totalUsers = response.total_users;
      this.newUsersLastWeek = response.created_last_week;
      this.newUsersThisWeek = response.created_this_week;
    }, {global: false});
  }

  #fetchActiveUsers() {
    Task.run(async() => {
      const response = await this.#statDomain.activeUsers();
      this.activeUsers = response.active_users;
    }, {global: false});
  }

  #fetchSales() {
    Task.run(async() => {
      const response = await this.#statDomain.sales();
      this.salesThisMonth = response.sales_this_month;
      this.salesLastMonth = response.sales_last_month;
      this.requestUpdate();
    }, {global: false});
  }

  render() {
    return html`
      ${this.topLinks ? html`
        ${AdminIndexPage.renderBreadcrumbs(this.topLinks, {
          title: this.title,
          subtitle: app.session.hasRole(Session.ROLE_HR) ? 'Overview of the state of your employees' : 'Overview of the users of the system.'
        })}
      ` : nothing}

      ${this.#renderStats()}

      <section class="card col-4">
        <h2>Mental Health Absence Prediction</h2>

        <stat-burnout width="100%" height="500px"></stat-burnout>
      </section>
      <section class="card col-4">

      </section>
      <section class="card col-4">

      </section>

      <section class="card col-6">
        <badge-tag class="blue mb-tiny">
          Stay informed
        </badge-tag>
        <h2>Recent active users</h2>

        <data-table .data="${this.activeUserList}" ?loading="${this.activeUserList === undefined}">
        </data-table>
      </section>

      <section class="card col-6">
        <badge-tag class="red mb-tiny">
          Stay informed
        </badge-tag>
        <h2>Recent Questionnaire Changes</h2>

        <data-table .data="${this.recentQuestionnaires}" ?loading="${this.recentQuestionnaires === undefined}">
        </data-table>
      </section>
    `;
  }

  renderShit() {
    return html`

      <div hidden>
        <section class="card col-3">
          <h2 class="accent-ball yellow">Pie chart</h2>
          <apex-chart options="${pieChartJsonString}"></apex-chart>
        </section>

        <section class="card col-3">
          <h2 class="accent-ball yellow">Pie chart</h2>
          <apex-chart options="${pieChartJsonString}"></apex-chart>
        </section>

        <section class="card col-3">
          <h2 class="accent-ball yellow">Pie chart</h2>
          <apex-chart options="${pieChartJsonString}"></apex-chart>
        </section>

        <section class="card col-3">
          <h2 class="accent-ball yellow">Pie chart</h2>
          <apex-chart options="${pieChartJsonString}"></apex-chart>
        </section>

        <section class="card col-4">
          <h3 class="accent-ball green">Weekly ranking</h3>

          <data-ranking></data-ranking>
        </section>

        <section class="card col-4">
          <h3 class="accent-ball light-blue">Monthly ranking</h3>

          <data-ranking></data-ranking>
        </section>
        <section class="card col-4">
          <h3 class="accent-ball yellow">Yearly ranking</h3>

          <data-ranking></data-ranking>
        </section>

        <section class="card col-6">
          <h2 class="accent-ball yellow">Line chart</h2>
          <apex-chart options="${lineChartJsonString}"></apex-chart>
        </section>

        <section class="card col-6">
          <h2 class="accent-ball yellow">Area chart</h2>
          <apex-chart options="${areaChartJsonString}"></apex-chart>
        </section>

        <section class="card col-12">
          <h2 class="accent-ball yellow">Columns chart</h2>
          <apex-chart options="${columnChartJsonString}"></apex-chart>
        </section>
      </div>`;
  }

  #renderStats() {
    return html`
      <section class="card col-3">
        <comparison-stat
          score="${this.salesThisMonth}"
          oldScore="${this.salesLastMonth}"
          ?loading="${this.salesThisMonth === undefined}"
          label="Sales this month (30 days)"
          icon="metrics"
          prefix="€"
          color="yellow"
        ></comparison-stat>
      </section>

      <section class="card col-3">
        <comparison-stat
          score="${this.activeUsers}"
          ?loading="${this.activeUsers === undefined}"
          label="Active users (30 days)"
          icon="people"
          prefix=""
          color="blue"
        ></comparison-stat>
      </section>

      <section class="card col-3">
        <comparison-stat
          score="${this.newUsersThisWeek}"
          oldScore="${this.newUsersLastWeek}"
          ?loading="${this.newUsersThisWeek === undefined}"
          compareTime="week"
          label="New users this week"
          icon="people"
          prefix=""
          color="blue"
        ></comparison-stat>
      </section>

      <section class="card col-3">
        <comparison-stat
          score="${this.totalUsers}"
          ?loading="${this.totalUsers === undefined}"
          label="Total users"
          icon="people"
          prefix=""
          color="red"
        ></comparison-stat>
      </section>
    `
  }
}
