import "./organisation-location-department-list";
import "./organisation-category-list";
import "./organisation-category-edit";
import "./organisation-type-list";
import "./organisation-type-edit";
import "./organisation-list";
import "./organisation-edit";
import "./organisation-view";
import "./user-list";
import "./user-view";
import "./user-edit";
import "./user-destroy";
import "./role-list";
import "./role-edit";
import "./role-destroy";

import { AdminIndexPage } from "../../shared/admin";
import { Session } from "../../shared/session";

export const links = [{
  name: 'Roles',
  link: '/tenants/roles',
  icon: 'account-add',
  role: Session.ROLE_QOGNI_ADMIN
}, {
  name: 'Users',
  link: '/tenants/users',
  subtitle: 'Manage all your users',
  icon: 'account',
}, {
  name: 'Organisations',
  link: '/tenants/organisations',
  icon: 'people',
}, {
  name: 'Organisation Types',
  link: '/tenants/organisation_types',
  role: Session.ROLE_QOGNI_ADMIN
}, {
  name: 'Organisation Categories',
  link: '/tenants/organisation_categories',
  role: Session.ROLE_QOGNI_ADMIN
}];
export const linksSubtitle = 'Manage companies, organisation structure, HR-employees, users and roles';

export class PageTenants extends AdminIndexPage {
  subtitle = linksSubtitle;

  constructor() {
    super(links);
  }
}
