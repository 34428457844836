import {RoleMixin} from "../../shared/pwa-page";
import { AdminEditPage, TagField } from "../../shared/admin";
import {Session} from "../../shared/session";
import { GenericDomain } from "../../domain/generic-domain";

export class PageQuestionCategoryEdit extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminEditPage) {
  constructor() {
    super(new GenericDomain('/question_categories'));
  }

  get fields() {
    return [
      {
        label: 'Name',
        property: 'name',
        readonly: this.id !== 'new',
        type: String
      }, {
        label: 'Weight (for Totals)',
        property: 'totals_weight',
        type: Number,
        required: false,
        min: 0,
        max: 100,
        step: 1,
        expanded: true,
      }, {
        label: 'Description',
        property: 'description',
        required: false,
        type: String,
        expanded: true,
        markdown: true,
      }, new TagField({
        object: this.object,
        onChange: (e) => {
          if (! this.object) this.object = {tags: []};
          this.object.tags = e.detail.tags;
          this.requestUpdate();
        },
      })
    ];
  }
}
