import {LitElement} from "lit";

/**
 * Default Page component (uses Light DOM)
 * @extends LitElement
 */
export class PWAPage extends LitElement {
  createRenderRoot() {
    return this;
  }

  get isAuthenticated() {
    return false;
  }

  get isOnboarded() {
    return app.isOnboarded;
  }

  updated() {
    document.documentElement.classList.remove(
      "is-authenticated"
    );
  }
}

/**
 * Mixin for checking if the user is authenticated.
 * @template T
 * @param {T} superclass
 * @returns {T}
 */
export function AuthenticatedMixin(superclass) {
  return class extends superclass {
    get isAuthenticated() {
      return true;
    }

    async connectedCallback() {
      await super.connectedCallback();

      if (!app.session.isAuthenticated) return location.replace("/enter");
    }

    updated() {
      document.documentElement.classList.add(
        "is-authenticated"
      );
    }
  };
}

/**
 * Mixin for showing page as full-screen (no sidebar/bottom bar).
 * @template T
 * @param {T} superclass
 * @returns {T}
 */
export function FullscreenMixin(superclass) {
  return class extends superclass {
    connectedCallback() {
      super.connectedCallback();
      app.classList.add("no-sidebar");
      app.classList.add("no-bottom-bar");
    }

    disconnectedCallback() {
      super.disconnectedCallback();
      app.classList.remove("no-sidebar");
      app.classList.remove("no-bottom-bar");
    }
  };
}

/**
 * Mixin for checking roles of the user.
 * @template T
 * @param {array|String} roles single or list with roles to check for. Roles will be checked based on 'or' ruling.
 * @param {String} redirect
 * @param {T} superclass
 * @returns {T}
 */
export function RoleMixin(roles, superclass, redirect = "/") {
  if (!Array.isArray(roles)) roles = [roles];

  return class extends superclass {
    async connectedCallback() {
      await super.connectedCallback();
      const matchedRoles = roles.filter((role) => app.session.hasRole(role));
      if (matchedRoles.length === 0) return location.replace(redirect);
    }
  };
}
