import {css, html, LitElement} from "lit";

customElements.define(
  "data-table",
  class DataTable extends LitElement {
    createRenderRoot() {
      return this;
    }

    static get properties() {
      return {
        data: {type: Object, attribute: true},
        loading: {type: Boolean, attribute: true},
        sortColumn: {type: String, attribute: false},
        sortOrder: {type: String, attribute: false},
        currentPage: {type: Number, attribute: false}, // Added for pagination
        itemsPerPage: {type: Number, attribute: false} // Added for pagination
      };
    }

    constructor() {
      super();
      this.sortColumn = null;
      this.sortOrder = 'asc';
      this.currentPage = 1; // Default to first page
      this.itemsPerPage = 10; // Display 10 items per page initially
    }

    connectedCallback() {
      super.connectedCallback();
    }

    sortData(e) {
      e.stopPropagation();
      const columnName = e.target.textContent.trim();
      if (!columnName ||!this.data.length) return;

      this.sortColumn = columnName;

      let data = [...this.data];
      data.sort((a, b) => {
        if (this.sortOrder === 'asc') {
          return a[this.sortColumn] > b[this.sortColumn]? 1 : -1;
        } else {
          return a[this.sortColumn] < b[this.sortColumn]? 1 : -1;
        }
      });

      this.data = data;
      this.sortOrder = this.sortOrder === 'asc'? 'desc' : 'asc';
    }

    renderLoading() {
      return html`
        ${Array(20).fill().map((_, i) => html`
          <app-shimmer></app-shimmer>
        `)}
      `;
    }

    render() {
      if (this.loading) return this.renderLoading();
      if (! this.data || ! this.data[0]) return html`<i>No data found</i>`;

      const totalPages = Math.ceil(this.data.length / this.itemsPerPage);
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      const paginatedItems = this.data.slice(startIndex, endIndex);

      return html`
        <table>
          <thead>
          <tr>
            ${Object.keys(this.data[0]).map((key, index) => {
              const name = key.replace(/\b([a-z])/g, (_, val) =>
                val.toUpperCase()
              ).replace('_', ' ');
              return html`
                <th class="${key == this.sortColumn ? 'active-sort' : ''} sortable" @click=${(e) => this.sortData(e)}>
                  <slot name="${key}">
                    <div class="wrap">
                      <span>${name}</span>
                      <svg-icon icon="caret" size="12px"></svg-icon>
                    </div>
                  </slot>
                </th>`;
            })}
          </tr>
          </thead>
          <tbody>
          ${paginatedItems.map((item) => {
            return html`
              <tr>
                ${Object.values(item).map((row) => {
                  let value = row;
                  if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}Z$/.test(value)) {
                    value = new Date(value).format({
                      mode: 'format'
                    });
                  }
                  return html`<td>${value}</td>`;
                })}
              </tr>
            `;
          })}
          </tbody>
        </table>

        <div class="pagination-controls">
          ${this.currentPage > 1 ? html`
            <button class="small ${this.currentPage === 1 ? 'hide' : ''}" @click=${() => this.currentPage--}>Previous</button>
          ` : ''}
          <span>Page ${this.currentPage} of ${totalPages}</span>
          ${this.currentPage < totalPages ? html`
            <button class="small ${this.currentPage === totalPages ? 'hide' : ''}" @click=${() => this.currentPage++}>Next
            </button>
          ` : ''}
        </div>
      `;
    }
  }
);
