import { RoleMixin } from "../../shared/pwa-page";
import { links } from "../home";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { html, nothing } from "lit";
import { keyed } from 'lit/directives/keyed.js';
import { AdminIndexPage, AdminPage } from "../../shared/admin";
import { Session } from "../../shared/session";
import { repeat } from "lit/directives/repeat.js";
import { OrganisationDomain } from "../../domain/organisation-domain";

import "./hr/general";

export class PageDashboardHR extends RoleMixin([Session.ROLE_HR, Session.ROLE_QOGNI_ADMIN], AdminPage) {
  #organisationDomain;

  constructor() {
    super();

    this.#organisationDomain = new OrganisationDomain();
    this.topLinks = links();
    this.tabs = [{
      name: 'General',
      content: html`
        <page-dashboard-hr-general
          organisation-id=${this.organisationId}
          .organisation=${this.organisation}
        ></page-dashboard-hr-general>
      `
    }, {
      name: 'Users',
      content: html`
        <page-dashboard-hr-users
          organisation-id=${this.organisationId}
          .organisation=${this.organisation}
        ></page-dashboard-hr-users>
      `
    }]
    this.tabIndex = 0;
  }

  get activeTab() {
    return this.tabs[this.tabIndex];
  }

  get title() {
    if (this.organisation) {
      return this.organisation.name + ' Dashboard';
    }
    if (! this.organisationId) return 'Demo HR Dashboard';
    return 'HR Dashboard';
  }

  static get properties() {
    return {
      organisationId: { type: String },
      organisation: { type: Object },
      organisationList: { type: Array },

      loading: { type: Boolean },
      tabIndex: { type: Number },
    }
  }

  connectedCallback() {
    if (app.session.user.organisation_id) this.organisationId = app.session.user.organisation_id;
    super.connectedCallback();
    this.fetch();
  }

  async fetch() {
    if (! this.organisationList) {
      this.#fetchOrganisationList();
    }
    if (this.organisationId && ((this.organisationId && ! this.organisation) || this.organisationId !== this.organisation?.id)) {
      this.#fetchOrganisation();
    }
  }

  async changeOrganisation(e) {
    this.organisationId = e.target.value ? e.target.value : null;
    this.organisation = null;
    console.log('Selected different organisation with ID: ', this.organisationId);
    this.requestUpdate();
    await this.fetch();
    this.requestUpdate();
  }

  #fetchOrganisationList() {
    Task.run(async () => {
      const response = await this.#organisationDomain.list({per_page: 1000});
      this.organisationList = response.data;
      this.requestUpdate();
    });
  }

  #fetchOrganisation() {
    Task.run(async () => {
      const response = await this.#organisationDomain.show(this.organisationId);
      this.organisation = response.data;
      this.requestUpdate();
    });
  }

  changeTabs(e) {
    this.tabIndex = parseInt(e.target.value);
    this.requestUpdate();
  }

  render() {
    return html`
      ${this.topLinks ? html`
        ${AdminIndexPage.renderBreadcrumbs(this.topLinks, {
          title: this.title,
          subtitle: 'Overview of the users of the system.',
          extra: () => this.renderFilterOptions()
        })}
      ` : nothing}

      ${this.renderTabs()}

      ${keyed(this.organisationId, this.renderStats())}
    `;
  }

  renderFilterOptions() {
    return html`
      <div class="extra filter-options">
        <x-form>
          <form>
            <label>
              <span data-label="">Organisation</span>
              <select name="organisation" @change="${this.changeOrganisation.bind(this)}">
                <option value="" ?selected=${!this.organisationId}>Demo Organisation</option>
                ${this.organisationList ? repeat(this.organisationList, (o) => html`
                  <option value="${o.id}" ?selected=${this.organisationId === o.id}>${o.name}</option>
                `) : nothing}
              </select>
              <svg-icon icon="chevron" size="12px"></svg-icon>
            </label>
          </form>
        </x-form>
      </div>
    `;
  }

  renderStats() {
    return html`${this.activeTab.content}`;
  }

  renderTabs() {
    return html`
      <div style="display: flex; flex-wrap: wrap;" class="tab-container tiny">
        ${repeat(this.tabs, (tab, idx) => html`
          <label>
            <input class="variant2" type="radio" name="tab_index" value="${idx}" ?checked=${idx === this.tabIndex} @change=${this.changeTabs.bind(this)}>
            <span class="data-label">
              ${tab.name}
            </span>
          </label>
        `)}
      </div>
    `;
  }
}
