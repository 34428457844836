import {AdminDomainHandler} from "../shared/app-domain-handler";
import {ApiRequest} from "../shared/APIRequest";

export class UserDomain extends AdminDomainHandler {
  baseUrl(operation, options) {
    return `/users`;
  }

  async list(options = {}) {
    return super.list({
      admin: true,
      ...options
    });
  }

  async show(key, options = {}) {
    options.query = {admin: true, ...options.query ?? {}};
    return super.show(key, options);
  }
}
