import { OrderDomain } from "../../domain/order-domain";
import { AdminListPage, dateRenderer } from "../../shared/admin";
import { RoleMixin } from "../../shared/pwa-page";
import { Session } from "../../shared/session";

export class PageOrderList extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminListPage) {
  canView = true;
  canEdit = false;
  canDestroy = false;
  canAdd = false;
  searchable = false;
  title = 'Order List';

  constructor() {
    super(new OrderDomain());
  }

  viewUrl(obj) {
    return `/orders/${obj.id}`;
  }

  get columns() {
    return [
      {name: 'Order', field: 'order_number', sortable: true, filterable: false, searchable: false, link: true},
      {name: 'First name', field: 'customer_firstname', sortable: true, filterable: false, searchable: true},
      {name: 'Last name', field: 'customer_lastname', sortable: true, filterable: false, searchable: true},
      {name: 'Status', field: 'status', sortable: true, filterable: true, searchable: false},
      {name: 'Date', field: 'created_at', sortable: true, filterable: false, searchable: false, render: dateRenderer},
    ];
  }
}
