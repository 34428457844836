import {RoleMixin} from "../../shared/pwa-page";
import { AdminListPage, dateTimeRenderer } from "../../shared/admin";
import {Session} from "../../shared/session";
import {html} from "lit";
import {FeedbackDomain} from "../../domain/feedback-domain";

export class PageFeedbackList extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminListPage) {
  #domain;

  canAdd = false;
  canEdit = false;
  canView = true;
  canDestroy = true;
  title = 'Feedback';

  constructor() {
    super(new FeedbackDomain());
  }

  viewUrl(obj) {
    return `/feedback/${obj.id}`;
  }

  destroyUrl(obj) {
    return `/feedback/${obj.id}/destroy`;
  }

  get columns() {
    return [
      {name: 'User', field: 'user_id', render: (r) => {
          if (r.user) return html`${r.user.firstname} ${r.user.lastname}`;
          return html`unknown user`;
      }},
      {name: 'Rating', field: 'rating'},
      {name: 'Page', field: 'page'},
      {name: 'Reported At', field: 'created_at', render: dateTimeRenderer},
      {name: 'Completed At', field: 'deleted_at', render: dateTimeRenderer},
    ];
  }
}

