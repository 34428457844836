import { RoleMixin } from "../../shared/pwa-page";
import { AdminEditPage } from "../../shared/admin";
import { Session } from "../../shared/session";
import { GenericDomain } from "../../domain/generic-domain";

export class PageScoreTextEdit extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminEditPage) {
  title = 'Score Text';

  constructor() {
    super(new GenericDomain('/score_texts'));
  }

  get fields() {
    return [
      {
        label: 'Text',
        property: 'text',
        readonly: false,
        expanded: true,
        required: true,
        type: String
      }
    ];
  }
}
