import { GenericDomain } from "./generic-domain";

export class LedgerAccountDomain extends GenericDomain {
  constructor(organisationId) {
    super(`/organisations/${organisationId}/ledger_accounts`);
  }

  async createOrder(data) {
    return this.api.postData(`${this.baseUrl('other')}/create_order`, data);
  }

  async createTransaction(data) {
    let result;
    try {
      result = await this.api.postData(`${this.baseUrl('other')}/create_transaction`, data);
    } catch (err) {
      switch(err.response && err.response.status) {
        case 400:
          app.addToastMessage("Validation errors with inputs given.", { type: "error" });
          return false;
        default:
          break;
      }
    }

    app.addToastMessage("New Email added");
    return result.data;
  }
}
